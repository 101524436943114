<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <b-table-lite
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-stats-box"
          thead-class="label"
          tbody-class="value"
          :items="statItems"
          :fields="statFields"
          :busy="loading">
          <template #table-caption>Leave Summary</template>
          <template #cell(type)="data">
            {{ data.item.value.toUpperCase() }}
          </template>
          <template #cell(allowance)="data">
            {{ data.item.groups[data.item.key] || data.item.allowance || '' }}
          </template>
          <template #cell(carryForward)="data">
            <span v-if="data.item.key == 'ANNUAL'">{{
              data.item.groups.CARRYFORWARD || ''
            }}</span>
          </template>
          <template #cell(inLieu)="data">
            <span v-if="data.item.key == 'ANNUAL'">{{
              data.item.groups.INLIEU || ''
            }}</span>
          </template>
          <template #cell(balance)="data">
            {{ data.item.balance }}
          </template>
        </b-table-lite>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'

export default {
  name: 'HolidayStats',
  props: {
    selectedYear: {
      type: Number,
      required: true
    },
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    statFields() {
      const flds = [
        {
          key: 'type',
          label: 'Leave Type',
          thClass: 'w40 aleft',
          tdClass: 'w40 aleft',
          _showInMobileView: true
        },
        {
          key: 'allowance',
          label: 'Allowance',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'carryForward',
          label: 'Carry-forward',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'inLieu',
          label: 'In-lieu',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'pending',
          label: 'Pending',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'approved',
          label: 'Approved',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'balance',
          label: 'Balance',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        }
      ]
      return flds.filter((fld) => {
        return this.mobileView ? fld._showInMobileView : true
      })
    }
  },
  data: () => {
    return {
      loading: false,
      statItems: []
    }
  },
  mounted() {
    this.$root.$on('parent_tab_holidays_selected', this.tabMounted)
    this.$root.$on('saved', this.tabMounted)
  },
  methods: {
    async tabMounted() {
      this.loading = true
      await this.getStats()
      this.loading = false
    },
    async getStats() {
      const TypesToHide = ['ANNUALSALE']
      const { status, data } = await ServicesOld.GetHolidayAllowences({
        year: this.selectedYear
      })
      if (status == 200) {
        this.statItems = data.content
          .map((itm) => {
            itm.balance = ''
            if (itm.value.toLowerCase() == 'personal') {
              itm.value = 'Personal/Sick'
              itm.groups[itm.key] = ''
              // itm.balance = itm.remains
            }
            if (
              ['annual holiday', 'personal/sick'].includes(
                itm.value.toLowerCase()
              )
            ) {
              itm.balance = itm.remains
            }
            itm.pending = itm.pending || ''
            itm.approved = itm.approved || ''
            return itm
          })
          .filter((itm) => {
            return !TypesToHide.includes(itm.key)
          })
      }
    }
  },
  watch: {
    selectedYear: {
      handler: 'tabMounted',
      immediate: true
    }
  }
}
</script>

<style>
.lizard-stats-box {
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid #eee;
  border-radius: 3px;
}
.lizard-stats-box .label {
  color: #fff;
  text-align: center;
  padding: 4px;
  border-bottom: 1px solid #eee;
  font-size: 10pt;
  font-weight: 600;
  height: 40px;
  line-height: 30px;
  background-color: #66ccff;
}
.lizard-stats-box .value {
  color: #666;
  text-align: center;
  padding: 4px;
  font-size: 10pt;
  height: 40px;
  line-height: 30px;
}
.lizard-stats-box .w10 {
  width: auto !important;
}
.lizard-stats-box .w40 {
  width: 25% !important;
}
.lizard-stats-box .aleft {
  text-align: left;
}
</style>
