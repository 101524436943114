<template>
  <div class="main-container">
    <NavbarComponent :mobileView="false" />
    <div v-if="showContent">
      <WelcomeUser v-if="showContent" :mobileView="false" />
      <b-card no-body>
        <b-tabs card class="content-bg">
          <b-tab
            v-for="tab in tabs.filter((tab) => tab.show)"
            :lazy="tab.lazy"
            :key="tab.id"
            :title="tab.title"
            :active="tab.active"
            :title-link-class="tab.class"
            @click="tabChanged(tab.id)"
          >
            <template #title>
              <b-icon :icon="tab.icon" :title="tab.title"></b-icon>
              {{ tab.title }}
            </template>
            <component
              :is="tab.component"
              :errorMessage="tab.message || ''"
              :mobileView="false"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div v-if="!showContent">
      <AdBanner />
    </div>
    <div v-if="!showContent">
      <About :errorMessage="errorMessage" />
    </div>
  </div>
</template>

<script>
import ServicesOld from '../services/index.vue'
import Services from '../services/main.service.vue'
import NavbarComponent from '../components/Navbar.vue'
import AdBanner from '../components/AdBanner.vue'
import About from '../components/About.vue'
import WelcomeUser from '../components/WelcomeUser.vue'
import HolidayLogs from '../components/logs-components/Holiday/Main.vue'
import ExpenseLogs from '../components/logs-components/ExpenseLogs.vue'
import StaffingLogs from '../components/logs-components/StaffingLogs.vue'
import TimeLogs from '../components/logs-components/TimeLogs.vue'
import Staffing from '../components/admin/Staffing.vue'
import AssetBoard from '../components/admin/AssetBoard.vue'
import Reports from '../components/reports-components/Reports.vue'
import Admin from '../components/admin/Admin.vue'
import SuperAdmin from '../components/superadmin/SuperAdmin.vue'

export default {
  name: 'HomePage',
  components: {
    NavbarComponent,
    AdBanner,
    About,
    WelcomeUser,
    HolidayLogs,
    ExpenseLogs,
    TimeLogs,
    StaffingLogs,
    Staffing,
    AssetBoard,
    Reports,
    Admin,
    SuperAdmin
  },
  data() {
    return {
      errorMessage: '',
      showContent: false,
      showReportContent: false,
      showAdminContent: false,
      showAdminTabs:
        this?.$store?.getters?.showAdminTabs ??
        process?.env?.VUE_APP_SHOW_ADMIN_TABS ??
        true,
      env:
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production',
      tabs: [
        {
          id: 'tab_timesheet',
          component: 'about',
          title: 'Timesheet',
          icon: 'info-circle',
          message:
            'Your account is not active, Please ask administrator to activate it.',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_staffing_logs',
          component: 'staffing-logs',
          icon: 'person-check',
          title: 'My Staffing',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_expenses',
          component: 'expense-logs',
          title: 'My Expenses',
          icon: 'cash-stack',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_holidays',
          component: 'holiday-logs',
          icon: 'controller',
          title: 'My Leave',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_timelogs',
          component: 'time-logs',
          icon: 'calendar2-week',
          title: 'My Timesheet',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_staffing',
          component: 'staffing',
          icon: 'person-check-fill',
          title: 'Staffing',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_assetboard',
          component: 'asset-board',
          icon: 'grid',
          title: 'Asset Board',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_reports',
          component: 'reports',
          icon: 'clipboard-data',
          title: 'Reports',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_admin',
          component: 'admin',
          icon: 'gear',
          title: 'Admin',
          class: '',
          lazy: true,
          active: false,
          show: false
        },
        {
          id: 'tab_superadmin',
          component: 'super-admin',
          icon: 'x-diamond-fill',
          title: 'SuperAdmin',
          class: '',
          lazy: true,
          active: false,
          show: false
        }
      ],
      notifSent: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    UserData() {
      return this.Store.userAuth
    },
    IsAuthenticated() {
      return this.UserData.authenticated
    },
    HasAdminPerm() {
      return this.UserData.hasAdminPrivileges
    },
    HasManagerPerm() {
      return this.UserData.hasManagerPrivileges
    }
  },
  mounted() {
    this.$root.$on('check-user', (hasUser = false) => {
      this.showContent = this.IsAuthenticated
      if (hasUser) {
        this.errorMessage = ''
      }
    })
    this.$root.$on('admin', (isAdmin = false) => {
      this.showReportContent = isAdmin
      this.showAdminContent = isAdmin && this.showAdminTabs
      if (isAdmin) {
        const gbpRates = this.$cookies.get('__gbp-rates')
        if (!gbpRates) {
          // console.log('exchange is not available.')
          ServicesOld.GetEURBaseRates()
            .then((result) => {
              if (result.status === 200) {
                let rates = {
                  eur: 1 / result.data.conversion_rates.GBP,
                  usd: 0
                }
                rates.usd = parseFloat(
                  rates.eur * result.data.conversion_rates.USD
                ).toFixed(2)
                rates.eur = parseFloat(rates.eur).toFixed(2)
                // console.log('using new rates', rates)
                this.$cookies.set('__gbp-rates', rates, '8H')
              } else {
                console.log(result.data.error)
              }
            })
            .catch(({ message }) => {
              console.log('ERROR', { message })
            })
        } else {
          // console.log('using old rates', gbpRates)
        }
      }
    })
    this.$root.$on('service-status', (isAvailable) => {
      this.toastNotif = null
      setTimeout(() => {
        if (!isAvailable) {
          this.fireNetworkNotif(false)
        } /*  else {
          this.fireNetworkNotif(true)
        } */
      }, 500)
    })
    this.$root.$on('network-status', (isOnine, errMessage) => {
      this.toastNotif = null
      setTimeout(() => {
        if (!isOnine) {
          this.fireNetworkNotif(false, errMessage)
        }
      }, 500)
    })
    this.$root.$on('fireLoginNotif', () => {
      if (!this.notifSent.includes('fireLoginNotif')) {
        setTimeout(() => {
          this.fireLoginNotif(false)
        }, 2000)
      }
    })
    this.$root.$on('fire403Notif', () => {
      setTimeout(() => {
        this.fire403Notif(false)
      }, 2000)
    })
    const authData = this.$cookies.get('__auth-data')
    if (authData) {
      this.showReportContent = authData.isAdmin
      this.showAdminContent = authData.isAdmin && this.showAdminTabs
    }
  },
  methods: {
    userLoaded(user) {
      try {
        if (user) {
          const userRole = (
            user?.details?.app?.roleLabel ?? 'not_specified'
          ).toLowerCase()
          const userTabs = user?.details?.app?.tabsToShow ?? []
          let tabActive = false
          this.tabs = this.tabs.map((tab) => {
            const tabLabel = tab.id.split('_').splice(1).join('_')
            tab.show = userTabs.includes(tabLabel) ?? false
            if (tab.show && !tabActive) {
              tabActive = true
              tab.active = true
              setTimeout(() => {
                this.tabChanged(tab.id)
              }, 2000)
            }
            return tab
          })
        }
      } catch (exception) {
        console.log(exception)
      }
    },
    tabChanged(tabId) {
      // check user is still logged in
      Services.Users.fetchCurrentUser()
        .then(({ status }) => {
          if (status === 200) {
            this.$root.$emit(`parent_${tabId}_selected`)
          } else {
            this.$root.$emit('user-signed-out')
          }
        })
        .catch(() => {
          this.$root.$emit('user-signed-out')
        })
      // console.log('tab loaded: %s', tabId)
      // console.log('ev::%s', `parent_${tabId}_selected`)
      // this.$root.$emit(`parent_${tabId}_selected`)
      // this.tabs.forEach((tab) => {
      //   if (tab.id !== tabId) {
      //     this.$root.$emit(`parent_${tab.id}_deselected`)
      //   }
      // })
    },
    fireNetworkNotif(
      success = true,
      errMessage = 'Timesheet service is not available right now. Please try again later.'
    ) {
      if (!this.notifSent.includes(`fireNetworkNotif:${success}`)) {
        this.notifSent.push(`fireNetworkNotif:${success}`)
        this.$toasted[success ? 'success' : 'error'](
          success ? 'Timesheet service is available!' : errMessage
        ).goAway(success ? 2000 : 20000)
      }
    },
    fireLoginNotif() {
      if (this.showContent) {
        return
      }
      this.notifSent.push('fireLoginNotif')
      return this.$toasted
        .info('Please sign-in using your Candesic account.')
        .goAway(1500)
    },
    fire403Notif() {
      this.errorMessage =
        'Your account is not active, Please ask administrator to activate it.'
      this.notifSent.push('fire403Notif')
      return this.$toasted.info(this.errorMessage).goAway(1500)
    },
    isPrd() {
      return ['staging', 'development'].indexOf(this.env) === -1
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  watch: {
    UserData: {
      handler: 'userLoaded',
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.main-container {
  min-height: calc(100vh - 100px) !important;
  overflow: hidden !important;
}
.card {
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0 !important;
}
.card-header {
  border-radius: 0 !important;
  background: rgba(255, 255, 255, 0.8) !important;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(238, 238, 238, 0.5) 100%
  );
}
ul.nav-tabs > .nav-item .nav-link {
  font-size: 11pt;
  color: #666 !important;
  margin: 0 3px !important;
  border-color: transparent !important;
  border-bottom: 2px solid transparent !important;
}
ul.nav-tabs > .nav-item .nav-link:hover {
  color: #000 !important;
  border-color: transparent !important;
  border-bottom: 2px solid #23b1f3 !important;
}
ul.nav-tabs > .nav-item .nav-link.active {
  /* background-color: transparent !important; */
  border-color: transparent !important;
  border-width: 0px !important;
  border-bottom: 2px solid #23b1f3 !important;
  color: #333 !important;
  font-weight: bold;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(35, 177, 243, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(35, 177, 243, 0.1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    rgba(35, 177, 243, 0.1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#23b1f3",GradientType=1);
}
ul.nav-pills > .nav-item .nav-link {
  font-size: 11pt;
  color: #666 !important;
  margin: 0 3px !important;
}
ul.nav-pills > .nav-item .nav-link:hover {
  background-color: rgba(0, 115, 190, 0.7) !important;
  color: #fff !important;
}
ul.nav-pills > .nav-item .nav-link.active {
  background-color: rgba(0, 115, 190, 0.7) !important;
  color: #fff !important;
  font-weight: bold;
}
</style>
