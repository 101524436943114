<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12" v-if="weeks.defaultDates.length">
        <div class="row calendar-header">
          <div class="col-lg team">Project</div>
          <div
            class="col-lg day-holder"
            :class="{
              today: isToday(date),
              weekend: isWeekend(date)
            }"
            v-for="(date, index) in weeks.defaultDates"
            :key="index"
            v-b-popover.hover.bottom="
              isToday(date)
                ? 'Today'
                : formatHeaderDate(date, 'EEEE, LLLL do yyyy')
            "
          >
            {{ formatHeaderDate(date) }}
          </div>
        </div>
        <div
          class="row"
          v-for="({ project, dates }, index) in weeks.projects"
          :key="project.uid + '_' + index"
        >
          <div class="col-lg">
            <div class="row calendar-body" :class="{ bgOdd: index % 2 !== 0 }">
              <div class="col-sm team">
                <ul>
                  <li>
                    {{
                      project.title
                        ? project.title
                        : getProjectTitle(project.uid)
                    }}
                  </li>
                  <li v-if="project._isStaffing">
                    <b-badge class="remove-btn" variant="info">
                      <strong>Staffing<br />Assignment</strong></b-badge
                    >
                  </li>
                  <li v-else>
                    <b-badge
                      class="remove-btn"
                      variant="danger"
                      @click="removeProject(project.uid)"
                    >
                      <strong>Remove</strong></b-badge
                    >
                  </li>
                </ul>
              </div>
              <div
                class="col-lg block"
                :class="{
                  weekend: isWeekend(date),
                  highlighted: isActiveBlock(project.uid, date)
                }"
                v-for="(date, index) in Object.keys(weeks.dates)"
                :key="index"
              >
                <span
                  v-if="!isWeekend(date) && isNotPassed(date) && dates[date]"
                >
                  <b-form-input
                    class="timelog"
                    type="number"
                    min="0"
                    max="24"
                    :id="'blktl_' + project.uid + '_' + date"
                    v-model="dates[date].hours"
                    :disabled="!editMode"
                    @focus="showTooltip"
                    @blur="updateTimelog(project.uid, date, $event)"
                  />
                </span>
                <span v-else></span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-for="({ project, dates }, index) in weeks.projectsToAdd"
          :key="project.uid + '_' + index"
        >
          <div class="col-lg">
            <div class="row calendar-body" :class="{ bgOdd: index % 2 !== 0 }">
              <div class="col-sm team">
                <ul>
                  <li>
                    {{
                      project.title
                        ? project.title
                        : getProjectTitle(project.uid)
                    }}
                  </li>
                  <li>
                    <b-badge
                      class="remove-btn"
                      variant="danger"
                      @click="removeProject(project.uid)"
                    >
                      <strong>Remove</strong></b-badge
                    >
                  </li>
                </ul>
              </div>
              <div
                class="col-lg block"
                :class="{ weekend: isWeekend(date) }"
                v-for="(date, index) in Object.keys(weeks.dates)"
                :key="index"
              >
                <span v-if="!isWeekend(date) && isNotPassed(date)">
                  <b-form-input
                    class="timelog"
                    type="number"
                    min="0"
                    max="24"
                    v-model="dates[date].hours"
                    :disabled="!editMode"
                    @focus="showTooltip"
                    @blur="updateTimelog(project.uid, date, $event)"
                  />
                </span>
                <div v-else></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <div class="row calendar-body">
              <div
                class="col-sm team"
                style="background-color: rgb(80, 80, 80)"
              >
                <b-button
                  size="sm"
                  style="
                    background: transparent !important;
                    border: none !important;
                    height: 100%;
                    width: 100%;
                  "
                  v-if="!addProject"
                  @click="toggleAddProject"
                >
                  <span v-if="!customProject.selected" class="addProjectTxt"
                    >Select Project</span
                  >
                  <span v-else>{{ getProjectTitle() }}</span>
                </b-button>
                <b-form-select
                  v-else
                  v-model="customProject.selected"
                  :options="filterByUnassignedProjects(customProject.options)"
                  size="sm"
                  class="mt-3 lizard-timelog-project-select"
                  @change="toggleAddProject"
                ></b-form-select>
              </div>
              <div
                class="col-lg block"
                :class="{ weekend: isWeekend(date) }"
                v-for="(date, index) in weeks.defaultDates"
                :key="index"
              >
                <span
                  v-if="
                    !isWeekend(date) &&
                    isNotPassed(date) &&
                    customProject.selected
                  "
                >
                  <b-form-input
                    class="timelog"
                    type="number"
                    min="0"
                    max="24"
                    v-model="customProject.hours[date]"
                    @blur="addNewTimelog(date)"
                  />
                </span>
                <span v-else></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-overlay no-wrap :show="deleteInProgress">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <p>
            <strong
              id="form-confirm-label"
              class="lizard-delete-overlay-message"
              >Are you sure you want to remove the timelogs for '{{
                getProjectTitle(selectedProjectToRemove)
              }}' for the following dates?</strong
            >
          </p>
          <ul class="lizard-logs-to-remove-list">
            <li v-for="(itm, index) in logsToRemoveQueue" :key="index">
              {{ formatHeaderDate(itm.date) }}
            </li>
          </ul>
          <div class="text-center">
            <b-button variant="outline-danger" class="mr-3" @click="onCancel">
              Cancel
            </b-button>
            <b-button variant="outline-info" @click="onConfirm"
              >Confirm</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>

<script>
import {
  addDays,
  format,
  isToday,
  isWeekend,
  isBefore,
  isSameDay,
  startOfWeek
} from 'date-fns'

export default {
  name: 'TwoWeeksCalendarTimelog',
  props: {
    showWeekends: {
      type: Boolean,
      default: false
    },
    showSaveTooltip: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: true
    },
    projects: {
      type: Array,
      default: () => []
    },
    assignments: {
      type: Array,
      default: () => []
    },
    timelogs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    },
    logsInQueue() {
      return this.Store.timelogsInQueue
    },
    logsToRemoveQueue() {
      return this.Store.timelogsToRemoveQueue
    }
  },
  data() {
    return {
      date: new Date(),
      days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      weeks: {
        defaultDates: [],
        dates: [],
        projects: [],
        unassignedProjects: [],
        projectsToAdd: [],
        timelogs: {}
      },
      addProject: false,
      customProject: {
        selected: null,
        options: [{ value: null, text: 'Loading...', disabled: true }],
        hours: {}
      },
      deleteInProgress: false,
      selectedProjectToRemove: null,
      highlightedBlocks: []
    }
  },
  watch: {
    projects: {
      handler() {
        this.customProject.options = [
          { value: null, text: 'Select Project...', disabled: true },
          ...this.projects.map((prj) => ({
            value: prj.uid,
            text: prj.title
          }))
        ]
      },
      deep: true
    },
    assignments: {
      handler() {
        this.weeks.dates = this.getBlocks()
      },
      deep: true
    },
    timelogs: {
      handler() {
        this.importTimelogs()
      },
      deep: true
    },
    customProject: {
      handler({ selected: uid, hours = [] }) {
        if (!uid || !Object.keys(hours).length) return
        this.weeks.projectsToAdd.push({
          project: { uid },
          dates: Object.keys(hours).reduce((acc, itm) => {
            acc[itm] = {
              hours: hours[itm]
            }
            return acc
          }, {})
        })
        this.resetCustomProject()
      },
      deep: true
    },
    editMode() {
      this.resetCustomProject()
    }
  },
  mounted() {
    this.$root.$on('week-changed-on-timelogs', () => {
      this.weeks.defaultDates = this.setDefaultDates()
      this.weeks.projectsToAdd = []
      this.resetCustomProject()
    })
    this.importTimelogs()
  },
  methods: {
    resetCustomProject() {
      this.addProject = false
      this.customProject.selected = null
      this.customProject.hours = {}
    },
    toggleAddProject() {
      if (this.customProject.options.length === 1) {
        this.$root.$emit('get-projects')
      }
      this.addProject = !this.addProject
      if (!this.addProject) {
        this.customProject.hours = {}
        this.weeks.defaultDates.forEach((date) => {
          this.customProject.hours[date] = 0
        })
      }
    },
    getProjectColor(uid = null) {
      const project = uid || this.customProject.selected
      if (!project) return ''
      if (!this.projects.length) {
        this.$root.$emit('get-projects')
      }
      return this.projects.find((prj) => prj.uid === project)?.meta?.color ?? ''
    },
    getProjectTitle(uid = null) {
      if (!this.customProject.selected && !uid) return ''
      if (!this.projects.length) {
        this.$root.$emit('get-projects')
      }
      return (
        this.projects.find(
          (prj) => prj.uid === (uid || this.customProject.selected)
        )?.title ?? 'Unassigned'
      )
    },
    setDefaultDates() {
      // if (this.calendarWeeksStartDate == null) {
      //   this.$store.commit(
      //     'setTimelogsCalendarWeeksStartDate',
      //     startOfWeek(new Date(), { weekStartsOn: 1 })
      //   )
      // }
      return new Array(14)
        .fill(0)
        .map((_, i) =>
          format(addDays(this.calendarWeeksStartDate, i), 'yyyy-MM-dd')
        )
        .filter((_, i) => this.showWeekends || i % 7 < 5)
    },
    filterByUnassignedProjects(options) {
      return [...options].filter((prj) => {
        const project =
          this.weeks.projects.find((p) => p.project.uid === prj.value) ||
          this.weeks.projectsToAdd.find((p) => p.project.uid === prj.value)
        return !project
      })
    },
    getBlocks() {
      let Blocks = {}

      // Group by assignments
      const assignments = this.assignments.reduce((acc, itm) => {
        acc[itm.assignment.uid] = acc[itm.assignment.uid] || []
        acc[itm.assignment.uid].push(itm)
        return acc
      }, {})
      // import assigned projects
      for (let itm in assignments) {
        const project = { ...assignments[itm][0].assignment, _isStaffing: true }
        const timelog = this.weeks.timelogs[project.uid] || {}
        Blocks[itm] = Blocks[itm] || {
          project,
          dates: this.weeks.defaultDates.reduce((acc, date) => {
            acc[date] = acc[date] || {
              uid: itm,
              assignmentDate: date,
              hours: timelog?.[date] ?? 0
            }
            return acc
          }, {})
          // dates: assignments[itm].reduce((acc, blk) => {
          //   acc[blk.assignmentDate] = acc[blk.assignmentDate] || {
          //     uid: blk.uid,
          //     assignmentDate: blk.assignmentDate,
          //     hours: timelog?.[blk.assignmentDate] ?? 0
          //   }
          //   return acc
          // }, {})
        }
      }

      // import unassigned projects
      for (const timelog in this.weeks.timelogs) {
        if (!Blocks[timelog]) {
          // const project = this.weeks.projects.find((prj) => prj.uid === timelog)
          Blocks[timelog] = {
            project: {
              uid: timelog,
              title: null
            },
            dates: this.weeks.defaultDates.reduce((acc, date) => {
              acc[date] = acc[date] || {
                uid: null,
                assignmentDate: date,
                hours: this.weeks.timelogs[timelog][date] ?? 0
              }
              return acc
            }, {})
          }
        }
      }

      this.weeks.projects = Object.values(Blocks)

      this.weeks.dates = new Array(14)
        .fill(0)
        .map((_, i) => {
          const date = format(
            addDays(this.calendarWeeksStartDate, i),
            'yyyy-MM-dd'
          )
          return {
            date,
            projects: this.weeks.projects.map((prj) => {
              return {
                project: prj.project,
                date,
                blocks: Object.values(prj.dates).filter((blk) =>
                  isSameDay(new Date(blk.assignmentDate), new Date(date))
                )
              }
            })
          }
        })
        .filter((_, i) => this.showWeekends || i % 7 < 5)

      this.weeks.dates = this.weeks.dates.reduce((acc, itm) => {
        acc[itm.date] = acc[itm.date] || itm.projects
        return acc
      }, [])

      return this.weeks.dates
    },
    isToday(date) {
      return isToday(new Date(date))
    },
    isWeekend(date) {
      return isWeekend(new Date(date))
    },
    isNotPassed(date) {
      return isBefore(new Date(date), new Date())
    },
    formatHeaderDate(date, cformat = 'EEE do') {
      return format(new Date(date), cformat)
    },
    getSelectedStyle(color) {
      return {
        'background-color': color,
        color: '#fff'
      }
    },
    isProjectAssignedDay(date, { uid }) {
      const project = this.weeks.dates[date].find(
        (prj) => prj.project.uid === uid
      )
      return project.blocks.length > 0
    },
    importTimelogs() {
      // Group by project
      const groupedByProject = this.timelogs.reduce((acc, itm) => {
        acc[itm.project.uid] = acc[itm.project.uid] || []
        acc[itm.project.uid].push(itm)
        return acc
      }, {})
      for (const prj in groupedByProject) {
        groupedByProject[prj] = groupedByProject[prj].reduce((acc, itm) => {
          acc[itm.date] = acc[itm.date] || 0
          acc[itm.date] += itm.hours
          return acc
        }, {})
      }
      this.weeks.timelogs = groupedByProject
      this.showTooltip()
    },
    updateTimelog(project, date, ev) {
      if (this.loading) return
      const hours = ev?.target?.value ?? ev
      // this.$store.commit('timelogToQueue', {
      //   project,
      //   date,
      //   hours: ev?.target?.value ?? ev
      // })
      this.$root.$emit('update-timelog', {
        project,
        date,
        hours: parseFloat(hours)
      })
      const elemId = 'blktl_' + project + '_' + date
      if (this.highlightedBlocks.includes(elemId)) {
        this.highlightedBlocks.splice(
          this.highlightedBlocks.findIndex((itm) => itm === elemId),
          1
        )
        const elm = document.getElementById(elemId)
        elm.value = parseFloat(hours)
      }
    },
    addNewTimelog(date) {
      if (!this.customProject.selected || this.loading) return
      if (this.customProject.hours[date] > 24) {
        this.customProject.hours[date] = 24
      }
      this.updateTimelog(
        this.customProject.selected,
        date,
        this.customProject.hours[date]
      )
      this.showTooltip()
    },
    isInQueueToRemove(projectUID) {
      return (
        this.logsToRemoveQueue.findIndex((log) => log.project === projectUID) !=
        -1
      )
    },
    delProjectFromRemoveQueue(projectUID) {
      this.$store.commit('deleteTimelogFromRemoveQueue', {
        project: projectUID
      })
    },
    removeProject(projectUID) {
      if (this.loading) return
      let totalErrShows = 0
      const dates = {}
      for (const date in this.weeks.dates) {
        const blk =
          this.weeks.dates[date]
            .find((prj) => prj.project.uid === projectUID)
            ?.blocks?.find((blk) => blk.assignmentDate === date) ?? null
        dates[date] = dates[date] || {
          project: projectUID,
          date,
          hours: blk?.hours ?? 0
        }
        // if (blk) {
        //   dates[date] = dates[date] || {
        //     project: projectUID,
        //     date,
        //     hours: blk.hours
        //   }
        // } else {
        //   if (totalErrShows++ > 0) continue
        //   dates[date] = null
        //   // this.$bvToast.toast('You can not delete this project', {
        //   //   title: 'Notice',
        //   //   variant: 'danger',
        //   //   solid: true
        //   // })
        // }
      }
      for (const date in dates) {
        if (dates[date]?.hours ?? false)
          this.$store.commit('timelogToRemoveQueue', dates[date])
      }
      this.selectedProjectToRemove = projectUID
      this.deleteInProgress = true
    },
    showTooltip(ev) {
      if (ev) {
        const elmId = ev?.target?.id ?? null
        if (elmId) {
          const highlightBlocksIndex = this.highlightedBlocks.findIndex(
            (itm) => itm === elmId
          )
          if (highlightBlocksIndex == -1) {
            this.highlightedBlocks.push(elmId)
          } else {
            // this.highlightedBlocks.splice(highlightBlocksIndex, 1)
          }
        }
      }
      if (!this.showSaveTooltip) {
        this.$root.$emit('bv::show::tooltip', 'tooltip-target-1')
      }
      this.$root.$on('bv::show::tooltip', (id) => {
        if (id === 'tooltip-target-1') {
          setTimeout(() => {
            this.$root.$emit('bv::hide::tooltip', 'tooltip-target-1')
          }, 10000)
        }
      })
    },
    isActiveBlock(puid, date) {
      return this.highlightedBlocks.includes('blktl_' + puid + '_' + date)
    },
    onCancel() {
      this.deleteInProgress = false
      this.selectedProjectToRemove = null
      this.$store.commit('clearDeleteTimelogQueue')
    },
    onConfirm() {
      this.$root.$emit('delete-timelog')
      this.deleteInProgress = false
      this.selectedProjectToRemove = null
    }
  }
}
</script>

<style scoped>
.calendar-header {
  background-color: rgb(0, 123, 255);
  padding: 0 !important;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  height: 40px;
  line-height: 40px;
  min-width: 100% !important;
  max-width: 100% !important;
}
.calendar-header .team {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
}
.calendar-header .loading {
  background-color: rgb(177, 50, 50) !important;
  color: #fff;
}
.calendar-header .day-holder {
  text-align: center;
  padding: 0 !important;
  width: 7% !important;
  max-width: 7% !important;
}
.calendar-header .day-holder.today {
  background-color: rgb(0, 51, 102) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend.today {
  color: rgb(0, 123, 255) !important;
}
.calendar-body {
  background-color: #eee;
  min-height: 100px;
  max-height: 100px;
  min-width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
}
.calendar-body.bgOdd {
  background-color: #f5f5f5 !important;
  padding: 0 !important;
}
.calendar-body .team {
  color: #fff;
  background-color: #333;
  border-top: #999 solid 1px;
  font-size: 10pt;
  font-weight: bold;
  display: inline-block;
  padding: 0 !important;
  text-align: center;
  max-height: 100px !important;
  overflow-y: auto !important;
}
.calendar-body .team ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 5px !important;
}
.calendar-body .team ul li {
  margin: 5px 0;
}
.calendar-body .team ul li .remove-btn {
  font-weight: bold;
  padding: 6px 4px;
}
.calendar-body .team ul li .remove-btn:hover {
  cursor: pointer;
}
.calendar-body .block {
  text-align: center;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll !important;
  padding: 0 !important;
  max-height: 100px !important;
  width: 7% !important;
  max-width: 7% !important;
}
.calendar-body .block.weekend {
  background-color: rgb(177, 177, 177) !important;
}
.calendar-body .block.highlighted {
  background-color: rgb(240, 240, 190) !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.calendar-body .block:hover {
  background-color: #ccc;
  cursor: default;
  padding: 0 !important;
}
.calendar-body .block .project {
  margin: 5px;
  padding: 10px;
  font-size: 10pt;
  color: #fff;
  font-weight: bold;
  overflow-wrap: break-word;
  max-width: 100px !important;
}
.calendar-body .block .timelog {
  background-color: transparent;
  border: none;
  text-align: center;
  height: 100px;
  font-size: 12pt;
  font-weight: bold;
  color: #999;
}
.calendar-body .block .timelog:focus {
  color: #333;
}
.addProjectTxt {
  color: #fff;
}

.addProjectTxt:hover {
  color: #fff;
}

.lizard-timelog-project-select {
  width: 80%;
  margin: 0 auto;
}

.lizard-delete-overlay-message {
  font-size: 12pt;
  font-weight: bold;
  color: #333;
}

.lizard-logs-to-remove-list {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}

.lizard-logs-to-remove-list li {
  margin: 0 5px;
  padding: auto;
  font-size: 10pt;
  display: inline-block;
  font-weight: 600;
  color: #666;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
