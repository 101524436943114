import { render, staticRenderFns } from "./ProjectSelector.vue?vue&type=template&id=34935b24&scoped=true"
import script from "./ProjectSelector.vue?vue&type=script&lang=js"
export * from "./ProjectSelector.vue?vue&type=script&lang=js"
import style0 from "./ProjectSelector.vue?vue&type=style&index=0&id=34935b24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34935b24",
  null
  
)

export default component.exports