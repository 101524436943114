<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-sm-12">
        <b-form-input
          v-model="q"
          placeholder="Search by person name, email, project code, category or reference codes..."
        ></b-form-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <!-- <div class="row">
      <div class="col-sm-12 text-center">
        <b-form-checkbox-group
          id="status-group"
          :checked="SelectedExpenseStatus"
          :options="StatusOptions"
          @change="filterByStatus"
          size="lg"
          button-variant="outline-secondary"
          buttons
          name="status-options"
        ></b-form-checkbox-group>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div> -->
    <div class="row">
      <div class="col-sm"></div>
      <div class="col-sm-3 text-right"><hr /></div>
      <div class="col-sm-1 text-center">
        <b-link
          class="filters-text active"
          v-if="showAdvancedFilters"
          @click="showAdvancedFilters = false"
          ><b-icon-caret-down-fill scale="0.9" />advanced filters</b-link
        >
        <b-link class="filters-text" @click="showAdvancedFilters = true" v-else
          ><b-icon-caret-up-fill scale="0.9" />advanced filters</b-link
        >
      </div>
      <div class="col-sm-3 text-left"><hr /></div>
      <div class="col-sm"></div>
    </div>
    <div class="row" v-if="showAdvancedFilters">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="showAdvancedFilters">
      <div class="col-sm-12 text-center">
        <b-form-radio-group
          class="allowance-year-group"
          v-model="selectedDateLabel"
          :options="dateLabels"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-radio-group
        >&nbsp;
        <b-form-radio-group
          class="allowance-year-group"
          v-model="selectedYear"
          :options="years"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-radio-group
        >&nbsp;
        <b-form-checkbox-group
          class="allowance-year-group"
          v-model="selectedMonth"
          :options="months"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-checkbox-group>
      </div>
    </div>
  </b-container>
</template>

<script>
import { differenceInQuarters, format as dateFormat } from 'date-fns'

export default {
  name: 'FiltersBar',
  data() {
    return {
      showAdvancedFilters: false,
      q: '',
      statusFilter: {
        selected: []
      },
      selectedDateLabel: 'Filter by Submitted Date',
      selectedYear: null,
      selectedMonth: [],
      dateLabels: ['Filter by Submitted Date', 'Filter by Expense Date'],
      years: [],
      months: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    StatusOptions() {
      return this.Store.expenseStatusOptions
    },
    SelectedExpenseStatus() {
      return this.Store.selectedExpenseStatus
    }
  },
  mounted() {
    const now = new Date()
    const currentYear = now.getFullYear()
    const sideYear =
      currentYear +
      (!Math.abs(
        differenceInQuarters(now, new Date(currentYear - 1, 1, 1, 0, 0, 0, 0))
      )
        ? 0
        : -1)
    this.years = [sideYear, currentYear]
    this.selectedYear = currentYear
    this.$root.$on('expenseSearchQueryChanged', (q) => {
      this.q = q
    })
  },
  methods: {
    updateSearchQuery(q) {
      this.$store.commit('setExpenseSearchQuery', q)
    },
    filterByStatus(statuses) {
      this.$store.commit('setSelectedExpenseStatus', statuses)
    },
    yearChanged() {
      if (!this.selectedYear) {
        return
      }
      const now = new Date()
      const currentYear = now.getFullYear()
      const selectedMonth =
        this.selectedYear === currentYear ? now.getMonth() + 1 : 12
      this.months = Array.from({ length: selectedMonth }, (v, k) => k + 1).map(
        (month) => {
          return dateFormat(new Date(this.selectedYear, month - 1, 1), 'MMMM')
        }
      )
      this.selectedMonth = [
        this.selectedYear === currentYear ? this.months.length - 1 : 0
      ]
      if (this.selectedMonth[0] - 1 >= 0) {
        this.selectedMonth.unshift(this.selectedMonth[0] - 1)
      }
      this.selectedMonth = this.selectedMonth.map((idx) => {
        return this.months[idx]
      })
    },
    monthChanged() {
      if (this.selectedMonth.length == 0) {
        this.selectedYear = null
      } else {
        this.$store.commit('setSelectedExpenseDateRange', {
          year: this.selectedYear,
          months: this.selectedMonth,
          dateLabel: this.selectedDateLabel
        })
      }
    }
  },
  watch: {
    q: {
      handler: 'updateSearchQuery',
      immediate: true
    },
    selectedDateLabel: {
      handler: 'monthChanged',
      immediate: true
    },
    selectedYear: {
      handler: 'yearChanged',
      immediate: true
    },
    selectedMonth: {
      handler: 'monthChanged',
      immediate: true
    }
  }
}
</script>

<style>
#status-group label {
  background-color: #eee !important;
  color: #ccc !important;
}
#status-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#status-group label.active:first-child {
  background-color: #ffc23d !important;
  color: #fff !important;
}
#status-group label.active:nth-child(2) {
  background-color: #1aa2b8 !important;
  color: #fff !important;
}
#status-group label.active:nth-child(3) {
  background-color: #dc3b45 !important;
  color: #fff !important;
}
#status-group label.active:last-child {
  background-color: #4aa746 !important;
  color: #fff !important;
}
.filters-text {
  font-size: 0.9rem;
  color: #ccc;
  font-weight: 600;
  font-variant: small-caps;
}
.filters-text:hover {
  text-decoration: none;
  color: #999;
}
.filters-text:active {
  color: #333;
}
.filters-text.active {
  color: #999;
}
</style>
