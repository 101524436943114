<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-12">
        <user-list :listItems="listItems" :clonedItems="clonedItems" />
      </div>
    </div>
  </b-container>
</template>

<script>
import UserList from './users-components/List.vue'
import Services from '../../services/main.service.vue'
import ServicesOld from '../../services/index.vue'
import { format as dateFormat } from 'date-fns'

export default {
  name: 'UsersComponenet',
  components: {
    UserList
  },
  data() {
    return {
      loading: false,
      listItems: [],
      clonedItems: [],
      inactiveListItems: [],
      assignments: {}
    }
  },
  mounted() {
    this.tabMounted()
    this.$root.$on('refresh-user-list', this.fetchData)
    // this.$root.$on('hourly-rate-changed', this.changeHourlyRate)
    // this.$root.$on('daily-rate-changed', this.changeDayRate)
    this.$root.$on('current-employee-changed', this.changeCurrentEmployee)
    this.$root.$on('role-changed', this.changeRole)
    this.$root.$on('search-user', this.searchUser)
  },
  methods: {
    async tabMounted() {
      this.$root.$emit('reset-search-user')
      this.assignments = await this.fetchStaffingStatus()
      await this.fetchData()
    },
    async fetchData() {
      try {
        this.loading = true
        const Users = await Services.Users.Admin.getList()
        this.listItems = Users.data.content
          .sort((a, b) => {
            if (a.email < b.email) {
              return -1
            }
            if (a.email > b.email) {
              return 1
            }
            return 0
          })
          .sort((a, b) => {
            if (a.settings.active && !b.settings.active) {
              return -1
            }
            if (!a.settings.active && b.settings.active) {
              return 1
            }
            return 0
          })
          .map((itm) => {
            itm._edit = false
            return itm
          })
        // group assignments by owner
        this.assignments = this.assignments.reduce((acc, itm) => {
          if (!acc[itm.owner.uid]) {
            acc[itm.owner.uid] = []
          }
          acc[itm.owner.uid].push(itm)
          return acc
        }, {})
        this.listItems
          .map((itm) => {
            itm.assignment = this.assignments[itm.uid] || []
            return itm
          })
          .forEach((itm) => {
            const lastTimelogDate = itm?.timelogs?.length
              ? itm?.timelogs[itm?.timelogs.length - 1]?.date
              : null
            if (!lastTimelogDate) {
              this.inactiveListItems.push(itm)
            } else {
              const cond =
                this.$moment().diff(
                  this.$moment(new Date(lastTimelogDate)),
                  'days'
                ) > 1
              itm.lastActivityEntry = lastTimelogDate
              if (cond) {
                itm.inactivityPeriod = this.$moment(
                  new Date(lastTimelogDate)
                ).fromNow()
                this.inactiveListItems.push(itm)
              } else {
                itm.inactivityPeriod = 'Active'
              }
            }
          })
        this.clonedItems = [...this.listItems]
        setTimeout(() => {
          this.loading = false
        }, 500)
      } catch (Exception) {
        console.error(Exception)
      }
    },
    async fetchStaffingStatus() {
      try {
        const fromDate = dateFormat(Date.now(), 'yyyy-MM-dd')
        const { status, data } = await ServicesOld.GetStaffingAssignments({
          fromDate,
          weeks: 0
        })
        return status == 200
          ? data.content
              .filter((itm) => {
                return itm.assignmentDate === fromDate
              })
              .map((itm) => {
                return {
                  uid: itm.uid,
                  owner: {
                    uid: itm.owner.uid,
                    email: itm.owner.email
                  },
                  assignment:
                    itm.type == 'project'
                      ? {
                          type: itm.type,
                          title: itm.assignment.title,
                          meta: itm.assignment.meta
                        }
                      : {
                          type: itm.type,
                          meta: itm.meta
                        }
                }
              })
              .reverse()
              .filter((itm) => {
                return itm.assignment.type == 'holiday' &&
                  /^WFH.+/.test(itm.assignment.meta.holidayType)
                  ? false
                  : true
              })
          : []
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    changeHourlyRate({ id, value }) {
      this.listItems = this.listItems.map((itm) => {
        if (itm.id === parseInt(id)) {
          itm.hourlyRate = parseFloat(value)
          itm.dayRate = itm.hourlyRate * 8
        }
        return itm
      })
    },
    changeDayRate({ id, value }) {
      this.listItems = this.listItems.map((itm) => {
        if (itm.id === parseInt(id)) {
          itm.dayRate = parseFloat(value)
          itm.hourlyRate = Math.round(itm.dayRate / 8)
        }
        return itm
      })
    },
    changeCurrentEmployee({ id: uid, checked, key = 'active' }) {
      const listIndex = this.listItems.findIndex((itm) => itm.uid === uid)
      if (listIndex === -1) {
        return
      }
      this.listItems[listIndex].settings[key] = Boolean(checked)
      return this.saveChanges(uid)
    },
    changeRole({ id: uid, role }) {
      const listIndex = this.listItems.findIndex((itm) => itm.uid === uid)
      if (listIndex === -1) {
        return
      }
      this.listItems[listIndex].role = role
      return this.saveChanges(uid)
    },
    searchUser(q = null) {
      if (q && q.trim().length > 1) {
        const keywords = q
          .trim()
          .split(' ')
          .map((keyword) => keyword.trim())
        const filterBasedOnHolidays = keywords[0].toLowerCase() === ':off'
        const filterBasedOnTimesheetAccess =
          keywords[0].toLowerCase() === ':timesheet'
        const filterBasedOnStaffingAccess =
          keywords[0].toLowerCase() === ':staffing'
        this.listItems = this.clonedItems.filter((itm) => {
          if (filterBasedOnHolidays) {
            return itm.assignment[0]?.assignment?.type === 'holiday'
          }
          if (filterBasedOnTimesheetAccess) {
            return itm.settings.active
          }
          if (filterBasedOnStaffingAccess) {
            return itm.settings?.staffing ?? false
          }
          const fullName = `${itm?.firstName?.trim() ?? ''} ${
            itm?.lastName?.trim() ?? ''
          }`
          return keywords.every((keyword) => {
            const qRegEx = new RegExp(keyword, 'i')
            return (
              qRegEx.test(fullName) ||
              qRegEx.test(itm.email) ||
              qRegEx.test(itm.role) ||
              q === itm.uid
            )
          })
        })
      } else {
        this.listItems = this.clonedItems
      }
    },
    async saveChanges(userUid) {
      try {
        if (this.loading) {
          return
        }
        this.loading = true
        const listIndex = this.listItems.findIndex((itm) => itm.uid === userUid)
        console.log({ toUpdate: this.listItems[listIndex] })
        await Services.Users.Admin.updateUser({
          uid: userUid,
          data: this.listItems[listIndex]
        })
        this.loading = false
      } catch (Exception) {
        this.loading = false
        this.$toasted.error('Failed! Please try again later.')
      }
    }
  }
}
</script>
