<template>
  <div class="text-left">
    <b-form-radio-group
      class="allowance-year-group"
      v-model="selectedYear"
      :options="years"
      @change="yearChanged"
      button-variant="outline-secondary"
      name="allowance-years"
      buttons></b-form-radio-group>
  </div>
</template>

<script>
import { differenceInMonths } from 'date-fns'

export default {
  name: 'FilterBar',
  data() {
    return {
      selectedYear: null,
      years: []
    }
  },
  mounted() {
    const now = new Date()
    const currentYear = now.getFullYear()
    const compareDate = new Date(currentYear + 1, 0, 1, 0, 0, 0, 0)
    const diff = 12 - Math.abs(differenceInMonths(now, compareDate))
    const sideYear = currentYear + (diff >= 2 ? 1 : -1)
    this.years = [currentYear, sideYear].sort((a, b) => a - b)
    this.selectedYear = currentYear
    this.yearChanged()
  },
  methods: {
    yearChanged() {
      this.$root.$emit('yearChanged', this.selectedYear)
    }
  }
}
</script>
