<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-sm-12">
        <b-form-input
          placeholder="Search..."
          :value="keyword"
          @keyup="updateSearchQuery"
          @blur="updateSearchQuery"
          :disabled="loading"
        ></b-form-input>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'SearchActivities',
  props: ['loading', 'keyword'],
  data() {
    return {
      q: ''
    }
  },
  methods: {
    updateSearchQuery(ev) {
      this.q = ev.target.value.trim()
      this.$root.$emit('setActivitiesSearchQuery', this.q)
    }
  }
}
</script>

<style>
#status-group label {
  background-color: #eee !important;
  color: #ccc !important;
}
#status-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
#status-group label.active:first-child {
  background-color: #ffc23d !important;
  color: #fff !important;
}
#status-group label.active:nth-child(2) {
  background-color: #1aa2b8 !important;
  color: #fff !important;
}
#status-group label.active:nth-child(3) {
  background-color: #dc3b45 !important;
  color: #fff !important;
}
#status-group label.active:last-child {
  background-color: #4aa746 !important;
  color: #fff !important;
}
.filters-text {
  font-size: 0.9rem;
  color: #ccc;
  font-weight: 600;
  font-variant: small-caps;
}
.filters-text:hover {
  text-decoration: none;
  color: #999;
}
.filters-text:active {
  color: #333;
}
.filters-text.active {
  color: #999;
}
</style>
