<template>
  <b-container
    fluid
    class="bg-white"
    :class="{ 'lizard-no-padding': mobileView }">
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-sm-1"></div>
      <div class="col-sm-4">
        <FilterBar v-if="showYearFilterButtons && !mobileView" />
      </div>
      <div class="col-sm-2">&nbsp;</div>
      <div
        class="col-sm-4"
        :class="{ 'text-right': !mobileView, 'text-center': mobileView }">
        <b-button
          variant="outline-info"
          class="lizard-view-link"
          size="sm"
          @click="toggleNewActivityModal()">
          Add New Leave Request
        </b-button>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <HolidayStats :selectedYear="selectedYear" :mobileView="mobileView" />
    </div>
    <div class="row bg-white" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <HolidayList :selectedYear="selectedYear" :mobileView="mobileView" />
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-modal
      size="xl"
      v-model="newActivityModal.show"
      centered
      :title="newActivityModal.data.title"
      :header-bg-variant="newActivityModal.headerBgVariant"
      :header-text-variant="newActivityModal.headerTextVariant"
      :body-bg-variant="newActivityModal.bodyBgVariant"
      :body-text-variant="newActivityModal.bodyTextVariant"
      :footer-bg-variant="newActivityModal.footerBgVariant"
      :footer-text-variant="newActivityModal.footerTextVariant">
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <ActivityEntry :activityType="newActivityModal.activityType" />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="toggleNewActivityModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services'
import Services from '../../../services/main.service'
import ActivityEntry from '../../ActivityEntry.vue'

import FilterBar from './FilterBar.vue'
import HolidayList from './List.vue'
import HolidayStats from './Stats.vue'

export default {
  name: 'HolidayLogs',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  components: {
    FilterBar,
    HolidayList,
    HolidayStats,
    ActivityEntry
  },
  computed: {
    showYearFilterButtons() {
      return !this.mobileView
    }
  },
  data() {
    return {
      viewType: 'list',
      deleteItemId: null,
      refreshData: false,
      statsFields: [
        {
          key: 'totalAnnualHoliday',
          label: 'Total Annual Holiday',
          thClass: 'lizard-activity-list-type',
          tdClass: 'lizard-activity-list-type'
        },
        {
          key: 'totalPersonalLeave',
          label: 'Total Personal Holiday',
          thClass: 'lizard-activity-list-date',
          tdClass: 'lizard-activity-list-date'
        }
      ],
      listItems: [],
      statsItems: [],
      calendarItems: {},
      calDate: '',
      context: null,
      newActivityModal: {
        show: false,
        activityType: 'hld',
        headerBgVariant: 'primary',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          selectedDate: null,
          title: 'New Leave Request (Please remember to click Submit button)',
          listFields: [
            {
              key: 'name',
              label: 'Name',
              sortable: true
            },
            {
              key: 'hours',
              label: 'Hours',
              sortable: true,
              thClass: 'lizard-column-hours',
              tdClass: 'lizard-column-hours'
            }
          ],
          listItems: [],
          perPage: 10,
          currentPage: 1
        }
      },
      selectedYear: new Date().getFullYear()
    }
  },
  mounted() {
    this.$root.$on('yearChanged', this.yearChanged)
  },
  methods: {
    async toggleNewActivityModal() {
      try {
        await Services.Users.fetchCurrentUser()
        this.newActivityModal.show = !this.newActivityModal.show
      } catch (Exception) {
        this.$root.$emit('user-signed-out')
      }
    },
    yearChanged(year) {
      this.selectedYear = year
    }
  }
}
</script>

<style>
.lizard-help-list ul {
  list-style: none;
  display: inline-block;
  margin: 10px 0;
  padding: 0;
}
.lizard-help-list ul li {
  display: inline-block;
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 11px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
}
.no-activity-calday {
  background-color: rgb(236, 143, 143) !important;
  color: #fff !important;
}
.half-activity-calday {
  background-color: #ccc !important;
  color: #333 !important;
}
.full-activity-calday {
  background-color: rgb(157, 247, 157) !important;
  color: #333 !important;
}
</style>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-activity-list-index {
  width: 5% !important;
  text-align: center !important;
}
.lizard-activity-list-type {
  min-width: 30% !important;
}
.lizard-activity-list-date {
  width: 12% !important;
  text-align: center !important;
}
.lizard-activity-list-duration {
  width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-notes {
  width: 35% !important;
  text-align: justify !important;
}
.lizard-activity-list-action {
  max-width: 6% !important;
  text-align: center !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-view-link {
  font-weight: bold;
}
.lizard-today-btn {
  margin: 10px;
  float: right;
}
</style>
