<template>
  <b-container fluid class="bg-white custom-font-size">
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg-2 lizard-no-margin left-switcher">
        <div class="lizard-week-switcher-btn" @click="prevWeek()">
          previous week
        </div>
      </div>
      <div class="col-lg-8 month-holder">
        <div class="col-lg month-holder" @click="currentWeek()">
          <div style="cursor: pointer" title="Go to current weeks">
            {{ monthInfo }}
          </div>
        </div>
      </div>
      <div class="col-lg-2 lizard-no-margin right-switcher">
        <div class="lizard-week-switcher-btn" @click="nextWeek()">
          next week
        </div>
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row lizard-calendar">
      <div class="col-lg-12 lizard-calendar-holder">
        <TwoWeeksCalendarTimelog
          :loading="loading"
          :projects="projects"
          :assignments="assignments"
          :timelogs="timelogs"
          :showWeekends="showWeekends"
          :showSaveTooltip="showSaveTooltip"
        />
        <h6>
          <b-badge pill variant="light" style="color: #999"
            >* If you don't see any staffed projects in the list, please ask the
            admin team to activate the project.</b-badge
          >
        </h6>
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-10 text-right">
        <b-tooltip
          target="tooltip-target-1"
          :show="showSaveTooltip"
          @update:show="(val) => (showSaveTooltip = val)"
          triggers="hover"
          variant="info"
          placement="topright"
        >
          Remember to click <strong>Submit Changes</strong> button!
        </b-tooltip>
      </div>
      <div class="col-lg hours-controllers">
        <ul>
          <li>
            <div
              id="tooltip-target-1"
              v-if="!savingMode"
              class="lizard-hour-controller-btn"
              :class="{
                'is-active': savingMode
              }"
              @click="tabMounted"
            >
              <b-icon-check2-square
                scale="1.5"
                style="margin-right: 10px"
              />Submit Changes
            </div>
            <div
              v-else
              class="lizard-hour-controller-btn"
              :class="{
                'is-active': savingMode
              }"
            >
              <b-icon-hourglass-split
                scale="1.5"
                style="margin-right: 10px"
              />Please wait...
            </div>
          </li>
          <!-- <li v-if="!savingMode && !editMode">
            <div class="lizard-hour-controller-btn" @click="refreshData">
              <b-icon-arrow-repeat
                scale="1.5"
                style="margin-right: 10px" />Refresh
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, sub, add, differenceInDays } from 'date-fns'
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import TwoWeeksCalendarTimelog from './Timelogs/Calendar.vue'

export default {
  name: 'TimeLogs',
  components: {
    TwoWeeksCalendarTimelog
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    },
    logsInQueue() {
      return this.Store.timelogsInQueue
    },
    logsToRemoveQueue() {
      return this.Store.timelogsToRemoveQueue
    }
  },
  data() {
    return {
      showSaveTooltip: false,
      showWeekends: true,
      monthInfo: dateFormat(new Date(), 'MMMM yyyy'),
      projects: [],
      assignments: [],
      timelogs: [],
      loading: false,
      dataFetched: false,
      editMode: false,
      savingMode: false
    }
  },
  mounted() {
    this.$root.$on('parent_tab_timelogs_selected', this.tabMounted)
    this.$root.$on('get-projects', () => {
      this.getProjects()
    })
    this.$root.$on('update-timelog', this.updateTimelog)
    this.$root.$on('delete-timelog', this.deleteLogs)
  },
  methods: {
    tabMounted() {
      this.setDefaultCalendarWeeksStartDate()
      this.currentWeek()
      this.getProjects()
      setTimeout(this.prevWeek, 500)
      // setTimeout(this.refreshData, 600)
    },
    prevWeek() {
      this.$store.commit(
        'setTimelogCalendarWeeksStartDate',
        sub(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    nextWeek() {
      this.$store.commit(
        'setTimelogCalendarWeeksStartDate',
        add(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    currentWeek() {
      this.setDefaultCalendarWeeksStartDate()
      this.setCurrentMonth()
    },
    setDefaultCalendarWeeksStartDate(setNull = false) {
      if (setNull) {
        this.$store.commit('setTimelogCalendarWeeksStartDate', null)
      } else {
        this.$store.commit('setTimelogCalendarWeeksStartDate')
      }
    },
    setCurrentMonth() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      const now = new Date()
      const startDate = this.calendarWeeksStartDate
      const isCurrentWeek = this.isWithinTwoWeeks(now, startDate)

      const monthsDays = this.countDaysByMonth(startDate)
      const selectedMonth = this.getFirstMonthWithMinDays(monthsDays, 7)
      const selectedYear = isCurrentWeek
        ? this.getYear(now)
        : this.getYear(startDate)
      this.monthInfo = selectedMonth + ' ' + selectedYear
      this.editMode = false
      this.clearChangeQueues(false, true)
    },
    toggleEditMode() {
      this.editMode = !this.editMode
      this.clearChangeQueues()
    },
    clearChangeQueues(updTls = true, delTls = true) {
      if (updTls) {
        this.$store.commit('clearTimelogQueue')
      }
      if (delTls) {
        this.$store.commit('clearDeleteTimelogQueue')
      }
    },
    async refreshData() {
      this.$root.$emit('week-changed-on-timelogs')
      await this.getUserTimelogs()
      await this.getUserAssignments()
      this.dataFetched = true
    },
    queueIterator(queue, idx = 0) {
      return {
        next() {
          const result = queue[idx]
          idx += 1
          return {
            value: result,
            done: !result
          }
        }
      }
    },
    submitChanges() {
      this.savingMode = true
      this.submitLogs()
      this.deleteLogs()
      this.savingMode = false
      setTimeout(() => {
        this.loading = true
        this.editMode = false
        this.refreshData()
      }, 500)
    },
    submitLogs() {
      const itr = this.queueIterator(this.logsInQueue)
      let next = itr.next()
      while (!next.done) {
        this.updateTimelog(next.value)
        next = itr.next()
      }
      if (next.done) {
        this.$store.commit('clearTimelogQueue')
      }
    },
    deleteLogs() {
      const itr = this.queueIterator(this.logsToRemoveQueue)
      let next = itr.next()
      while (!next.done) {
        this.removeTimelog(next.value)
        next = itr.next()
      }
      if (next.done) {
        this.$store.commit('clearDeleteTimelogQueue')
      }
      setTimeout(() => {
        this.refreshData()
      }, 500)
    },
    async getProjects() {
      this.loading = true
      const { status, data } = await Services.Projects.getMondayProjects()
      this.loading = false
      if (status == 200) {
        this.projects = data.content
          .map((prj) => {
            const prjType =
              prj.type === 'business_development' ? 'BD' : 'Project'
            if (prjType === 'BD') {
              const hasBDPerfix = /^BD/.test(prj.title)
              if (!hasBDPerfix) {
                prj.title = `BD ${prj.title}`
                if (prj.client) {
                  prj.title = `${prj.title} (${prj.client})`
                }
              }
            }
            return prj
          })
          .sort((a, b) => {
            if (a.title < b.title) return -1
            if (a.title > b.title) return 1
            return 0
          })
      }
    },
    async getUserAssignments() {
      this.loading = true
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      const { status, data } = await ServicesOld.GetStaffingAssignmentsByUser({
        fromDate: dateFormat(this.calendarWeeksStartDate, 'yyyy-MM-dd')
      })
      if (status == 200) {
        this.assignments = data.content
      }
      this.loading = false
    },
    async getUserTimelogs() {
      this.loading = true
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      const { status, data } = await ServicesOld.GetTimeLogs({
        dates: {
          from: dateFormat(new Date(this.calendarWeeksStartDate), 'yyyy-MM-dd'),
          to: dateFormat(
            add(new Date(this.calendarWeeksStartDate), {
              days: 13
            }),
            'yyyy-MM-dd'
          )
        }
      })
      if (status == 200) {
        this.timelogs = data.content
      }
      this.loading = false
    },
    async updateTimelog({ project: projectUid, date, hours }) {
      if (hours > 24) {
        this.$bvToast.toast('You can not log more than 24 hours per day', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
        return
      }
      this.loading = true
      const { status } = await ServicesOld.AddTimelog({
        projectUid,
        date,
        hours
      })
      this.loading = false
      return { status }
    },
    async removeTimelog({ project: projectUid, date }) {
      this.loading = true
      const { status } = await ServicesOld.RemoveTimelog({
        projectUid,
        date
      })
      this.loading = false
      return { status }
    },
    isWithinTwoWeeks(date1, date2) {
      const diffInDays = differenceInDays(add(date2, { days: 13 }), date1)
      return diffInDays > 0 && diffInDays < 14
    },
    countDaysByMonth(startDate) {
      const monthsDays = {}
      const start = new Date(startDate)
      const end = add(new Date(startDate), { days: 13 })

      const loopDate = new Date(start)
      while (loopDate <= end) {
        const month = this.getMonthName(loopDate)
        monthsDays[month] = (monthsDays[month] || 0) + 1
        loopDate.setDate(loopDate.getDate() + 1)
      }

      return monthsDays
    },
    getFirstMonthWithMinDays(monthsDays, minDays) {
      for (const month in monthsDays) {
        if (monthsDays[month] >= minDays) {
          return month
        }
      }
      return null
    },
    getMonthName(date) {
      try {
        return dateFormat(date, 'MMMM')
      } catch (error) {
        console.error({ error })
        return null
      }
    },
    getYear(date) {
      try {
        return dateFormat(date, 'yyyy')
      } catch (error) {
        console.error({ error })
        return null
      }
    }
  },
  watch: {
    calendarWeeksStartDate() {
      setTimeout(this.refreshData, 500)
    }
  }
}
</script>

<style>
.lizard-calendar {
  /* overflow: hidden !important; */
  margin: 0 auto !important;
  padding: 0 !important;
}
.lizard-calendar > .lizard-calendar-holder {
  margin: 0 auto !important;
  padding: 0 !important;
}
.container-fluid.lizard-no-padding {
  padding-right: 0 !important;
}
.row.lizard-no-margin {
  margin-right: 0 !important;
}
</style>

<style scoped>
.lizard-week-switcher-btn {
  color: #fff;
  margin: 0;
  width: 100%;
  min-width: 65px !important;
  max-width: 100px !important;
  padding: 5px;
  text-align: center;
  font-size: 8pt;
  font-weight: bold;
  background-color: #036;
  text-transform: uppercase;
}
.lizard-week-switcher-btn.current-week {
  max-width: 100px !important;
  height: auto !important;
  margin: 0 auto !important;
}
.lizard-week-switcher-btn:hover {
  background-color: #036;
  color: #fff;
  cursor: pointer;
}
.lizard-week-switcher-btn:active {
  background-color: #036;
  color: #ccc;
}
.lizard-no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
.lizard-no-margin.left-switcher div {
  text-align: center;
  float: left;
  margin-left: 2%;
}
.lizard-no-margin.right-switcher div {
  text-align: center;
  float: right !important;
  margin-right: 2%;
}
.month-holder {
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  color: #036;
}
.hours-controllers {
  padding: 0 10px;
  margin: 0;
  text-align: right;
}
.hours-controllers ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.hours-controllers ul li {
  display: inline-block;
  margin: 0 1px;
}
.hours-controllers .lizard-hour-controller-btn {
  color: #fff;
  margin: 0;
  width: 100%;
  height: 40px;
  line-height: 30px;
  min-width: 140px !important;
  max-width: 100px !important;
  padding: 5px;
  text-align: center;
  font-size: 9pt;
  font-weight: bold;
  background-color: #999;
  text-transform: uppercase;
  border-radius: 3px;
}
.hours-controllers .lizard-hour-controller-btn.is-active {
  background-color: #333;
}
.hours-controllers .lizard-hour-controller-btn.current-week {
  max-width: 100px !important;
  height: auto !important;
  margin: 0 auto !important;
}
.hours-controllers .lizard-hour-controller-btn:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}
.hours-controllers .lizard-hour-controller-btn:active {
  background-color: #000;
  color: #ccc;
}
</style>

<style>
.custom-font-size {
  font-size: 8px !important;
}
</style>
