<template>
  <b-container fluid>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          :busy.sync="loading"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption>
            <span v-if="loading">
              <b-spinner small></b-spinner>
              Loading...
            </span>
            <code style="color: #666 !important" v-else>
              Page {{ pager.currentPage | prettyNumber }}/{{
                pager.totalPages | prettyNumber
              }}
              (Total Results: {{ pager.totalRows | prettyNumber }})
            </code>
          </template>
          <template #cell(email)="data">
            <h6>
              <b-badge
                v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                :title="data.item.email"
                @click="search(getEmailInitials(data.item.email))"
                style="cursor: pointer"
                variant="primary"
              >
                {{ getEmailInitials(data.item.email) }}
              </b-badge>
            </h6>
          </template>
          <template #cell(agent)="data">
            <h6>
              <b-badge variant="dark">
                {{ parseUA(data.item.agent).os.name }} /
                {{ parseUA(data.item.agent).browser.name }}@{{
                  parseUA(data.item.agent).browser.version
                }}
              </b-badge>
            </h6>
          </template>
          <template #cell(data)="data">
            <b-button
              size="sm"
              variant="info"
              @click="data.toggleDetails"
              class="mr-2"
            >
              {{ data.detailsShowing ? 'Hide' : 'Show' }} Request Object
            </b-button>
          </template>
          <template #cell(method)="data">
            <h6>
              <b-badge
                :variant="
                  getMethodVariant(exportEndpoint(data.item.endpoint).method)
                "
              >
                {{ exportEndpoint(data.item.endpoint).method }}
              </b-badge>
            </h6>
          </template>
          <template #cell(endpoint)="data">
            <strong>
              <code>
                {{ exportEndpoint(data.item.endpoint).url }}
              </code>
            </strong>
          </template>
          <template #cell(submitted)="data">
            <h6>
              <b-badge
                variant="secondary"
                v-b-tooltip.hover.bottom="{ variant: 'secondary' }"
                :title="data.item.uid"
                >{{
                  formatDate(data.item.submitted, 'HH:mm:ss dd/MM/yyyy')
                }}</b-badge
              >
            </h6>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col class="text-left">
                  <pre>{{ row.item.data | prettyJSON }}</pre>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="pager.totalRows"
          :per-page="pager.perPage"
          @change="GetList"
          aria-controls="activityTable"
          size="sm"
          align="center"
        ></b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import UAParser from 'ua-parser-js'
import { format as dateFormat } from 'date-fns'
// parser = new UAParser("user-agent")

export default {
  name: 'ActivitiesList',
  props: ['listItems', 'pager', 'loading'],
  data() {
    return {
      listFields: [
        {
          key: 'submitted',
          label: 'Date'
        },
        {
          key: 'email',
          label: 'User'
        },
        {
          key: 'agent',
          label: 'Agent'
        },
        {
          key: 'method',
          label: '',
          tdClass: 'text-center'
        },
        {
          key: 'endpoint',
          label: 'Endpoint',
          tdClass: 'text-left'
        },
        {
          key: 'data',
          label: '',
          tdClass: 'text-left'
        }
      ]
    }
  },
  async mounted() {
    try {
      this.$root.$on('reset-search-user', () => {
        this.q = null
      })
    } catch (Excpetion) {
      console.log('Exception', Excpetion)
    }
  },
  methods: {
    GetList(page) {
      this.$root.$emit('refresh-activities-list', page)
    },
    search(keyword) {
      this.$root.$emit('setActivitiesSearchQuery', keyword)
    },
    summarize(note, chars = 40) {
      if (!note) {
        return ''
      }
      note = note.trim()
      if (note.length > chars) {
        return note.substring(0, chars) + '...'
      } else {
        return note
      }
    },
    getEmailInitials(email) {
      if (!email) {
        return '-'
      }
      const [id] = email.split('@')
      return id
    },
    parseUA(ua) {
      const parser = new UAParser(ua)
      const result = parser.getResult()
      return result
    },
    exportEndpoint(endpoint) {
      if (!endpoint) {
        return '-'
      }
      const [method, url] = endpoint.split(':')
      return {
        method: method.toUpperCase(),
        url: url.replace(/^\/api\/v2/, '')
      }
    },
    formatDate(date, format) {
      if (!date) {
        return '-'
      }
      return dateFormat(new Date(date), format)
    },
    getMethodVariant(method) {
      switch (method) {
        case 'GET':
          return 'info'
        case 'POST':
          return 'success'
        case 'PUT':
        case 'PATCH':
          return 'warning'
        case 'DELETE':
          return 'danger'
        default:
          return 'dark'
      }
    }
  },
  watch: {
    q: {
      handler(q) {
        this.$root.$emit('search-user', q)
      },
      deep: true
    }
  },
  filters: {
    prettyJSON(value) {
      return JSON.stringify(value, null, 2)
    },
    prettyNumber(value) {
      return value.toLocaleString()
    }
  }
}
</script>

<style>
.lizard-user-active-btn {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-user-active-btn:hover {
  cursor: pointer;
  color: #eee !important;
}
.lizard-user-active-btn:active {
  color: #ccc !important;
}
.lizard-user-active-btn-disabled {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
  cursor: 'not-allowed';
  opacity: 0.5;
}
.lizard-employeeRole {
  font-size: 9pt !important;
  font-weight: bold;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-active {
  width: 50px !important;
  text-align: center !important;
}
.lizard-column-status {
  width: 80px !important;
  text-align: center !important;
}
.lizard-column-actions {
  text-align: center !important;
}
.lizard-column-actions .manage-rates-btn {
  margin: 0;
  font-size: 8pt !important;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.lizard-header-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell:hover {
  background-color: #eee;
}

.allowence-table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #999 !important;
  text-align: center;
}
.allowence-data-row {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}
.allowence-data-row .allowence-input {
  border: 0;
  background: transparent;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.allowence-data-row:hover {
  background-color: rgba(102, 204, 255, 0.1);
}
</style>

<style scoped>
span.user-assignments {
  margin: 0;
  padding: 0;
}
span.user-assignments div h6 {
  font-size: 1.1em;
  vertical-align: middle;
}
span.user-assignments div h6 > .badge {
  vertical-align: middle;
  margin-top: 0.7em;
  text-shadow: none !important;
}
.stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #93c 10px,
      #93c 20px
    ),
    linear-gradient(to bottom, #c39, #93c);
}
.wfa-stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #400d01 10px,
      #400d01 20px
    ),
    linear-gradient(to bottom, #592202, #400d01);
}
</style>
