<script>
exports.ActivitiesService = (api) => {
  const basePath = '/activities'
  const adminPath = `/admin${basePath}`

  const handleError = (error) => {
    if (typeof error === 'string') {
      throw new Error(error)
    } else {
      throw error
    }
  }

  const getList = async (query = '', page = 1, perPage = 10) => {
    try {
      return await api.get(
        `${adminPath}/list?q=${query}&page=${page}&limit=${perPage}`
      )
    } catch (error) {
      handleError('Failed to fetch activities list for admin')
    }
  }

  return {
    Admin: {
      getList
    }
  }
}
</script>
