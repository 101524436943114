<template>
  <b-container
    fluid
    v-if="weeks.defaultDates.length && users.length"
    :class="{ 'lizard-no-padding': mobileView }"
    style="padding-right: 0 !important"
  >
    <div class="row calendar-header">
      <div class="col-lg team" :class="{ mobile: mobileView }">Name</div>
      <div
        class="col-lg day-holder"
        :class="{
          today: isToday(date),
          weekend: isWeekend(date) || isHoliday(date),
          mobile: mobileView
        }"
        v-for="(date, index) in weeks.defaultDates"
        :key="index"
        v-b-popover.hover.bottom="
          isToday(date) ? 'Today' : formatHeaderDate(date, 'EEEE, LLLL do yyyy')
        "
      >
        {{ formatHeaderDate(date) }}
      </div>
    </div>
    <div
      class="row calendar-body"
      :class="{ bgOdd: index % 2 !== 0 }"
      v-for="(date, index) in weeks.dates"
      :key="date.user.uid + '_' + index"
    >
      <div
        class="col-lg team"
        :class="{ mobile: mobileView }"
        :style="getSelectedStyle(date.user.color)"
      >
        <div class="row">
          <div class="col-sm" style="margin: 10px">
            {{ date.user.name }}
          </div>
        </div>
      </div>
      <div
        class="col-lg block"
        :class="{
          weekend: isWeekend(block.date) || isHoliday(block.date),
          mobile: mobileView
        }"
        v-for="(block, index) in date.blocks"
        :key="block.key + '_' + index"
      >
        <span v-if="isWeekend(block.date) || isHoliday(block.date)"></span>
        <span v-else>
          <div
            v-for="(project, index) in block.items"
            :class="{
              project:
                !project.isHoliday ||
                !isWeekend(block.date) ||
                isHoliday(block.date),
              'block-text-shadow':
                !isWeekend(block.date) &&
                !isHoliday(block.date) &&
                !isWFA(block),
              'stripe-bg':
                !isHoliday(block.date) &&
                !isWeekend(block.date) &&
                !isWFA(block) &&
                project.isHoliday,
              'wfa-stripe-bg':
                !isHoliday(block.date) &&
                !isWeekend(block.date) &&
                isWFA(block) &&
                project.isHoliday,
              'full-block': block.items.length == 1
            }"
            :style="setProjectColorAsBg(project.color)"
            :key="block.key + '_' + project.aid + '_' + index"
          >
            <span v-if="!isWeekend(block.date) && !isHoliday(block.date)">
              <h6 v-if="project.isHoliday">
                <b-badge variant="warning" style="text-transform: uppercase">{{
                  project.name
                }}</b-badge>
              </h6>
              <span v-else>{{ project.name }}</span>
            </span>
          </div>
        </span>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'
import { startOfWeek, addDays, format, isToday, isWeekend } from 'date-fns'

export default {
  name: 'StaffingCalendar',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    },
    showWeekends: {
      type: Boolean,
      default: false
    },
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    Store() {
      return this.$store.state.components.staffinglogs
    },
    calendarWeeksStartDate() {
      return this.Store.calendarWeeksStartDate
    },
    projectToDrag() {
      return this.Store.projectToDrag
    },
    totalDaysToLoad() {
      return this.mobileView ? 7 : 14
    }
  },
  data() {
    return {
      date: new Date(),
      weeks: {
        defaultDates: [],
        dates: [],
        bankHolidays: []
      }
    }
  },
  mounted() {
    this.$root.$on('staffing-assignments-updated', this.syncBlocks)
    this.$root.$on('staffinglogs-assignments-fetched', (data) => {
      this.weeks.dates = this.getBlocks()
      if (this.weeks.dates.length) {
        this.importAssignmentsToBlocks(data)
      }
    })
    this.$root.$on('week-changed-on-staffing-logs', async () => {
      this.weeks.defaultDates = await this.setDefaultDates()
    })
  },
  methods: {
    async setDefaultDates() {
      // GET BANK HOLIDAYS
      const { status, data } = await this.getBankHolidays()
      if (status === 200) {
        this.weeks.bankHolidays = data?.content ?? []
      }
      return new Array(this.totalDaysToLoad)
        .fill(0)
        .map((_, i) =>
          format(addDays(this.calendarWeeksStartDate, i), 'yyyy-MM-dd')
        )
        .filter((_, i) => this.showWeekends || i % 7 < 5)
    },
    getBlocks() {
      this.weeks.dates = []
      const start =
        this.calendarWeeksStartDate ||
        startOfWeek(this.date, { weekStartsOn: 1 })
      this.$store.commit('setStaffingLogsCalendarWeeksStartDate', start)
      this.weeks.dates = new Array(this.totalDaysToLoad)
        .fill(0)
        .map((_, i) => format(addDays(start, i), 'yyyy-MM-dd'))
        .filter((_, i) => this.showWeekends || i % 7 < 5)

      let Blocks = {}

      this.users.forEach((user) => {
        Blocks[user.uid] = Blocks[user.uid] || {
          user,
          blocks: []
        }
        this.weeks.dates.forEach((date) => {
          const blockIndexToPush = Blocks[user.uid].blocks.findIndex(
            (block) => block.date === date
          )
          if (blockIndexToPush === -1) {
            Blocks[user.uid].blocks.push({
              key: `${user.uid}-${date}`,
              date,
              items: []
            })
          }
        })
      })
      return Object.values(Blocks)
    },
    importAssignmentsToBlocks(data = []) {
      // Group data by users
      data = data
        .filter((itm) => {
          return (
            this.weeks.dates.findIndex(
              (date) => date.user.uid == itm.owner.uid
            ) != -1
          )
        })
        .reduce((acc, item) => {
          acc[item.owner.uid] = acc[item.owner.uid] || []
          acc[item.owner.uid].push(item)
          return acc
        }, {})

      if (!Object.keys(data).length) {
        return
      }
      // Push assignments to blocks
      for (const userUid in data) {
        const blockIndex = this.weeks.dates.findIndex(
          (date) => date.user.uid == userUid
        )
        const groupedByDates = data[userUid].reduce((acc, item) => {
          acc[item.assignmentDate] = acc[item.assignmentDate] || []
          acc[item.assignmentDate].push(item)
          return acc
        }, {})
        const dataBlocks = this.weeks.dates[blockIndex].blocks
        for (const date in groupedByDates) {
          const dateIndex = dataBlocks.findIndex((block) => block.date == date)
          const dateBlock = this.weeks.dates[blockIndex].blocks[dateIndex]
          if (typeof dateBlock === 'undefined') {
            continue
          }
          // console.log({ dateBlock, blockIndex, dateIndex })
          dateBlock.items = []
          groupedByDates[date].forEach((item) => {
            if (item.type === 'project' && item.assignment) {
              dateBlock.items.push({
                uid: item?.uid ?? null,
                aid: item?.assignment?.uid ?? null,
                name: item?.assignment?.title ?? null,
                color: item?.assignment?.meta?.color ?? '#000',
                created: item?.created ?? null,
                isHoliday: false
              })
            }
            if (item.type === 'holiday') {
              const name =
                item.meta.holidayType.charAt(0).toUpperCase() +
                item.meta.holidayType.slice(1).toLowerCase()
              let HolidayName =
                /^HALF.+$|^WFH.+|^ANNUAL.+|^DAY\-IN\-LIEU$|^MANDATORY$/g.test(
                  item.meta.holidayType
                )
                  ? name
                  : 'Personal'
              if (name.toLowerCase() == 'half-day' && item.meta.meridiem) {
                HolidayName += ' (' + item.meta.meridiem + ')'
              }
              if (/^Wfh.+/i.test(HolidayName)) {
                HolidayName = 'WFA'
              }
              if (/^annual.+/i.test(HolidayName)) {
                HolidayName = 'Holiday'
              }
              if (/^mandatory$/i.test(HolidayName)) {
                HolidayName = 'Holiday'
              }
              if (/^DAY\-IN\-LIEU$/i.test(HolidayName)) {
                HolidayName = 'DAY-IN-LIEU'
              }
              const ItemToStore = {
                uid: item.uid,
                aid: item.meta.holidayType.toUpperCase(),
                name: HolidayName,
                color:
                  item.meta.holidayType.toUpperCase() == 'ANNUAL'
                    ? '#C39'
                    : '#93C',
                created: item?.created ?? null,
                isHoliday: true,
                meridiem: item?.meta?.meridiem ?? null
              }
              const HolidaysOnLessPriority = [
                'WFA',
                'Half-day (AM)',
                'Half-day (PM)',
                'Half personal'
              ]
              if (HolidaysOnLessPriority.includes(ItemToStore.name)) {
                if (ItemToStore.name === HolidaysOnLessPriority[1]) {
                  dateBlock.items.unshift(ItemToStore)
                } else {
                  dateBlock.items.push(ItemToStore)
                }
              } else {
                dateBlock.items = [ItemToStore]
              }
            }
          })
        }
      }
    },
    syncBlocks() {
      setTimeout(() => {
        this.$root.$emit('get-staffing-logs-assignments')
        this.$root.$emit('week-changed-on-staffing-logs')
      }, 100)
    },
    isValidProjectToDrop(date) {
      let isValid = true
      isValid = isValid && !this.isWeekend(date)
      isValid = isValid && this.projectToDrag
      return isValid
    },
    getBlockIndexs(userUid, date) {
      const indexs = {
        date: -1,
        block: -1
      }
      indexs.date = this.weeks.dates.findIndex(
        (dateBlock) => dateBlock.user.uid === userUid
      )
      indexs.block =
        this.weeks.dates[indexs.date]?.blocks.findIndex(
          (block) => block.date === date
        ) ?? -1
      return indexs
    },
    isToday(date) {
      return isToday(new Date(date))
    },
    isWeekend(date) {
      return isWeekend(new Date(date))
    },
    isHoliday(date) {
      return this.weeks.bankHolidays.findIndex((itm) => itm.date == date) != -1
    },
    isWFA(block) {
      return block.items.findIndex((itm) => itm.name == 'WFA') != -1
    },
    formatHeaderDate(date) {
      const dt = new Date(date)
      return this.mobileView ? format(dt, 'eeeeee d') : format(dt, 'EEE do')
    },
    getSelectedStyle(color) {
      return {
        'background-color': color,
        color: '#fff'
      }
    },
    setProjectColorAsBg(color) {
      return {
        'background-color': color
      }
    },
    async getBankHolidays() {
      return await ServicesOld.GetBankHolidays()
    }
  }
}
</script>

<style scoped>
.calendar-header {
  background-color: rgb(0, 123, 255);
  padding: 0 !important;
  font-weight: bold;
  font-size: 10pt;
  color: #fff;
  height: 40px;
  line-height: 40px;
  max-width: 100% !important;
}
.calendar-header .team {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
  width: calc(100% / 15) !important;
}
.calendar-header .team.mobile {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
  text-align: center;
  width: calc(100% / 6) !important;
}
.calendar-header .loading {
  background-color: rgb(177, 50, 50) !important;
  color: #fff;
}
.calendar-header .day-holder {
  text-align: center;
  padding: 0 !important;
  width: calc(100% / 15) !important;
}
.calendar-header .day-holder.mobile {
  text-align: center;
  padding: 0 !important;
  width: calc(100% / 6) !important;
}
.calendar-header .day-holder.today {
  background-color: rgb(0, 51, 102) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend {
  background-color: rgb(177, 177, 177) !important;
  padding: 0 !important;
}
.calendar-header .day-holder.weekend.today {
  color: rgb(0, 123, 255) !important;
}
.calendar-body {
  background-color: #eee;
  min-height: 60px;
  max-height: 60px;
  max-width: 100% !important;
  padding: 0 !important;
}
.calendar-body.bgOdd {
  background-color: #f5f5f5 !important;
  padding: 0 !important;
}
.calendar-body .team {
  color: #fff;
  background-color: #333;
  font-size: 10pt;
  font-weight: bold;
  padding: 0 !important;
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center;
  height: 60px !important;
  width: calc(100% / 15) !important;
  border-top: 1px solid #eee !important;
  text-shadow: #666 0 0 3px;
}
.calendar-body .team.mobile {
  color: #fff;
  background-color: #333;
  font-size: 10pt;
  font-weight: bold;
  padding: 0 !important;
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center;
  height: 60px !important;
  width: calc(100% / 6) !important;
  border-top: 1px solid #eee !important;
  text-shadow: #666 0 0 3px;
  overflow-x: auto !important;
}
.calendar-body .block {
  display: block;
  text-align: center;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 0 !important;
  height: 60px !important;
  width: calc(100% / 15) !important;
}
.calendar-body .block.mobile {
  display: block;
  text-align: center;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 0 !important;
  height: 60px !important;
  width: calc(100% / 6) !important;
}
.calendar-body .block.weekend {
  background-color: rgb(177, 177, 177) !important;
}
::-webkit-scrollbar {
  width: 0 !important;
}
.calendar-body .block .project {
  border-radius: 0;
  margin: 0.5% auto;
  padding: 1%;
  font-size: 10pt;
  color: #fff;
  font-weight: bold;
  overflow-wrap: break-word;
  overflow-y: auto;
  max-width: 100% !important;
  min-height: 50% !important;
  max-height: 50% !important;
  height: 50% !important;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}
.calendar-body .block .project.full-block {
  min-height: 99% !important;
  max-height: 99% !important;
  height: 99% !important;
}
.block-text-shadow {
  text-shadow: #666 0 1px 2px !important;
}
.stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #93c 10px,
      #93c 20px
    ),
    linear-gradient(to bottom, #c39, #93c);
}
.wfa-stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #400d01 10px,
      #400d01 20px
    ),
    linear-gradient(to bottom, #592202, #400d01);
}
</style>
