<template>
  <b-container
    fluid
    class="bg-white"
    :class="{ 'lizard-no-padding': mobileView }">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-sm-1"></div>
      <div class="col-sm-6">&nbsp;</div>
      <div
        class="col-sm-4"
        :class="{ 'text-right': !mobileView, 'text-center': mobileView }">
        <b-button
          variant="outline-info"
          class="lizard-view-link"
          size="sm"
          @click="toggleNewActivityModal()">
          Add New Expense
        </b-button>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      v-if="!this.mobileView">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      v-if="!listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      v-if="listItems.length">
      <div class="col-sm-1"></div>
      <div class="col-sm-10">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          v-if="viewType === 'list'">
          <template #table-caption>Expenses</template>
          <template #cell(projectCode)="data">
            {{ data.item.project.title }}
          </template>
          <template #cell(created)="data">
            {{ formatDate(data.item.created) }}
          </template>
          <template #cell(expenseDate)="data">
            {{ formatDate(data.item.expenseDate) }}
          </template>
          <template #cell(amount)="data">
            {{ calcAmount(data.item.fee, 'amount') }}
          </template>
          <template #cell(vat)="data">
            {{ calcAmount(data.item.fee, 'vat') }}
          </template>
          <template #cell(category)="data">
            {{ categoryLabel(data.item.category) }}
          </template>
          <template #cell(refCode)="data">
            <b-badge
              variant="info"
              v-if="data.item.refCode"
              style="cursor: pointer"
              title="Click to copy"
              @click="copyRefCode(data.item)">
              {{ data.item.refCode }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge
              class="lizard-expense-status"
              :variant="GetExpenseStatusVariant(data.item.status)">
              {{ data.item.status }}
            </b-badge>
          </template>
          <template #cell(notes)="data"
            ><span v-b-popover.hover.top="data.item.notes">{{
              summarize(data.item.notes)
            }}</span></template
          >
          <template #cell(attachments)="data">
            <ul class="lizard-attachment-list">
              <li v-if="data.item.attachments">
                <b-link @click="downloadReceipt(data.item.attachments)"
                  ><!-- <b-icon
                    icon="file-earmark-arrow-down"
                    variant="info"></b-icon> -->
                  Download</b-link
                >
              </li>
              <!-- <li
                v-if="data.item.attachments && data.item.status === 'pending'">
                <b-link
                  class="lizard-remove-link"
                  @click="removeBill(data.item)"
                  >replace</b-link
                >
              </li> -->
              <!-- <li v-if="!data.item.attachments">
                <b-form-file
                  drop-placeholder="Drop file here..."
                  size="sm"
                  ref="file"
                  :itmId="data.item.uid"
                  @change="uploadBill"
                  :disabled="uploading"></b-form-file>
              </li> -->
            </ul>
          </template>
          <template #cell(action)="data">
            <b-link
              @click="doRemove(data.item.uid)"
              v-if="data.item.status === 'pending'"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"></b-icon
            ></b-link>
          </template>
        </b-table>
        <b-calendar
          v-if="viewType === 'calendar'"
          v-model="calDate"
          @context="onContext"
          :date-info-fn="dateClass"
          block
          hide-header
          locale="en-US"
          variant="secondary"></b-calendar>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row" :class="{ 'lizard-no-margin': mobileView }">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-overlay no-wrap :show="deleteInProgress">
      <template #overlay>
        <div
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3">
          <p><strong id="form-confirm-label">Are you sure?</strong></p>
          <div class="d-flex">
            <b-button variant="outline-danger" class="mr-3" @click="onCancel">
              Cancel
            </b-button>
            <b-button variant="outline-info" @click="onConfirm"
              >Confirm</b-button
            >
          </div>
        </div>
      </template>
    </b-overlay>
    <b-modal
      size="xl"
      v-model="newActivityModal.show"
      centered
      :title="newActivityModal.data.title"
      :header-bg-variant="newActivityModal.headerBgVariant"
      :header-text-variant="newActivityModal.headerTextVariant"
      :body-bg-variant="newActivityModal.bodyBgVariant"
      :body-text-variant="newActivityModal.bodyTextVariant"
      :footer-bg-variant="newActivityModal.footerBgVariant"
      :footer-text-variant="newActivityModal.footerTextVariant">
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <ActivityEntry :activityType="newActivityModal.activityType" />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="toggleNewActivityModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import ActivityEntry from '../ActivityEntry.vue'
// import FormComponent from './Expenses/Form.vue'
import { format as formatDate } from 'date-fns'

export default {
  name: 'ExpenseLogs',
  props: {
    mobileView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ActivityEntry
  },
  computed: {
    listFields() {
      const flds = [
        {
          key: 'created',
          label: 'Submitted Date',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'fee.type',
          label: 'Category',
          sortable: true,
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'projectCode',
          label: 'Project',
          sortable: true,
          thClass: 'w40 aleft',
          tdClass: 'w40 aleft',
          _showInMobileView: false
        },
        {
          key: 'expenseDate',
          label: this.mobileView ? 'Date' : 'Expense Date',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'amount',
          label: 'Amount',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'vat',
          label: 'VAT',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'manager',
          label: 'Manager',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'attachments',
          label: 'Receipt',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'notes',
          label: 'Notes',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        // {
        //   key: 'refCode',
        //   label: 'Reference Code',
        //   thClass: 'w10',
        //   tdClass: 'w10',
        //   _showInMobileView: false
        // },
        {
          key: 'action',
          label: '',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        }
      ]
      return flds.filter((fld) => {
        return this.mobileView ? fld._showInMobileView : true
      })
    }
  },
  data() {
    return {
      viewType: 'list',
      deleteInProgress: false,
      deleteItemId: null,
      uploading: false,
      listItems: [],
      calendarItems: {},
      calDate: '',
      context: null,
      categories: {},
      gbpRates: { eur: 0, usd: 0 },
      newActivityModal: {
        show: false,
        activityType: 'exp',
        headerBgVariant: 'primary',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          selectedDate: null,
          title: 'New Expense (Please remember to click Submit button)',
          listFields: [
            {
              key: 'name',
              label: 'Name',
              sortable: true
            },
            {
              key: 'hours',
              label: 'Hours',
              sortable: true,
              thClass: 'lizard-column-hours',
              tdClass: 'lizard-column-hours'
            }
          ],
          listItems: [],
          perPage: 10,
          currentPage: 1
        }
      }
    }
  },
  mounted() {
    this.$root.$on('parent_tab_expenses_selected', this.tabMounted)
    this.$root.$on('saved', this.tabMounted)
    this.tabMounted()
  },
  methods: {
    async tabMounted() {
      this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
      await this.GetCategories()
      const now = new Date()
      this.calDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      this.listItems = await this.GetList()
    },
    async toggleNewActivityModal() {
      try {
        // check connection
        await Services.Users.fetchCurrentUser()
        this.newActivityModal.show = !this.newActivityModal.show
      } catch (Exception) {
        this.$root.$emit('user-signed-out')
      }
    },
    async GetList() {
      try {
        let { status, data } = await ServicesOld.GetExpenses()
        if (status === 200) {
          return this.structurizeDataSchema(data.content)
        } else {
          return []
        }
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    async GetCategories() {
      const { status, data } = await ServicesOld.GetExpenseCategories()
      if (status === 200) {
        data.content.forEach(({ value, name }) => {
          this.categories[value] = name
        })
      }
    },
    structurizeDataSchema(data = []) {
      data = data
        .map((itm) => {
          itm.project = itm.project || { title: 'CANDESIC' }
          this.calendarItems[itm.date] = itm.amount
          itm.fee.type = itm.fee.type.replace(/_/g, ' ').toUpperCase()
          itm.manager = `${itm?.manager?.firstName ?? ''} ${
            itm?.manager?.lastName ?? ''
          }`.trim()
          itm.action = {
            id: itm.uid
          }
          return itm
        })
        .sort((a, b) => {
          if (a.created > b.created) {
            return -1
          } else if (a.created < b.created) {
            return 1
          } else {
            return 0
          }
        })
      return data
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount(itm, amountKey) {
      let toGBP = ''
      return (
        this.currencySymbol(itm.currency) +
        parseFloat(itm[amountKey]).toFixed(2) +
        toGBP
      )
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      ServicesOld.DownloadExpenseBill(fileName)
    },
    async removeBill(itm) {
      itm.attachments = null
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await ServicesOld.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        const itmId = ev.target.attributes['itmId'].value
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.uid) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachments = data.fileName
        await ServicesOld.UpdateExpense(itmId, {
          attachmentKeys: itm.attachments
        })
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    async doRemove(id) {
      this.deleteInProgress = true
      this.deleteItemId = id
    },
    onCancel() {
      this.deleteInProgress = false
      this.deleteItemId = null
    },
    async onConfirm() {
      this.deleteInProgress = false
      if (!this.deleteItemId) {
        return
      }
      let { status } = await ServicesOld.RemoveExpense(this.deleteItemId)
      if (status === 200) {
        this.listItems = this.listItems.filter((itm) => {
          return itm.uid !== this.deleteItemId
        })
      }
      this.deleteItemId = null
      this.deleteInProgress = false
    },
    onContext(ctx) {
      this.context = ctx
    },
    toggleViewType() {
      this.viewType = this.viewType === 'list' ? 'calendar' : 'list'
    },
    dateClass(ymd, date) {
      let className = ''
      const dt = this.$moment(date).format('YYYY-MM-DD')
      if (this.calendarItems[dt]) {
        className = 'half-activity-calday'
      }
      return this.$moment().diff(dt) > 0 ? className : ''
    },
    GetExpenseStatusVariant(status) {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'info'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'paid':
          variant = 'success'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    formatDate(dt) {
      return formatDate(new Date(dt), 'dd/MM/yyyy')
    },
    summarize(note) {
      if (!note) {
        return ''
      }
      note = note.trim()
      if (note.length > 20) {
        return note.substring(0, 20) + '...'
      } else {
        return note
      }
    },
    copyRefCode({ refCode = null }) {
      if (refCode === null) {
        return
      }
      navigator.clipboard.writeText(refCode).then(
        () => {
          this.$toasted.show('Reference code copied to clipboard', {
            type: 'info',
            duration: 3000
          })
        },
        function (err) {
          console.error('Could not copy text: ', err)
        }
      )
    }
  }
}
</script>

<style>
.half-activity-calday {
  background-color: #ccc !important;
  color: #333 !important;
}
.lizard-text-center {
  text-align: center !important;
}
.lizard-text-center.white {
  color: #fff !important;
}
.lizard-col-w5 {
  width: 5% !important;
}
.lizard-col-w10 {
  width: 10% !important;
}
.lizard-col-w15 {
  width: 15% !important;
}
.lizard-col-w20 {
  width: 20% !important;
}
.lizard-col-w25 {
  width: 25% !important;
}
.lizard-col-w30 {
  width: 30% !important;
}
.lizard-activity-list-action a {
  color: #f00;
}
.lizard-activity-list-action a:hover {
  color: #c00;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-view-link {
  font-weight: bold;
}
.lizard-today-btn {
  margin: 10px;
  float: right;
}
.lizard-attachment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.lizard-attachment-list li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-remove-link {
  color: #c00;
  font-variant: small-caps;
  font-size: 10pt;
  font-weight: bold;
}
.lizard-remove-link:hover {
  color: #600;
  text-decoration: underline;
}
.lizard-expense-status {
  font-variant: small-caps;
  font-weight: bolder;
  font-size: 10pt !important;
  letter-spacing: 0.03em;
  padding: 0 8px 4px 8px;
  margin: 0 auto;
  cursor: default;
}
.lizard-expenses-box {
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid #eee;
  border-radius: 3px;
  table-layout: auto !important;
  margin-top: 0 !important;
}
.lizard-expenses-box .label {
  color: #fff;
  text-align: center;
  padding: 4px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 30px;
  background-color: #66ccff;
}
.lizard-expenses-box .value {
  color: #666;
  text-align: center;
  padding: 4px;
  font-size: 10pt;
  height: 40px;
  line-height: 30px;
}
.lizard-expenses-box .w10 {
  width: auto !important;
}
.lizard-expenses-box .w40 {
  width: 10% !important;
}
.lizard-expenses-box .aleft {
  text-align: left;
}
</style>
