import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './routes/Home.vue'
import MobileView from './routes/MobileHome.vue'
// import Admin from './routes/Admin.vue'
// import HangFire from './routes/HangFire.vue'
import NotFound from './routes/NotFound.vue'

Vue.use(VueRouter)

const beforeEnter = function (to, from, next) {
  const { uri } = to.query
  if (uri != null && uri != '/') {
    next(false)
    Router.push(uri)
  } else {
    next()
  }
}

// const checkUserAgent = function (to, from, next) {
//   const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
//   console.log({ isMobile, to })
//   next()
// }

// const checkAdmin = function (to, from, next) {
//   console.log('isAdmin', { to })
//   next()
// }

const routes = [
  { path: '/', name: 'home', component: Home, beforeEnter },
  { path: '/mobile', name: 'mobileView', component: MobileView, beforeEnter },
  // { path: '/admin', name: 'admin', component: Admin, beforeEnter: checkAdmin },
  // { path: '/hangfire', name: 'hangFire', component: HangFire, beforeEnter },
  { path: '*', name: 'NotFound', component: NotFound, beforeEnter }
]

const Router = new VueRouter({
  mode: 'history',
  routes
})

export default Router
