<template>
  <b-container fluid>
    <div class="row" v-if="list.length">
      <div class="col-lg-10">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :busy="loading"
          :items="list"
          :fields="fields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption
            >Projects Statistics (Excluding Time Entries for Interns)</template
          >
          <template #cell(title)="data">
            <div
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to include in filters"
              @click="fnAddToFilters(data.item, 'project')"
              class="selected-project"
              style="margin-bottom: 5px"
              :style="'background-color: ' + data.item.color + ' !important'"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(totalCost)="data">
            <span v-if="showSensitiveData"
              >£{{ numberWithCommas(data.item.totalCost) || 0 }}</span
            >
            <span v-else>
              <h6>
                <b-badge variant="light" style="color: #999">***</b-badge>
              </h6>
            </span>
          </template>
        </b-table>
      </div>
      <div class="col-lg-2"></div>
    </div>
    <div class="row" v-if="list.length">
      <div class="col-lg-5 text-left">
        <b-pagination
          v-if="list.length > pager.perPage"
          v-model="pager.currentPage"
          :total-rows="list.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
      <div class="col-lg-5 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportExcelOpts"
          :type="exportExcel.stats.projects.type"
          :name="exportExcel.stats.projects.name"
          :fields="exportExcel.stats.projects.fields"
          :data="exportExcel.stats.projects.data"
          :worksheet="exportExcel.stats.projects.worksheet"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'TimelogProjectsStatsComponent',
  props: {
    fnAddToFilters: {
      type: Function,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    showSensitiveData: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'Project',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'totalHours',
          label: 'Total Hours',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'totalCost',
          label: 'Total Cost',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        }
      ],
      pager: {
        currentPage: 1,
        perPage: 10
      },
      exportExcel: {
        stats: {
          projects: {
            type: 'xls',
            name: 'projects-stats-reports.xls',
            worksheet: "Candesic's Timelog Enteries Reports | Projects Stats",
            fields: {
              'Project Title': 'title',
              'Total Hours': 'totalHours',
              'Total Cost': 'totalCost'
            },
            data: []
          }
        }
      }
    }
  },
  methods: {
    numberWithCommas(x) {
      const parsed = parseFloat(x || 0).toFixed(2)
      return parseFloat(parsed).toLocaleString()
    },
    generateExcel() {
      this.exportExcel.stats.projects.data = this.list
    },
    resetExportExcelOpts() {
      this.exportExcel.stats.projects.data = []
    }
  }
}
</script>
