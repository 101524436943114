<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg"></div>
      <div class="col-lg-11">
        <b-card bg-variant="light" style="max-height: 75px">
          <div class="row">
            <div class="col-lg-4 text-left">
              <el-date-picker
                id="datesFilterInput"
                style="max-width: 98% !important"
                type="daterange"
                range-separator=""
                start-placeholder="From"
                end-placeholder="To"
                class="form-control lizard-form-datepicker"
                size="small"
                format="dd/MM/yyyy"
                value-format="dd/MM/yyyy"
                v-model="reportsFilters.filters.dates"
                :picker-options="datePickerOptions"
                @change="datesChanged"
              ></el-date-picker>
            </div>
            <div class="col-lg-3 text-left">
              <b-form-input
                id="teamMembersFilterInput"
                size="sm"
                placeholder="Type and select user..."
                :value="reportsFilters.info.userFullName"
                @keyup="checkUsersList"
                @change="userChanged"
                @keyup.enter="
                  addToFilters(
                    usersTempList.length ? usersTempList[0] : null,
                    'user',
                    false
                  )
                "
              ></b-form-input>
            </div>
            <div class="col-lg-3 text-left">
              <b-form-input
                id="projectsFilterInput"
                size="sm"
                placeholder="Type and select project..."
                :value="reportsFilters.info.projectTitle"
                @keyup="checkProjectsList"
                @change="projectChanged"
                @keyup.enter="
                  addToFilters(
                    projectsTempList.length ? projectsTempList[0] : null,
                    'project',
                    false
                  )
                "
              ></b-form-input>
            </div>
            <div class="col-lg-2 text-center">
              <b-button
                v-if="!loading"
                variant="info"
                size="sm"
                @click="GetReports"
                style="
                  font-size: 10pt;
                  font-weight: bold;
                  min-width: 90px;
                  padding: 4px 8px;
                "
                >Get Reports</b-button
              >
              <b-button
                v-else
                variant="secondary"
                size="sm"
                style="
                  font-size: 10pt;
                  font-weight: bold;
                  min-width: 90px;
                  padding: 4px 8px;
                "
                disabled
                >Loading...</b-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 text-left"></div>
            <div class="col-lg-3 text-left">
              <ul class="lizard-temp-list">
                <li
                  v-for="user in usersTempList"
                  :key="user.uid"
                  @click="addToFilters(user, 'user', false)"
                >
                  <b-badge variant="secondary">{{ user.fullName }}</b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 text-left">
              <ul class="lizard-main-list">
                <li
                  v-for="(project, index) in reportsFilters.info.projectTitles"
                  :key="index"
                  @click="
                    removeFromFilters(
                      {
                        uid: reportsFilters.filters.projects[index],
                        title: project
                      },
                      'project',
                      true
                    )
                  "
                >
                  <b-badge variant="info">{{ project }}</b-badge>
                </li>
              </ul>
              <ul class="lizard-temp-list">
                <li
                  v-for="project in projectsTempList"
                  :key="project.uid"
                  @click="addToFilters(project, 'project', false)"
                >
                  <b-badge variant="secondary">{{ project.title }}</b-badge>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 text-center"></div>
          </div>
        </b-card>
      </div>
      <div class="col-lg"></div>
    </div>
    <div class="row" v-if="showStats">
      <div class="col-lg-12">
        <timelog-stats-component
          :loading="loading"
          :showSensitiveData="showSensitiveData"
          :list="timeLogStatsItems"
          :ctreport="CTReportItems"
        />
      </div>
    </div>
    <div class="row" v-if="showStats">
      <div class="col-lg-6">
        <timelog-team-stats-component
          :loading="loading"
          :fnAddToFilters="addToFilters"
          :showSensitiveData="showSensitiveData"
          :list="teamStatsItems"
        />
      </div>
      <div class="col-lg-6">
        <timelog-projects-stats-component
          :loading="loading"
          :fnAddToFilters="addToFilters"
          :showSensitiveData="showSensitiveData"
          :list="projectsStatsItems"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <timelog-reports-component
          :loading="loading"
          :fnAddToFilters="addToFilters"
          :showSensitiveData="showSensitiveData"
          :list="timeLogReportsItems"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import { format as dateFormat, isWeekend, parseISO } from 'date-fns'
import TimelogStatsComponent from './stats.vue'
import TimelogTeamStatsComponent from './teamStats.vue'
import TimelogProjectsStatsComponent from './projectsStats.vue'
import TimelogReportsComponent from './timelogReports.vue'

export default {
  name: 'ReportsComponent',
  components: {
    TimelogStatsComponent,
    TimelogTeamStatsComponent,
    TimelogProjectsStatsComponent,
    TimelogReportsComponent
  },
  data() {
    return {
      showStatsControllers: false,
      showStats: true,
      showSensitiveData: true,
      teamMembers: [],
      projectsList: [],
      bankHolidays: [],
      timeLogStatsItems: [],
      teamStatsItems: [],
      projectsStatsItems: [],
      timeLogReportsItems: [],
      CTReportItems: [],
      loading: false,
      usersTempList: [],
      projectsTempList: []
    }
  },
  mounted() {
    this.$root.$on('tab_reports_timesheet_selected', this.tabMounted)
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    reportsFilters() {
      return this.Store.reportsFilters
    },
    datePickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate: (dt) => {
          const isBankHoliday = this.bankHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          return isWeekend(new Date(dt)) || isBankHoliday
        }
      }
    }
  },
  methods: {
    tabMounted() {
      this.SetUsersList()
      this.SetProjectsList()
      this.SetBankHolidays()
      this.showStats = true
      // this.showSensitiveData = false
      this.timeLogStatsItems = []
      this.teamStatsItems = []
      this.projectsStatsItems = []
      this.timeLogReportsItems = []
    },
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    async SetUsersList() {
      const Users = await Services.Users.Admin.getList()
      const List =
        Users?.data?.content?.map(
          ({ uid, firstName, lastName, role, email }) => {
            return {
              uid,
              fullName: `${firstName} ${lastName}`.trim(),
              role,
              email
            }
          }
        ) ?? []
      this.teamMembers = List.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1
        }
        if (a.fullName > b.fullName) {
          return 1
        }
        return 0
      })
    },
    async SetProjectsList() {
      const projects = await Services.Projects.Admin.getProjects()
      const List =
        projects?.data?.content.map(
          ({ uid, type, code, title, client, meta, status }) => {
            return { uid, type, code, title, client, meta, status }
          }
        ) ?? []
      this.projectsList = List.sort((a, b) => {
        return a.title > b.title
      })
    },
    async SetBankHolidays() {
      const { data } = await ServicesOld.GetBankHolidays()
      this.bankHolidays = (data?.content ?? []).map((bh) => bh.date)
    },
    async checkUsersList(ev) {
      this.usersTempList = []
      if (!this.teamMembers.length) {
        await this.SetUsersList()
      }
      const searchResult = this.teamMembers.find(({ fullName }) => {
        const regEx = new RegExp(String(ev.target.value).trim(), 'ig')
        return regEx.test(fullName)
      })
      if (searchResult?.uid) {
        const existed =
          this.usersTempList.findIndex((itm) => itm.uid === searchResult.uid) >
          -1
        if (!existed) {
          this.usersTempList.unshift(searchResult)
        }
      }
      setTimeout(() => {
        this.usersTempList = []
      }, 6000)
    },
    async checkProjectsList(ev) {
      this.projectsTempList = []
      if (!this.projectsList.length) {
        await this.SetProjectsList()
      }
      const searchResult = this.projectsList.find(({ title, code }) => {
        const regEx = new RegExp(String(ev.target.value).trim(), 'ig')
        return regEx.test(title) || regEx.test(code)
      })
      if (searchResult?.uid) {
        const existed =
          this.projectsTempList.findIndex(
            (itm) => itm.uid === searchResult.uid
          ) > -1
        if (!existed) {
          this.projectsTempList.unshift(searchResult)
        }
      }
      setTimeout(() => {
        this.projectsTempList = []
      }, 5000)
    },
    async GetReports() {
      try {
        // check if at least one filter is set
        if (
          !this.reportsFilters.filters.user &&
          !this.reportsFilters.filters.projects.length &&
          !this.reportsFilters.filters.dates.length
        ) {
          this.$bvToast.toast('Please select at least one filter', {
            title: 'No filters selected',
            variant: 'warning',
            solid: true
          })
          return
        }
        const filtersToUse = {}
        for (const key in this.reportsFilters.filters) {
          if (this.reportsFilters.filters[key]) {
            if (key === 'projects') {
              filtersToUse[key] = this.reportsFilters.filters[key].join(',')
            } else {
              filtersToUse[key] = this.reportsFilters.filters[key]
            }
          }
        }
        if (!Object.keys(filtersToUse).length) {
          this.$bvToast.toast('Please select at least one filter', {
            title: 'No filters selected',
            variant: 'warning',
            solid: true
          })
          return
        }
        this.loading = true
        const { data } = await ServicesOld.GetTimeLogReports(filtersToUse, [
          'stats',
          'teamStats',
          'projectsStats',
          'list'
        ])
        this.timeLogStatsItems = [data?.content?.stats]
        this.teamStatsItems = data?.content?.teamStats ?? []
        this.projectsStatsItems = data?.content?.projectsStats ?? []
        this.timeLogReportsItems =
          data?.content?.list?.map((itm) => {
            return {
              date: parseISO(itm.date),
              owner: {
                uid: itm.owner.uid,
                fullName: `${itm.owner.firstName} ${itm.owner.lastName}`.trim(),
                role: itm.owner.role.label
              },
              project: {
                uid: itm.project.uid,
                title: itm.project.title || itm.project.meta.projectName,
                code: itm.project.code,
                client: itm.project?.client ?? '',
                type: itm.project.type,
                systemProject: itm.project.meta.system,
                color: itm.project.meta?.color ?? '#000000'
              },
              hours: itm.hours,
              hourlyRate: itm?.rate?.hourlyRate ?? 0,
              ratePeriod:
                [itm?.rate?.startDate ?? null, itm?.rate?.endDate] ?? null,
              cost: itm?.cost ?? 0
            }
          }) ?? []
        this.CTReportItems = data?.content?.ctreport ?? []
        this.loading = false
        // console.log({ filtersToUse })
        // console.log({ Reports: this.timeLogReportsItems })
        // console.log({ TeamStats: this.teamStats })
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    datesChanged(dates) {
      this.commitToStore('setReportsFilters', {
        filter: 'dates',
        data: dates
      })
    },
    userChanged(fullName) {
      const user = this.teamMembers.find(
        (member) => member.fullName === fullName
      )
      this.commitToStore('setReportsFilters', {
        filter: 'user',
        data: {
          uid: user?.uid ?? null,
          fullName
        }
      })
    },
    projectChanged(title) {
      const project = this.projectsList.find(
        (project) => project.title === title
      )
      this.commitToStore('setReportsFilters', {
        filter: 'project',
        data: {
          uid: project?.uid ?? null,
          title
        }
      })
    },
    addToFilters(itm, filter, execSearch = true) {
      if (!itm) {
        return
      }
      const componentFilters = {
        uid: itm.uid
      }
      switch (filter) {
        case 'user':
          componentFilters.fullName =
            itm?.fullName ?? `${itm.firstName} ${itm.lastName}`.trim()
          this.usersTempList = []
          break
        case 'project':
          componentFilters.title = itm.title
          this.projectsTempList = []
          break
      }
      this.commitToStore('setReportsFilters', {
        filter,
        data: componentFilters
      })
      if (execSearch) {
        this.GetReports()
      }
    },
    removeFromFilters(itm, filter, execSearch = true) {
      if (!itm) {
        return
      }
      const componentFilters = {
        uid: itm.uid
      }
      switch (filter) {
        case 'user':
          componentFilters.fullName =
            itm?.fullName ?? `${itm.firstName} ${itm.lastName}`.trim()
          this.usersTempList = []
          break
        case 'project':
          componentFilters.title = itm.title
          this.projectsTempList = []
          break
      }
      this.commitToStore('deleteReportsFilters', {
        filter,
        data: componentFilters
      })
      if (execSearch) {
        this.GetReports()
      }
    }
  }
}
</script>

<style>
.selected-project {
  width: 100px;
  background-color: #333 !important;
  color: #fff !important;
  margin: 0 3px;
  padding: 0;
  text-align: center;
  font-size: 9pt;
  font-weight: 600;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
  text-shadow: #666 0 0 3px;
}
.settings-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.lizard-user-active-btn {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-user-active-btn:hover {
  cursor: pointer;
  color: #eee !important;
}
.lizard-user-active-btn:active {
  color: #ccc !important;
}
.lizard-user-active-btn-disabled {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
  cursor: 'not-allowed';
  opacity: 0.5;
}
.lizard-employeeRole {
  font-size: 9pt !important;
  font-weight: bold;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-active {
  width: 50px !important;
  text-align: center !important;
}
.lizard-column-status {
  width: 80px !important;
  text-align: center !important;
}
.lizard-column-actions {
  text-align: center !important;
}
.lizard-column-actions .manage-rates-btn {
  margin: 0;
  font-size: 8pt !important;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.lizard-header-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell:hover {
  background-color: #eee;
}

.allowence-table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #999 !important;
  text-align: center;
}
.allowence-data-row {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}
.allowence-data-row .allowence-input {
  border: 0;
  background: transparent;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.allowence-data-row:hover {
  background-color: rgba(102, 204, 255, 0.1);
}
ul.lizard-temp-list,
ul.lizard-main-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.lizard-temp-list li,
ul.lizard-main-list li {
  display: inline-block;
  margin: 0 1px;
  cursor: pointer;
}
</style>
