var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ico)?_c('div',{staticClass:"selected-project",on:{"click":_vm.fn}},[_c('div',[_c('b-icon',{attrs:{"icon":_vm.ico,"scale":"2"}})],1)]):_c('div',{staticClass:"selected-project",class:_vm.projectToDrag && _vm.projectToDrag.uid === _vm.tile.uid ? 'lizard-dragged' : '',style:(_vm.tile.costLevelColor
      ? 'background: linear-gradient(180deg,' +
        _vm.tile.color +
        ' 80%, ' +
        _vm.tile.costLevelColor +
        ' 20%) !important'
      : 'background-color: ' + _vm.tile.color + ' !important'),on:{"click":function($event){return _vm.dragProject()}}},[(_vm.tile.costLevelValue)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(
      'Cost utilization to date: ' + _vm.tile.costLevelValue + '%'
    ),expression:"\n      'Cost utilization to date: ' + tile.costLevelValue + '%'\n    ",modifiers:{"hover":true,"bottom":true}}],attrs:{"title":_vm.tile.name}},[_vm._v(" "+_vm._s(_vm.tile.name)+" ")]):_c('div',{attrs:{"title":"Click to select"}},[_vm._v(" "+_vm._s(_vm.tile.name)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }