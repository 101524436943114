<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-8 text-left lizard-ct-report-head-message">
        <h3>
          <b-spinner v-if="loading" type="grow" label="Spinning"></b-spinner>
          Time Entries Report for Selected Projects
        </h3>
      </div>
      <div class="col-lg-4 text-right">
        <b-button
          :variant="loading ? 'outline-secondary' : 'outline-info'"
          size="sm"
          @click="GetReports"
          :disabled="loading"
        >
          <b-icon v-if="!loading" icon="clipboard-data"></b-icon>
          <b-spinner v-if="loading" small label="Spinning"></b-spinner>
          <span v-if="loading"> Please wait...</span>
          <span v-if="!loading"> Get The Latest Report</span>
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-table
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="CTReportItems"
          :fields="CTReportFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
          v-if="CTReportItems.length"
        >
          <template #cell(projectCode)="data">
            <h6>
              <b-badge variant="info">{{ data.item.projectCode }}</b-badge>
            </h6>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="CTReportItems.length">
      <div class="col-lg">
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="CTReportItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="showExcelBtn">
      <div class="col-lg-12 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportExcelOpts"
          :type="exportExcel.stats.main.type"
          :name="exportExcel.stats.main.name"
          :fields="exportExcel.stats.main.fields"
          :data="exportExcel.stats.main.data"
          :worksheet="exportExcel.stats.main.worksheet"
        >
          <b-button variant="outline-success" size="sm" :disabled="loading"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            <span v-if="!loading"> Export Excel</span>
            <span v-else> Please wait...</span>
          </b-button>
        </download-excel>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'

export default {
  name: 'CTReportsComponent',
  components: {},
  data() {
    return {
      showExcelBtn: false,
      pager: {
        currentPage: 1,
        perPage: 10
      },
      CTReportItems: [],
      CTReportFields: [
        {
          key: 'title',
          label: 'Project Title',
          thClass: 'ct-report-tbl-col text-left',
          tdClass: 'ct-report-tbl-col text-left',
          sortable: true
        },
        {
          key: 'projectCode',
          label: 'Project Code',
          thClass: 'ct-report-tbl-col text-center',
          tdClass: 'ct-report-tbl-col text-center',
          sortable: true
        },
        {
          key: 'totalHours',
          label: 'Total Hours',
          thClass: 'ct-report-tbl-col text-center',
          tdClass: 'ct-report-tbl-col text-center',
          sortable: false
        },
        {
          key: 'calendarDays',
          label: 'Calendar Days',
          thClass: 'ct-report-tbl-col text-center',
          tdClass: 'ct-report-tbl-col text-center',
          sortable: false
        },
        {
          key: 'actualDays',
          label: 'Actual Days',
          thClass: 'ct-report-tbl-col text-center',
          tdClass: 'ct-report-tbl-col text-center',
          sortable: false
        },
        {
          key: 'billableDays',
          label: 'Billable Days',
          thClass: 'ct-report-tbl-col text-center',
          tdClass: 'ct-report-tbl-col text-center',
          sortable: false
        }
      ],
      exportExcel: {
        stats: {
          main: {
            type: 'xls',
            name: 'ct-reports-stats.xls',
            worksheet: 'Timesheet Report',
            fields: {
              Title: 'title',
              'Project code': 'projectCode',
              'Total hours': 'totalHours',
              'Calendar days': 'calendarDays',
              'Actual days': 'actualDays',
              'Billable days': 'billableDays'
            },
            data: []
          }
        }
      },
      loading: false
    }
  },
  mounted() {
    this.$root.$on('tab_reports_ct_selected', this.tabMounted)
  },
  methods: {
    tabMounted() {
      this.showExcelBtn = false
      this.loading = false
    },
    async GetReports() {
      try {
        this.loading = true
        this.CTReportItem = []
        const { data } = await ServicesOld.GetTimeLogReports({}, ['ctreport'])
        const CTReport = data?.content?.ctreport ?? []
        if (CTReport.length) {
          Object.keys(CTReport[0])
            .filter((itm) => {
              return (
                this.CTReportFields.findIndex((fld) => fld.key === itm) === -1
              )
            })
            .forEach((itm) => {
              this.CTReportFields.push({
                key: itm,
                label: itm,
                thClass: 'ct-report-tbl-col text-center',
                tdClass: 'ct-report-tbl-col text-center',
                sortable: false
              })
            })
          this.CTReportItems = CTReport
          this.showExcelBtn = true
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    async generateExcel() {
      if (!this.CTReportItems.length) {
        await this.GetReports()
      }
      // add users to exportExcel fields
      Object.keys(this.CTReportItems[0])
        .filter((itm) => {
          return !Object.values(this.exportExcel.stats.main.fields).includes(
            itm
          )
        })
        .forEach((itm) => {
          this.exportExcel.stats.main.fields[itm] = itm
        })
      this.exportExcel.stats.main.data = this.CTReportItems
    },
    resetExportExcelOpts() {
      this.exportExcel.stats.main.data = []
    }
  }
}
</script>

<style>
.lizard-ct-report-head-message {
  color: #ccc;
  font-weight: bolder;
  letter-spacing: -0.1rem;
}
.ct-report-tbl-col {
  text-align: center;
  min-width: 100px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.selected-project {
  width: 100px;
  background-color: #333 !important;
  color: #fff !important;
  margin: 0 3px;
  padding: 0;
  text-align: center;
  font-size: 9pt;
  font-weight: 600;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
  text-shadow: #666 0 0 3px;
}
.settings-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.lizard-user-active-btn {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-user-active-btn:hover {
  cursor: pointer;
  color: #eee !important;
}
.lizard-user-active-btn:active {
  color: #ccc !important;
}
.lizard-user-active-btn-disabled {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
  cursor: 'not-allowed';
  opacity: 0.5;
}
.lizard-employeeRole {
  font-size: 9pt !important;
  font-weight: bold;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-active {
  width: 50px !important;
  text-align: center !important;
}
.lizard-column-status {
  width: 80px !important;
  text-align: center !important;
}
.lizard-column-actions {
  text-align: center !important;
}
.lizard-column-actions .manage-rates-btn {
  margin: 0;
  font-size: 8pt !important;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.lizard-header-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell:hover {
  background-color: #eee;
}

.allowence-table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #999 !important;
  text-align: center;
}
.allowence-data-row {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}
.allowence-data-row .allowence-input {
  border: 0;
  background: transparent;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.allowence-data-row:hover {
  background-color: rgba(102, 204, 255, 0.1);
}
ul.lizard-temp-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.lizard-temp-list li {
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
}
</style>
