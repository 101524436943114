<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-12">
        <ProjectList
          :metas="metas"
          :listItems="items"
          :ems="ems"
          :showProjectFilter="clonedItems.length > 0"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat } from 'date-fns'
import Services from '../../services/main.service.vue'
import ProjectList from './projects-components/ProjectList.vue'

export default {
  name: 'ProjectsComponent',
  components: {
    ProjectList
  },
  data() {
    return {
      showFeeCheckbox: false,
      clonedItems: [], // using for filtering
      items: [],
      ems: [],
      filteredBy: null,
      metas: {}
    }
  },
  mounted() {
    this.getProjectsMetas()
    this.fetchData()
    this.$root.$on('tab_projects_refresh', this.fetchData)
    this.$root.$on('add-new', this.addNew)
    this.$root.$on('save-new', this.saveNew)
    this.$root.$on('update-project', this.updateProject)
    this.$root.$on('show-fee-cols', this.showFeeCols)
    this.$root.$on('filter-project-type', this.filterProjectType)
    this.$root.$on('search-project', this.searchProject)
  },
  methods: {
    async getProjectsMetas() {
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        this.metas = data.content.metas
      }
    },
    async fetchData() {
      this.filteredBy = null
      // GET METAS
      await this.getProjectsMetas()
      this.items = []
      this.clonedItems = []
      const Projects = await Services.Projects.Admin.getProjects()
      if (Projects.status == 200) {
        const systemProjects = Projects.data.content
          .filter((itm) => itm.meta.system)
          .map((itm) => {
            itm.source = itm.meta?.partner ?? ''
            itm._new = false
            itm._edit = false
            return itm
          })
        Projects.data.content
          .filter((itm) => !itm.meta.system)
          .forEach((itm) => {
            itm.source = itm.type === 'project' ? '' : itm.meta?.partner ?? ''
            itm.status = itm.status.toUpperCase() === 'LIVE'
            itm._new = false
            itm._edit = false
            this.items.push(itm)
          })
        this.clonedItems = [...this.items, ...systemProjects]
        this.filteredBy = 'project'
      }
      // GET USERS WITH EXPERT ROLE
      this.ems = []
      const Users = await Services.Users.getUsersByRole('manager')
      if (Users.status == 200) {
        Users.data.content.forEach((user) => {
          this.ems.push({
            value: user.uid,
            text: `${user.firstName} ${user.lastName}`
          })
        })
      }
    },
    filterProjectType(type = 'project') {
      this.items = this.clonedItems.filter(
        (itm) => itm.type.toUpperCase() === type?.toUpperCase() ?? 'PROJECT'
      )
      this.filteredBy = type
    },
    searchProject(q = null) {
      if (q && q.trim().length) {
        // console.log('filtering...', this.filteredBy)
        this.items = this.clonedItems
          // .filter((itm) => {
          //   if (this.filteredBy) {
          //     return itm.type.toUpperCase() === this.filteredBy.toUpperCase()
          //   }
          // })
          .filter((itm) => {
            const qRegEx = new RegExp(`^${q.trim()}`, 'i')
            const title = qRegEx.test(itm.title.trim())
            const projectCode = qRegEx.test(itm.code.trim())
            return title || projectCode || q === itm.uid
          })
      } else {
        this.items = this.clonedItems.filter((itm) => {
          if (this.filteredBy) {
            return itm.type.toUpperCase() === this.filteredBy.toUpperCase()
          }
        })
      }
    },
    async addNew(type = 'PROJECT') {
      if (this.items.findIndex((itm) => itm._new) > -1) return
      const defaultProject = {
        type,
        code: null,
        title: '',
        client: null,
        partners: null,
        meta: {
          color: '#000',
          partner: null,
          projectName: '',
          ems: ''
        },
        status: 'CLOSED',
        sector: 'OTHER',
        todos: 'NTD',
        notes: '',
        totalassociates: 0,
        totalanalysts: 0,
        budget: 0,
        totalFees: 0,
        active: false,
        created: dateFormat(new Date(), 'yyyy-MM-dd'),
        deadline: null,
        _new: true,
        _edit: true
      }
      this.items.unshift(defaultProject)
    },
    async saveNew() {
      try {
        const toCreate = this.items.find((itm) => itm._new)
        if (toCreate?.length == 0 || typeof toCreate == 'undefined') return
        const { status } = await Services.Projects.Admin.create(toCreate)
        if (status == 200) {
          this.items = this.items.map((itm) => {
            itm._new = false
            itm._edit = false
            return itm
          })
          this.$bvToast.toast('Project created', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
        }
      } catch (Exception) {
        this.items = this.items.splice(1)
        console.log(Exception?.response ?? Exception)
        // const defaultErrorMsg = 'Action Failed, Please contact Admin.'
        // const { status, code, details } = Exception?.response?.data?.error ?? {}
        // switch (true) {
        //   case status == 400 && code == 'VALIDATION_ERROR':
        //     this.$bvToast.toast(
        //       details.validationErrors[0]?.message ?? defaultErrorMsg,
        //       {
        //         title: 'Error',
        //         variant: 'danger',
        //         solid: true
        //       }
        //     )
        //     break
        //   default:
        //     this.$bvToast.toast(defaultErrorMsg, {
        //       title: 'Error',
        //       variant: 'danger',
        //       solid: true
        //     })
        // }
      }
    },
    async updateProject(project) {
      try {
        if (project._new) {
          await this.saveNew(project.type)
          return
        }
        await Services.Projects.Admin.update(project.uid, project)
      } catch (Exception) {
        throw Exception
      }
    }
  },
  watch: {
    filteredBy: {
      handler: function (val) {
        this.filterProjectType(val)
      },
      immediate: true
    }
  }
}
</script>

<style>
.cost-details-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
. {
  width: calc(100vw / 11) !important;
  min-width: calc(100vw / 11) !important;
  max-width: calc(100vw / 11) !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow: auto;
}
.lizard-column-fees {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.lizard-column-fees-disabled {
  color: #999;
}
.lizard-scroll {
  overflow-y: none !important;
  overflow-x: scroll !important;
  padding: 0 !important;
}
.lizard-scroll .table {
  width: auto !important;
}
/* width */
.lizard-scroll::-webkit-scrollbar {
  width: 1px !important;
}

/* Track */
.lizard-scroll::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
.lizard-scroll::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
.lizard-scroll::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.lizard-candesic-table {
  width: auto !important;
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0 !important;
  margin: 0 auto !important;
}
.lizard-candesic-table .label {
  color: #fff;
  text-align: center;
  padding: 4px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 30px;
  background-color: #66ccff;
}
.lizard-candesic-table .value {
  color: #666;
  text-align: center;
  padding: 4px;
  font-size: 10pt;
  height: 40px;
  line-height: 30px;
}
.lizard-candesic-table .w10 {
  min-width: 140px !important;
}
.lizard-candesic-table .w40 {
  width: 40% !important;
}
.lizard-candesic-table .aleft {
  text-align: left;
}
</style>

<style scoped>
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  text-align: center !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.lizard-date-input {
  text-align: center;
  width: 90px;
  max-width: 90px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
