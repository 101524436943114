<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-5"></div>
      <div class="col-lg-2">TEST</div>
      <div class="col-lg-5"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'DropTree',
  components: {},
  data() {
    return {
      // define the default value
      value: null,
      // define options
      options: [
        {
          id: 'a',
          label: 'a',
          children: [
            {
              id: 'aa',
              label: 'aa'
            },
            {
              id: 'ab',
              label: 'ab'
            }
          ]
        },
        {
          id: 'b',
          label: 'b'
        },
        {
          id: 'c',
          label: 'c'
        }
      ]
    }
  },
  mounted() {
    console.log('DropTree mounted')
  },
  methods: {}
}
</script>
