var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.weeks.defaultDates.length && _vm.users.length)?_c('b-container',{class:{ 'lizard-no-padding': _vm.mobileView },staticStyle:{"padding-right":"0 !important"},attrs:{"fluid":""}},[_c('div',{staticClass:"row calendar-header"},[_c('div',{staticClass:"col-lg team",class:{ mobile: _vm.mobileView }},[_vm._v("Name")]),_vm._l((_vm.weeks.defaultDates),function(date,index){return _c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(
        _vm.isToday(date) ? 'Today' : _vm.formatHeaderDate(date, 'EEEE, LLLL do yyyy')
      ),expression:"\n        isToday(date) ? 'Today' : formatHeaderDate(date, 'EEEE, LLLL do yyyy')\n      ",modifiers:{"hover":true,"bottom":true}}],key:index,staticClass:"col-lg day-holder",class:{
        today: _vm.isToday(date),
        weekend: _vm.isWeekend(date) || _vm.isHoliday(date),
        mobile: _vm.mobileView
      }},[_vm._v(" "+_vm._s(_vm.formatHeaderDate(date))+" ")])})],2),_vm._l((_vm.weeks.dates),function(date,index){return _c('div',{key:date.user.uid + '_' + index,staticClass:"row calendar-body",class:{ bgOdd: index % 2 !== 0 }},[_c('div',{staticClass:"col-lg team",class:{ mobile: _vm.mobileView },style:(_vm.getSelectedStyle(date.user.color))},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm",staticStyle:{"margin":"10px"}},[_vm._v(" "+_vm._s(date.user.name)+" ")])])]),_vm._l((date.blocks),function(block,index){return _c('div',{key:block.key + '_' + index,staticClass:"col-lg block",class:{
        weekend: _vm.isWeekend(block.date) || _vm.isHoliday(block.date),
        mobile: _vm.mobileView
      }},[(_vm.isWeekend(block.date) || _vm.isHoliday(block.date))?_c('span'):_c('span',_vm._l((block.items),function(project,index){return _c('div',{key:block.key + '_' + project.aid + '_' + index,class:{
            project:
              !project.isHoliday ||
              !_vm.isWeekend(block.date) ||
              _vm.isHoliday(block.date),
            'block-text-shadow':
              !_vm.isWeekend(block.date) &&
              !_vm.isHoliday(block.date) &&
              !_vm.isWFA(block),
            'stripe-bg':
              !_vm.isHoliday(block.date) &&
              !_vm.isWeekend(block.date) &&
              !_vm.isWFA(block) &&
              project.isHoliday,
            'wfa-stripe-bg':
              !_vm.isHoliday(block.date) &&
              !_vm.isWeekend(block.date) &&
              _vm.isWFA(block) &&
              project.isHoliday,
            'full-block': block.items.length == 1
          },style:(_vm.setProjectColorAsBg(project.color))},[(!_vm.isWeekend(block.date) && !_vm.isHoliday(block.date))?_c('span',[(project.isHoliday)?_c('h6',[_c('b-badge',{staticStyle:{"text-transform":"uppercase"},attrs:{"variant":"warning"}},[_vm._v(_vm._s(project.name))])],1):_c('span',[_vm._v(_vm._s(project.name))])]):_vm._e()])}),0)])})],2)})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }