<template>
  <b-container fluid>
    <div class="row" v-if="list.length">
      <div class="col-lg-2"></div>
      <div class="col-lg-10">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :busy="loading"
          :items="list"
          :fields="fields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption>Team Statistics</template>
          <template #cell(fullName)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to include in filters"
              @click="fnAddToFilters(data.item, 'user')"
              style="cursor: pointer"
            >
              {{ data.item.firstName }} {{ data.item.lastName }}
            </span>
          </template>
          <template #cell(lastTimeEntry)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              :title="dateToNowDiff(data.item.lastTimeEntry)"
            >
              {{ formatDate(data.item.lastTimeEntry) }}
            </span>
          </template>
          <template #cell(totalCost)="data">
            <span v-if="showSensitiveData"
              >£{{ numberWithCommas(data.item.totalCost) || 0 }}</span
            >
            <span v-else>
              <h6>
                <b-badge variant="light" style="color: #999">***</b-badge>
              </h6>
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row" v-if="list.length">
      <div class="col-lg-2"></div>
      <div class="col-lg-5 text-center">
        <b-pagination
          v-if="list.length > pager.perPage"
          v-model="pager.currentPage"
          :total-rows="list.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
      <div class="col-lg-5 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportExcelOpts"
          :type="exportExcel.stats.team.type"
          :name="exportExcel.stats.team.name"
          :fields="exportExcel.stats.team.fields"
          :data="exportExcel.stats.team.data"
          :worksheet="exportExcel.stats.team.worksheet"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, formatDistanceToNow, parseISO } from 'date-fns'

export default {
  name: 'TimelogTeamStatsComponent',
  props: {
    fnAddToFilters: {
      type: Function,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    showSensitiveData: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'fullName',
          label: 'Team Member',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'role',
          label: 'Role',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'lastTimeEntry',
          label: 'Last Time Entry',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'totalHours',
          label: 'Total Hours',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        {
          key: 'totalCost',
          label: 'Payable Amount',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        }
      ],
      pager: {
        currentPage: 1,
        perPage: 10
      },
      exportExcel: {
        stats: {
          team: {
            type: 'xls',
            name: 'team-stats-reports.xls',
            worksheet: "Candesic's Timelog Enteries Reports | Team Stats",
            fields: {
              'Full Name': 'fullNameExcel',
              Role: 'role',
              'First Time Entry': 'firstTimeEntryExcel',
              'Last Time Entry': 'lastTimeEntryExcel',
              'Total Working Days': 'totalWorkingDays',
              'Total Bank Holidays': 'totalBankHolidays',
              // 'Total Leave Days': 'totalLeaveDays',
              'Total Hours': 'totalHours'
              // 'Average Hours': 'averageHours'
            },
            data: []
          }
        }
      }
    }
  },
  methods: {
    numberWithCommas(x) {
      const parsed = parseFloat(x || 0).toFixed(2)
      return parseFloat(parsed).toLocaleString()
    },
    formatDate(dt) {
      return dateFormat(parseISO(dt), 'dd/MM/yyyy')
    },
    dateToNowDiff(dt) {
      return formatDistanceToNow(parseISO(dt), { addSuffix: true })
    },
    generateExcel() {
      this.exportExcel.stats.team.data = Object.assign(this.list, {}).map(
        (itm) => {
          itm.fullNameExcel = `${itm.firstName} ${itm.lastName}`
          itm.firstTimeEntryExcel = this.formatDate(itm.firstTimeEntry)
          itm.lastTimeEntryExcel = this.formatDate(itm.lastTimeEntry)
          return itm
        }
      )
    },
    resetExportExcelOpts() {
      this.exportExcel.stats.team.data = []
    }
  }
}
</script>
