var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg lizard-form-empty-col"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg"}),_c('div',{staticClass:"col-lg-11"},[_c('b-card',{staticStyle:{"max-height":"75px"},attrs:{"bg-variant":"light"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 text-left"},[_c('el-date-picker',{staticClass:"form-control lizard-form-datepicker",staticStyle:{"max-width":"98% !important"},attrs:{"id":"datesFilterInput","type":"daterange","range-separator":"","start-placeholder":"From","end-placeholder":"To","size":"small","format":"dd/MM/yyyy","value-format":"dd/MM/yyyy","picker-options":_vm.datePickerOptions},on:{"change":_vm.datesChanged},model:{value:(_vm.reportsFilters.filters.dates),callback:function ($$v) {_vm.$set(_vm.reportsFilters.filters, "dates", $$v)},expression:"reportsFilters.filters.dates"}})],1),_c('div',{staticClass:"col-lg-3 text-left"},[_c('b-form-input',{attrs:{"id":"teamMembersFilterInput","size":"sm","placeholder":"Type and select user...","value":_vm.reportsFilters.info.userFullName},on:{"keyup":[_vm.checkUsersList,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addToFilters(
                  _vm.usersTempList.length ? _vm.usersTempList[0] : null,
                  'user',
                  false
                )}],"change":_vm.userChanged}})],1),_c('div',{staticClass:"col-lg-3 text-left"},[_c('b-form-input',{attrs:{"id":"projectsFilterInput","size":"sm","placeholder":"Type and select project...","value":_vm.reportsFilters.info.projectTitle},on:{"keyup":[_vm.checkProjectsList,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addToFilters(
                  _vm.projectsTempList.length ? _vm.projectsTempList[0] : null,
                  'project',
                  false
                )}],"change":_vm.projectChanged}})],1),_c('div',{staticClass:"col-lg-2 text-center"},[(!_vm.loading)?_c('b-button',{staticStyle:{"font-size":"10pt","font-weight":"bold","min-width":"90px","padding":"4px 8px"},attrs:{"variant":"info","size":"sm"},on:{"click":_vm.GetReports}},[_vm._v("Get Reports")]):_c('b-button',{staticStyle:{"font-size":"10pt","font-weight":"bold","min-width":"90px","padding":"4px 8px"},attrs:{"variant":"secondary","size":"sm","disabled":""}},[_vm._v("Loading...")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 text-left"}),_c('div',{staticClass:"col-lg-3 text-left"},[_c('ul',{staticClass:"lizard-temp-list"},_vm._l((_vm.usersTempList),function(user){return _c('li',{key:user.uid,on:{"click":function($event){return _vm.addToFilters(user, 'user', false)}}},[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(user.fullName))])],1)}),0)]),_c('div',{staticClass:"col-lg-3 text-left"},[_c('ul',{staticClass:"lizard-main-list"},_vm._l((_vm.reportsFilters.info.projectTitles),function(project,index){return _c('li',{key:index,on:{"click":function($event){return _vm.removeFromFilters(
                    {
                      uid: _vm.reportsFilters.filters.projects[index],
                      title: project
                    },
                    'project',
                    true
                  )}}},[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(_vm._s(project))])],1)}),0),_c('ul',{staticClass:"lizard-temp-list"},_vm._l((_vm.projectsTempList),function(project){return _c('li',{key:project.uid,on:{"click":function($event){return _vm.addToFilters(project, 'project', false)}}},[_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(project.title))])],1)}),0)]),_c('div',{staticClass:"col-lg-2 text-center"})])])],1),_c('div',{staticClass:"col-lg"})]),(_vm.showStats)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('timelog-stats-component',{attrs:{"loading":_vm.loading,"showSensitiveData":_vm.showSensitiveData,"list":_vm.timeLogStatsItems,"ctreport":_vm.CTReportItems}})],1)]):_vm._e(),(_vm.showStats)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('timelog-team-stats-component',{attrs:{"loading":_vm.loading,"fnAddToFilters":_vm.addToFilters,"showSensitiveData":_vm.showSensitiveData,"list":_vm.teamStatsItems}})],1),_c('div',{staticClass:"col-lg-6"},[_c('timelog-projects-stats-component',{attrs:{"loading":_vm.loading,"fnAddToFilters":_vm.addToFilters,"showSensitiveData":_vm.showSensitiveData,"list":_vm.projectsStatsItems}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('timelog-reports-component',{attrs:{"loading":_vm.loading,"fnAddToFilters":_vm.addToFilters,"showSensitiveData":_vm.showSensitiveData,"list":_vm.timeLogReportsItems}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }