<template>
  <div class="container-lg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">
        <h6>
          <span></span>
        </h6>
      </div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmProject']">
      <div class="col-lg">
        <project-selector :activityType="activityType" />
      </div>
    </div>
    <div class="row" v-if="FormLayouts['FrmHolidayType']">
      <div class="col-lg">
        <holiday-type-selector />
      </div>
    </div>
    <div class="row" v-if="FormLayouts['FrmDate']">
      <div class="col-lg">
        <date-selector
          :activityType="activityType"
          :dateRangeSelected="FormLayouts['FrmDateRange']" />
      </div>
    </div>
    <div class="row" v-if="FormLayouts['FrmTotalDays']">
      <div class="col-lg"><days-requested-selector /></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmCategories']">
      <div class="col-lg">
        <categories-selector />
      </div>
    </div>
    <div class="row" v-if="FormLayouts['FrmAmount']">
      <div class="col-lg">
        <amount-selector />
      </div>
    </div>
    <div class="row" v-if="FormLayouts['FrmVAT']">
      <div class="col-lg"><vat-selector /></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmHours']">
      <div class="col-lg"><hours-selector /></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmReceipt']">
      <div class="col-lg"><attachment-selector /></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmManager']">
      <div class="col-lg"><manager-selector /></div>
    </div>
    <div class="row" v-if="FormLayouts['FrmNotes']">
      <div class="col-lg"><notes-selector /></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-2"></div>
      <div class="col-sm-7 text-right">
        <b-button
          size="sm"
          title="Add and clear the form."
          variant="info"
          @click="addEntry"
          >Add</b-button
        >
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </div>
</template>

<script>
import ProjectSelector from './form-components/ProjectSelector.vue'
import DateSelector from './form-components/DateSelector.vue'
import HoursSelector from './form-components/HoursSelector.vue'
import NotesSelector from './form-components/NotesSelector.vue'
import CategoriesSelector from './form-components/CategoriesSelector.vue'
import AmountSelector from './form-components/AmountSelector.vue'
import AttachmentSelector from './form-components/AttachmentSelector.vue'
import ManagerSelector from './form-components/ManagerSelector.vue'
import HolidayTypeSelector from './form-components/HolidayTypeSelector.vue'
import VatSelector from './form-components/VATSelector.vue'
import DaysRequestedSelector from './form-components/DaysRequestedSelector.vue'

export default {
  name: 'ActivityForm',
  props: ['activityType'],
  components: {
    CategoriesSelector,
    ProjectSelector,
    DateSelector,
    HoursSelector,
    NotesSelector,
    AmountSelector,
    AttachmentSelector,
    ManagerSelector,
    HolidayTypeSelector,
    VatSelector,
    DaysRequestedSelector
  },
  data() {
    return {
      generateForm: false,
      holidayOptions: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    FormLayouts() {
      return this.Store.formLayouts
    },
    items() {
      return this.$store.getters.activityItems
    }
  },
  mounted() {
    this.$root.$once('holidayTypeOptions', (list) => {
      this.holidayOptions = list
    })
    this.selectType(null, this.activityType)
  },
  methods: {
    selectType(ev, val = null) {
      try {
        let key = ev?.target?.value ?? val
        if (!key) {
          return
        }
        let layoutsToShow = []
        switch (key) {
          case 'hld':
            layoutsToShow = ['HolidayType', 'Date', 'DateRange', 'TotalDays']
            break
          case 'exp':
            layoutsToShow = [
              'Project',
              'Date',
              'Categories',
              'Amount',
              'Receipt',
              'Manager',
              'VAT'
            ]
            break
        }
        layoutsToShow = layoutsToShow.concat(['Notes'])
        layoutsToShow = layoutsToShow.map((layout) => `Frm${layout}`)
        for (let layout in this.FormLayouts) {
          this.$store.commit('setFormLayouts', {
            layout,
            show: layoutsToShow.indexOf(layout) !== -1
          })
        }
        this.generateForm = true
      } catch (Exception) {
        this.resetForm(true)
        console.log({ Exception: Exception.message })
      }
    },
    resetForm() {
      this.$store.commit('resetFormData')
      this.$store.commit('resetFormLayouts')
      this.generateForm = false
    },
    async addEntry() {
      let isValid = true
      let errormsg = []
      let newEntry = {}
      const typesMap = {
        def: null,
        hld: 'holiday',
        exp: 'expense'
      }
      this.$store.commit('modifyFormData', {
        key: 'type',
        value: this.activityType
      })
      for (let key in this.FormData) {
        let val = this.FormData[key]
        let valType = Object.prototype.toString.call(val)
        valType = valType.substr(valType.indexOf(' ') + 1, 3).toLowerCase()
        switch (key) {
          case 'type':
            newEntry[key] = typesMap[val]
            break
          case 'project':
            newEntry[key] = val === 'def' ? null : val
            break
          case 'dates':
            if (valType !== 'arr') {
              val = [val, val]
            }
            newEntry[key] = val
            break
          case 'duration':
            if (parseFloat(val) > 18) {
              isValid = false
              errormsg.push(
                'Maximum allowed hours to be added per day are 18 hours.'
              )
            }
            newEntry[key] = parseFloat(val)
            break
          case 'vat':
            newEntry[key] = val.amount
            break
          default:
            newEntry[key] = val
        }
      }
      if (newEntry.type === 'expense') {
        isValid =
          isValid && newEntry.file && typeof newEntry.file !== 'undefined'
        if (!isValid) {
          errormsg.push(`you have to attach the expense bill`)
        }
        newEntry['ProjectCode'] = newEntry?.project ?? null
        newEntry['Description'] = newEntry?.notes ?? null
        newEntry['AttachmentObjectKey'] = newEntry?.file ?? null
      }
      if (newEntry.type == 'holiday') {
        newEntry.holidayLabel =
          this.holidayOptions.find((itm) => {
            if (!newEntry.holidayType) {
              return {}
            }
            if (
              itm.value ==
                newEntry.holidayType.toUpperCase() +
                  newEntry.holidayHalfDayMeridiem ||
              itm.value == newEntry.holidayType
            ) {
              return itm
            }
            return null
          })?.text ?? 'N/A'
      }
      isValid =
        isValid && Object.values(typesMap).slice(1).includes(newEntry.type)
      if (!isValid) errormsg.push(`type "${newEntry.type}" is not valid`)
      if (newEntry.type === 'expense') {
        isValid = isValid && newEntry.project !== null
        if (!isValid) errormsg.push(`selected project is not valid`)
      }
      if (newEntry.type === 'holiday') {
        isValid = isValid && newEntry.holidayType !== null
        if (!isValid) errormsg.push(`selected holiday type is not valid`)
      }
      newEntry.dates = newEntry.dates.filter((dt) => dt !== null)
      isValid = isValid && newEntry.dates.length
      if (!isValid) errormsg.push(`selected date is not valid`)
      if (isValid && newEntry.type === 'holiday') {
        newEntry.duration = this.FormData.totalDays
      }
      isValid = isValid && newEntry.duration > 0
      if (!isValid) errormsg.push(`selected dates are not valid`)
      if (isValid && newEntry.type === 'expense') {
        isValid = isValid && newEntry.category !== 'def'
        if (!isValid) errormsg.push(`selected category are not valid`)
      }
      if (!isValid) {
        return this.sendErrNotif('Error: ' + errormsg[0])
      }
      newEntry = this.ObjFilter(newEntry, (val) => val !== null)
      // if (this.isDuplicated(newEntry)) {
      //   return this.sendErrNotif('Record already exists.')
      // }
      this.$store.commit('addActivityEntry', {
        list: newEntry.type === 'expense' ? 'expenseItems' : 'holidayItems',
        item: newEntry
      })
      // this.resetForm()
      // if (newEntry.type === 'expense') {
      //   // this.resetForm()
      //   // this.FormLayouts.FrmReceipt = false
      //   // this.FormData.file = null
      //   // setTimeout(() => {
      //   //   this.FormLayouts.FrmReceipt = true
      //   // }, 500)
      // }
    },
    isDuplicated(newEntry) {
      const { type, project, duration, dates, holiday } = newEntry
      const listToCheck =
        type === 'holiday' ? this.items.holidayItems : this.items.listItems

      const uniqueValues = new Set(
        listToCheck.map(function ({
          type,
          project,
          duration,
          dates,
          holidayLabel
        }) {
          return JSON.stringify({
            type,
            project,
            duration,
            dates: dates.join(','),
            holidayLabel
          })
        })
      )
      return uniqueValues.has(
        JSON.stringify({
          type,
          project,
          duration,
          dates: dates.join(','),
          holiday
        })
      )
    },
    sendErrNotif(msg = 'Please check entries and try again.') {
      this.$toasted.error(msg).goAway(3500)
    },
    ObjFilter(obj = {}, predicate) {
      return Object.assign(
        ...Object.keys(obj)
          .filter((key) => predicate(obj[key]))
          .map((key) => ({ [key]: obj[key] }))
      )
    }
  }
}
</script>

<style>
.no-activity-day div span {
  background-color: rgb(236, 143, 143) !important;
  color: #fff !important;
}
.half-activity-day div span {
  background-color: #ccc !important;
  color: #333 !important;
}
.full-activity-day div span {
  background-color: rgb(157, 247, 157) !important;
  color: #333 !important;
}
.bank-holiday-day div span {
  background-color: rgba(200, 200, 200, 0.5) !important;
  color: #fff !important;
}
.holiday-activity-day div span {
  background-color: #eee !important;
}
</style>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
