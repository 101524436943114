<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2" style="text-align: right !important">
      <label for="" class="h6">Notes</label>
    </div>
    <div class="col-sm-7">
      <textarea
        cols="30"
        rows="5"
        class="form-control txt"
        style="resize: none"
        :value="Notes"
        @change="noteChanged"></textarea>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
export default {
  name: 'NotesSelector',
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    Notes() {
      return this.FormData.notes
    }
  },
  methods: {
    noteChanged(ev) {
      const value = ev?.target?.value ?? this.Notes
      if (value.trim() !== this.Notes) {
        this.$store.commit('modifyFormData', {
          key: 'notes',
          value
        })
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
