<template>
  <b-container fluid>
    <div class="row" v-if="clonedItems.length">
      <div class="col-sm text-center">
        <b-form-input v-model="q" placeholder="Search..."></b-form-input>
      </div>
      <div class="col-sm-2 text-right">
        <create-user :roles="rolesList" />
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-sm-12">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption></template>
          <template #cell(name)="data">
            {{ data.item.firstName + ' ' + data.item.lastName }}
          </template>
          <template #cell(birthday)="data">
            <el-date-picker
              v-if="data.item._edit"
              v-model="data.item.meta.birthday"
              id="birthday"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              format="dd/MM/yyyy"
              :picker-options="datePickerOptions"
              @change="modifyBirthday(data.item)"
              @blur="data.item._edit = false"
            ></el-date-picker>
            <span v-else>
              <b-button
                v-if="!data.item.meta.birthday"
                variant="link"
                @click="data.item._edit = true"
                size="sm"
                >Add Birthday</b-button
              >
              <span
                v-else
                @click="data.item._edit = true"
                style="cursor: pointer"
                title="Click to edit"
                >{{ formatBirthday(data.item.meta.birthday) }}</span
              >
            </span>
          </template>
          <template #cell(hourlyRate)="data">
            £{{ data.item.rates.length ? data.item.rates[0].hourlyRate : 0 }}
          </template>
          <template #cell(dayRate)="data">
            £{{ data.item.rates.length ? data.item.rates[0].dayRate : 0 }}
          </template>
          <template #cell(totalAnnualLeaves)="data">{{
            getAnnualLeaves(data.item)
          }}</template>
          <template #cell(role)="data">
            <b-form-select
              size="sm"
              v-model="data.item.role"
              :options="rolesList.filter((itm) => itm.disabled)"
              v-if="!data.item.settings.active || isDisabledRole(data.item)"
              disabled
              class="lizard-employeeRole"
            ></b-form-select>
            <b-form-select
              size="sm"
              v-model="data.item.role"
              :options="rolesList"
              v-else
              class="lizard-employeeRole"
              @change="changeRole(data.item.uid)"
            ></b-form-select>
          </template>
          <template #cell(assignment)="data">
            <span v-if="data.item.assignment.length" class="user-assignments">
              <div
                class="selected-project"
                :style="{
                  'background-color':
                    data.item.assignment[0].assignment.meta.color + '!important'
                }"
                v-if="data.item.assignment[0].assignment.type == 'project'"
              >
                {{ data.item.assignment[0].assignment.title }}
              </div>
              <div
                v-else
                class="selected-project"
                :class="{
                  'stripe-bg': !isWFA(
                    data.item.assignment[0].assignment.meta.holidayType
                  ),
                  'wfa-stripe-bg': isWFA(
                    data.item.assignment[0].assignment.meta.holidayType
                  )
                }"
              >
                <h6>
                  <b-badge
                    variant="warning"
                    style="text-transform: uppercase"
                    >{{
                      isWFA(data.item.assignment[0].assignment.meta.holidayType)
                        ? 'WFA'
                        : data.item.assignment[0].assignment.meta.holidayType
                    }}</b-badge
                  >
                </h6>
              </div>
            </span>
          </template>
          <template #cell(isCurrentEmployee)="data">
            <h6>
              <b-badge
                v-if="!isDisabledRole(data.item)"
                class="lizard-user-active-btn"
                :variant="getActiveBtnVariant(data.item)"
                @click="changeCurrentEmployee(data.item)"
              >
                <span v-if="data.item.settings.active">Active</span>
                <span v-else>Inactive</span>
              </b-badge>
              <b-badge
                v-else
                class="lizard-user-active-btn-disabled"
                variant="dark"
              >
                <span v-if="data.item.settings.active">Active</span>
                <span v-else>Inactive</span>
              </b-badge>
            </h6>
          </template>
          <template #cell(showInStaffing)="data">
            <h6>
              <b-badge
                v-if="!isDisabledRole(data.item)"
                class="lizard-user-active-btn"
                :variant="getActiveBtnVariant(data.item, 'staffing')"
                @click="changeCurrentEmployee(data.item, 'staffing')"
              >
                <span v-if="data.item.settings.staffing">Show</span>
                <span v-else>Hide</span>
              </b-badge>
              <b-badge
                v-else
                class="lizard-user-active-btn-disabled"
                variant="dark"
              >
                <span v-if="data.item.settings.staffing">Show</span>
                <span v-else>Hide</span>
              </b-badge>
            </h6>
          </template>
          <template #cell(rates)="data">
            <b-button
              class="manage-rates-btn"
              :id="data.item.id"
              variant="info"
              size="sm"
              :disabled="!data.item.settings.active || false"
              @click="toggleRateManagementModal(data.item)"
              >Manage</b-button
            >
          </template>
          <template #cell(leaves)="data">
            <b-button
              class="manage-rates-btn"
              :id="data.item.id"
              variant="info"
              size="sm"
              @click="toggleAllowancesModal(data.item)"
              :disabled="!data.item.settings.active || false"
              title="Click to modify allowances"
            >
              Manage
            </b-button></template
          >
        </b-table>
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <!-- <div class="row" v-if="listItems.length">
      <div class="col-lg" style="text-align: right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel">
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div> -->
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="rateManagementModal.show"
      centered
      :title="rateManagementModal.data.title"
      :header-bg-variant="rateManagementModal.headerBgVariant"
      :header-text-variant="rateManagementModal.headerTextVariant"
      :body-bg-variant="rateManagementModal.bodyBgVariant"
      :body-text-variant="rateManagementModal.bodyTextVariant"
      :footer-bg-variant="rateManagementModal.footerBgVariant"
      :footer-text-variant="rateManagementModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-row type="flex" justify="end">
              <el-button
                title="Add New"
                type="text"
                size="small"
                :disabled="rateManagementModal.data.loading"
                @click="toggleNewRateManagementModal"
                >Add New</el-button
              >
            </el-row>
            <el-row><p>&nbsp;</p></el-row>
            <el-row>
              <el-table
                v-loading="rateManagementModal.data.loading"
                element-loading-text="Please wait..."
                :data="rateManagementModal.data.listItems"
                style="width: 100%"
                height="250"
                header-cell-class-name="lizard-header-cell"
                row-class-name="lizard-row-cell"
                ><el-table-column type="index" label="No"> </el-table-column
                ><el-table-column
                  prop="startDate"
                  label="Start date"
                ></el-table-column>
                <el-table-column
                  prop="endDate"
                  label="End date"
                ></el-table-column>
                <el-table-column
                  prop="hourlyRate"
                  label="Hourly rate"
                ></el-table-column>
                <el-table-column prop="dayRate" label="Day rate">
                </el-table-column
                ><el-table-column align="right">
                  <template slot="header">Actions</template>
                  <template slot-scope="scope">
                    <!-- <el-button
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      style="border: 0px"
                      title="Edit"
                      @click="
                        toggleEditRateManagementModal(scope.row)
                      "></el-button> -->
                    <el-button
                      icon="el-icon-delete"
                      circle
                      size="mini"
                      style="border: 0px"
                      title="Delete"
                      @click="deleteRate(scope.row.uid, true)"
                    ></el-button> </template></el-table-column
              ></el-table>
            </el-row>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="toggleRateManagementModal"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="newRateModal.show"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      :title="newRateModal.data.title"
      :header-bg-variant="newRateModal.headerBgVariant"
      :header-text-variant="newRateModal.headerTextVariant"
      :body-bg-variant="newRateModal.bodyBgVariant"
      :body-text-variant="newRateModal.bodyTextVariant"
      :footer-bg-variant="newRateModal.footerBgVariant"
      :footer-text-variant="newRateModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-form
              ref="form"
              :model="newRateModal.data.form"
              label-width="120px"
            >
              <el-form-item label="Rate">
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Hourly Rate"
                    v-model="newRateModal.data.form.hourlyRate"
                    @change="calcRateAmount('hourlyRate')"
                  ></el-input>
                </el-col>
                <!-- <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Daily Rate"
                    v-model="newRateModal.data.form.dailyRate"
                    @change="calcRateAmount('dailyRate')"></el-input>
                </el-col> -->
              </el-form-item>
              <el-form-item label="Rate Period">
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Start Date"
                    v-model="newRateModal.data.form.startDate"
                    style="width: 100%"
                  ></el-date-picker>
                </el-col>
                <!-- <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="End Date"
                    v-model="newRateModal.data.form.endDate"
                    style="width: 100%"></el-date-picker>
                </el-col> -->
              </el-form-item>
            </el-form>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="addNewRate"
          >
            Submit
          </b-button>
          <b-button
            variant="light"
            size="sm"
            class="float-right"
            @click="toggleNewRateManagementModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="editRateModal.show"
      :hide-header-close="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      :title="editRateModal.data.title"
      :header-bg-variant="editRateModal.headerBgVariant"
      :header-text-variant="editRateModal.headerTextVariant"
      :body-bg-variant="editRateModal.bodyBgVariant"
      :body-text-variant="editRateModal.bodyTextVariant"
      :footer-bg-variant="editRateModal.footerBgVariant"
      :footer-text-variant="editRateModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <el-form
              ref="form"
              :model="editRateModal.data.form"
              label-width="120px"
            >
              <el-form-item label="Rate">
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Hourly Rate"
                    v-model="editRateModal.data.form.hourlyRate"
                    @change="calcRateAmount('hourlyRate', editRateModal)"
                  ></el-input>
                </el-col>
                <!-- <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-input
                    type="number"
                    placeholder="Daily Rate"
                    v-model="editRateModal.data.form.dailyRate"
                    @change="
                      calcRateAmount('dailyRate', editRateModal)
                    "></el-input>
                </el-col> -->
              </el-form-item>
              <el-form-item label="Rate Period">
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="Start Date"
                    v-model="editRateModal.data.form.startDate"
                    style="width: 100%"
                  ></el-date-picker>
                </el-col>
                <!-- <el-col class="line" :span="2"></el-col>
                <el-col :span="11">
                  <el-date-picker
                    type="date"
                    placeholder="End Date"
                    v-model="editRateModal.data.form.endDate"
                    style="width: 100%"></el-date-picker>
                </el-col> -->
              </el-form-item>
            </el-form>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="editRate"
          >
            Update
          </b-button>
          <b-button
            variant="light"
            size="sm"
            class="float-right"
            @click="toggleEditRateManagementModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      size="md"
      v-b-modal.modal-footer-sm
      v-model="allowancesModal.show"
      centered
      :title="allowancesModal.data.title"
      :header-bg-variant="allowancesModal.headerBgVariant"
      :header-text-variant="allowancesModal.headerTextVariant"
      :body-bg-variant="allowancesModal.bodyBgVariant"
      :body-text-variant="allowancesModal.bodyTextVariant"
      :footer-bg-variant="allowancesModal.footerBgVariant"
      :footer-text-variant="allowancesModal.footerTextVariant"
    >
      <b-container fluid>
        <b-row class="mb-1 allowence-table-header">
          <b-col></b-col>
          <b-col>Annual</b-col>
          <b-col>Carry-forward</b-col>
          <b-col>In-lieu</b-col>
          <b-col>Personal</b-col>
        </b-row>
        <b-row
          class="mb-1 allowence-data-row"
          v-for="(alInfo, index) in allowancesModal.data.allowancesData"
          :key="index"
        >
          <b-col class="allowence-table-header text-right">
            {{ alInfo.year }}
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              title="Click to change"
              class="allowence-input"
              @change="UpdateAllowance(alInfo.year)"
              v-model="alInfo.ANNUAL"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              title="Click to change"
              class="allowence-input"
              @change="UpdateAllowance(alInfo.year)"
              v-model="alInfo.CARRYFORWARD"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              title="Click to change"
              class="allowence-input"
              @change="UpdateAllowance(alInfo.year)"
              v-model="alInfo.INLIEU"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              type="number"
              title="Click to change"
              class="allowence-input"
              @change="UpdateAllowance(alInfo.year)"
              v-model="alInfo.PERSONAL"
            ></b-form-input>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="toggleAllowancesModal"
          >
            Close and Update
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-b-modal.modal-footer-sm
      v-model="rateManagementModal.data.showConfirmationPrompt"
      :title="rateManagementModal.data.confirmationPromptTitle"
      :header-bg-variant="rateManagementModal.promptHeaderBgVariant"
      :header-text-variant="rateManagementModal.promptHeaderTextVariant"
      centered
      hide-footer
      size="sm"
    >
      <b-container fluid>
        <div class="row">
          <div class="col-sm-12">
            Are you sure you want to delete this rate?
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p>&nbsp;</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8 text-center">
            <b-button-group size="sm">
              <b-button variant="danger" @click="closeConfirmationPrompt"
                >Cancel</b-button
              >
              <b-button variant="info" @click="deleteRate()">Confirm</b-button>
            </b-button-group>
          </div>
          <div class="col-sm-2"></div>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'
import CreateUser from './Create.vue'
import { format as dateFormat, parseISO } from 'date-fns'

export default {
  name: 'UsersList',
  components: {
    CreateUser
  },
  props: ['listItems', 'clonedItems'],
  data() {
    return {
      q: null,
      disabledRoles: ['superadmin', 'admin', 'partner'], // ['manager', 'admin', 'hr', 'it']
      rolesList: [],
      pager: {
        perPage: 30,
        currentPage: 1
      },
      listFields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left'
        },
        {
          key: 'role',
          label: 'Role',
          sortable: true
        },
        {
          key: 'assignment',
          label: "Today's Assignment",
          sortable: true
        },
        {
          key: 'isCurrentEmployee',
          label: 'Timesheet access'
        },
        {
          key: 'showInStaffing',
          label: 'Show in Staffing'
        },
        // {
        //   key: 'created',
        //   label: 'Joining Date',
        //   sortable: true
        // }
        // {
        //   key: 'dayRate',
        //   label: 'Day Rate'
        // },
        // {
        //   key: 'rates',
        //   label: 'Rates',
        //   sortable: false
        // },
        // {
        //   key: 'totalAnnualLeaves',
        //   label: 'Total Leave Allowance'
        // },
        {
          key: 'birthday',
          label: 'Birthday',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        { key: 'leaves', label: 'Leave Allocations' }
        // {
        //   key: 'lastActivityEntry',
        //   label: 'Last Activity',
        //   sortable: true
        // },
        // {
        //   key: 'inactivityPeriod',
        //   label: 'Inactivity Period',
        //   sortable: true
        // }
      ],
      rateManagementModal: {
        show: false,
        headerBgVariant: 'primary',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        promptHeaderBgVariant: 'danger',
        promptHeaderTextVariant: 'light',
        data: {
          loading: true,
          userId: null,
          rateUid: null,
          listItems: [],
          showConfirmationPrompt: false,
          confirmationPromptTitle: 'Delete Rate',
          promptIsBusy: false
        }
      },
      newRateModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          form: {
            dailyRate: null,
            hourlyRate: null,
            startDate: null,
            endDate: null
          }
        }
      },
      editRateModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          form: {
            rateId: null,
            dailyRate: null,
            hourlyRate: null,
            startDate: null,
            endDate: null
          }
        }
      },
      allowancesModal: {
        show: false,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        bodyBgVariant: 'light',
        bodyTextVariant: 'dark',
        footerBgVariant: 'light',
        footerTextVariant: 'dark',
        data: {
          loading: true,
          uid: null,
          allowancesData: {}
        }
      }
    }
  },
  computed: {
    datePickerOptions() {
      return {
        firstDayOfWeek: 1
      }
    }
  },
  async mounted() {
    try {
      this.$root.$on('bv::hide::prompt', () => {
        this.rateManagementModal.data.showConfirmationPrompt = false
      })
      this.$root.$on('reset-search-user', () => {
        this.q = null
      })
      const { status, data } = await Services.Users.Admin.getRolesList()
      if (status === 200) {
        this.rolesList = data.content.map((itm) => {
          return {
            text: itm.label === 'Not Specified' ? 'N/A' : itm.label,
            value: itm.value.toLowerCase(),
            disabled: this.disabledRoles.includes(itm.value.toLowerCase())
          }
        })
      }
    } catch (Excpetion) {
      console.log('Exception', Excpetion)
    }
  },
  methods: {
    GetList() {
      this.$root.$emit('refresh-user-list')
    },
    changeHourlyRate(ev) {
      this.$root.$emit('hourly-rate-changed', ev.target)
    },
    changeDayRate(ev) {
      this.$root.$emit('daily-rate-changed', ev.target)
    },
    changeCurrentEmployee(itm, key = 'active') {
      this.$root.$emit('current-employee-changed', {
        id: itm.uid,
        checked: !itm.settings[key],
        key
      })
    },
    changeRole(uid) {
      this.$root.$emit('role-changed', {
        id: uid,
        role: this.listItems.find((itm) => itm.uid === uid).role
      })
    },
    isDisabledRole({ role = null }) {
      if (!role) {
        return false
      }
      return this.disabledRoles.includes(role.toLowerCase())
    },
    async getRates(userUid) {
      this.rateManagementModal.data.loading = true
      const { status, data } = await ServicesOld.GetUserRates({
        userUid
      })
      this.rateManagementModal.data.loading = false
      if (status !== 200) {
        return this.toggleRateManagementModal()
      }
      this.rateManagementModal.data.userUid = userUid
      this.rateManagementModal.data.listItems =
        data?.content?.sort((a, b) => {
          return new Date(a.startDate) - new Date(b.startDate)
        }) ?? []
      this.rateManagementModal.data.listItems.map((itm) => {
        itm.startDate = dateFormat(parseISO(itm.startDate), 'dd/MM/yyyy')
        itm.endDate = itm.endDate
          ? dateFormat(parseISO(itm.endDate), 'dd/MM/yyyy')
          : 'Now'
        itm.hourlyRate = '£' + parseFloat(itm.hourlyRate).toFixed(2)
        itm.dayRate = '£' + parseFloat(itm.dayRate).toFixed(2)
        return itm
      })
    },
    getAnnualLeaves(user) {
      const now = new Date()
      let total = 0
      total += user.settings.leave_allowances[now.getFullYear()]?.annual ?? 0
      total +=
        user.settings.leave_allowances[now.getFullYear()]?.carryforward ?? 0
      total += user.settings.leave_allowances[now.getFullYear()]?.inlieu ?? 0
      return total
    },
    async toggleRateManagementModal(user = null) {
      const hasUserObj =
        Object.prototype.toString.call(user).match(/.+?Object\]$/g) !== null
      this.rateManagementModal.data.listItems = []
      if (!this.rateManagementModal.show && hasUserObj) {
        this.rateManagementModal.data.title = 'Loading...'
        await this.getRates(user.uid)
        this.rateManagementModal.data.title = `${user.firstName}'s rates`
      } else {
        this.rateManagementModal.data.title = 'Default'
        if (!this.newRateModal.show) {
          this.rateManagementModal.data.userId = null
          this.rateManagementModal.data.listItems = []
          this.GetList()
        }
      }
      this.rateManagementModal.show = !this.rateManagementModal.show
    },
    async toggleNewRateManagementModal() {
      this.rateManagementModal.show = !this.rateManagementModal.show
      if (!this.newRateModal.show) {
        this.newRateModal.data.loading = true
        this.newRateModal.data.title = 'Loading...'
        this.newRateModal.data.loading = false
        this.newRateModal.data.title = 'New Rate'
      } else {
        this.newRateModal.data.title = 'Default'
      }
      this.newRateModal.show = !this.newRateModal.show
      if (!this.newRateModal.show) {
        for (let key in this.newRateModal.data.form) {
          this.newRateModal.data.form[key] = null
        }
      }
    },
    async toggleEditRateManagementModal(data = {}) {
      this.rateManagementModal.show = !this.rateManagementModal.show
      if (!this.editRateModal.show) {
        this.editRateModal.data.form.rateUid = data.uid
        this.editRateModal.data.form.dailyRate = parseInt(
          data.dayRate.substr(1)
        )
        this.editRateModal.data.form.hourlyRate = parseInt(
          data.hourlyRate.substr(1)
        )
        this.editRateModal.data.form.startDate = data.startDate
        this.editRateModal.data.form.endDate = data.endDate
        this.editRateModal.data.title = 'Edit Rate'
      } else {
        this.editRateModal.data.title = 'Default'
      }
      this.editRateModal.show = !this.editRateModal.show
      if (!this.editRateModal.show) {
        for (let key in this.editRateModal.data.form) {
          this.editRateModal.data.form[key] = null
        }
      }
    },
    async GetHolidayAllowencesByUser({ uid }) {
      const response = await ServicesOld.GetHolidayAllowencesByUser(uid)
      return response.data.content
    },
    async toggleAllowancesModal(owner = null) {
      this.allowancesModal.data.loading = true
      this.allowancesModal.data.uid = null
      this.allowancesModal.data.allowancesData = {}
      if (!this.allowancesModal.show && owner) {
        this.allowancesModal.data.title = 'Loading...'
        this.allowancesModal.data.uid = owner.uid
        const allowances = await this.GetHolidayAllowencesByUser(owner)
        this.allowancesModal.data.allowancesData = []
        const allowancesData = {}
        for (const year in allowances) {
          allowances[year].forEach((al, idx) => {
            if (idx > 1) return
            allowancesData[year] = allowancesData[year] || {
              year
            }
            switch (idx) {
              case 1:
                allowancesData[year].PERSONAL = al.groups.PERSONAL
                break
              default:
                for (const key in al.groups) {
                  allowancesData[year][key] = al.groups[key]
                }
            }
          })
          this.allowancesModal.data.allowancesData =
            Object.values(allowancesData)
        }
        this.allowancesModal.data.title = `${owner.firstName}'s allowances`
      } else {
        this.allowancesModal.data.title = 'Default'
      }
      this.allowancesModal.data.loading = false
      this.allowancesModal.show = !this.allowancesModal.show
      if (!this.allowancesModal.show) {
        this.$root.$emit('refresh-user-list')
      }
    },
    async UpdateAllowance(year) {
      const { allowancesData, uid } = this.allowancesModal.data
      let leaveAllowances = {}
      allowancesData.forEach((alInfo) => {
        leaveAllowances[alInfo.year] = {
          annual: Number(alInfo.ANNUAL),
          carryforward: Number(alInfo.CARRYFORWARD),
          inlieu: Number(alInfo.INLIEU),
          personal: Number(alInfo.PERSONAL)
        }
      })
      await Services.Users.Admin.updateUser({
        uid,
        data: {
          leaveAllowances
        }
      })
      // const allowances = await this.GetHolidayAllowencesByUser({ uid })
      // this.allowancesModal.data.allowancesData = []
      // for (const year in allowances) {
      //   allowances[year][0].groups.year = year
      //   this.allowancesModal.data.allowancesData.push(
      //     allowances[year][0].groups
      //   )
      // }
    },
    calcRateAmount(key, Modal = this.newRateModal) {
      const val = Modal.data.form[key]
      const target = key === 'dailyRate' ? 'hourlyRate' : 'dailyRate'
      if (target === 'dailyRate') {
        Modal.data.form[target] = parseInt(val * 8)
      } else {
        Modal.data.form[target] = parseInt(val / 8)
      }
    },
    async addNewRate() {
      this.calcRateAmount('dailyRate')
      if (this.newRateModal.data.form.startDate === null) {
        return
      }
      const objToSend = {
        dayRate: this.newRateModal.data.form.dailyRate,
        hourlyRate: this.newRateModal.data.form.hourlyRate,
        startDate: this.$moment(this.newRateModal.data.form.startDate).format(
          'YYYY-MM-DD'
        ),
        endDate:
          this.newRateModal.data.form.endDate === null
            ? null
            : this.$moment(this.newRateModal.data.form.endDate).format(
                'YYYY-MM-DD'
              )
      }
      const { status } = await ServicesOld.AddNewUserRate(
        this.rateManagementModal.data.userUid,
        objToSend
      )
      if (status === 200) {
        this.toggleNewRateManagementModal()
        await this.getRates(this.rateManagementModal.data.userUid)
      }
    },
    async editRate() {
      this.calcRateAmount('dailyRate', this.editRateModal)
      if (this.editRateModal.data.form.startDate === null) {
        return
      }
      const objToSend = {
        dayRate: this.editRateModal.data.form.dailyRate,
        hourlyRate: this.editRateModal.data.form.hourlyRate,
        startDate: this.$moment(this.editRateModal.data.form.startDate).format(
          'YYYY-MM-DD'
        ),
        endDate:
          this.editRateModal.data.form.endDate === null
            ? null
            : this.$moment(this.editRateModal.data.form.endDate).format(
                'YYYY-MM-DD'
              )
      }
      const { status } = await ServicesOld.UpdateNewUserRate(
        this.editRateModal.data.form.rateUid,
        objToSend
      )
      if (status === 200) {
        this.toggleEditRateManagementModal()
        await this.getRates(this.rateManagementModal.data.userUid)
      }
    },
    async deleteRate(
      uid = this.rateManagementModal.data.rateUid,
      showPrompt = false
    ) {
      if (showPrompt) {
        // show an confirmation
        this.rateManagementModal.data.rateUid = uid
        this.rateManagementModal.data.showConfirmationPrompt = true
      } else {
        const { status } = await ServicesOld.DeleteUserRate(uid)
        if (status === 200) {
          await this.getRates(this.rateManagementModal.data.userUid)
        }
        this.closeConfirmationPrompt()
      }
    },
    formatBirthday(birthday) {
      const formatted = dateFormat(parseISO(birthday), 'dd/MM/yyyy')
      const today = dateFormat(new Date(), 'dd/MM')

      return new RegExp(`^${today}`, 'gi').test(formatted)
        ? 'TODAY!'
        : formatted
    },
    async modifyBirthday({ uid, meta: { birthday } }) {
      await Services.Users.Admin.updateUser({
        uid,
        data: {
          birthday
        }
      })
    },
    closeConfirmationPrompt() {
      this.rateManagementModal.data.rateUid = null
      this.rateManagementModal.data.showConfirmationPrompt = false
    },
    getActiveBtnVariant(itm, key = 'active') {
      return itm.settings[key] ? 'success' : 'secondary'
    },
    isWFA(holidayType) {
      const wfaRegEx = new RegExp(/^WFH.+/i)
      return wfaRegEx.test(holidayType)
    }
  },
  watch: {
    q: {
      handler(q) {
        this.$root.$emit('search-user', q)
      },
      deep: true
    }
  }
}
</script>

<style>
.lizard-user-active-btn {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-user-active-btn:hover {
  cursor: pointer;
  color: #eee !important;
}
.lizard-user-active-btn:active {
  color: #ccc !important;
}
.lizard-user-active-btn-disabled {
  margin: 0;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
  cursor: 'not-allowed';
  opacity: 0.5;
}
.lizard-employeeRole {
  font-size: 9pt !important;
  font-weight: bold;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  width: 30px !important;
  text-align: center !important;
}
.lizard-column-active {
  width: 50px !important;
  text-align: center !important;
}
.lizard-column-status {
  width: 80px !important;
  text-align: center !important;
}
.lizard-column-actions {
  text-align: center !important;
}
.lizard-column-actions .manage-rates-btn {
  margin: 0;
  font-size: 8pt !important;
  line-height: 20px !important;
  min-width: 60px !important;
  border-radius: 3px !important;
}
.lizard-column-hrate {
  width: 85px !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.lizard-header-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell {
  font-size: 8pt !important;
  font-weight: bold;
}
.lizard-row-cell:hover {
  background-color: #eee;
}

.allowence-table-header {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #999 !important;
  text-align: center;
}
.allowence-data-row {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  text-align: center;
  min-height: 40px;
  line-height: 40px;
}
.allowence-data-row .allowence-input {
  border: 0;
  background: transparent;
  font-size: 12px !important;
  font-weight: bold !important;
  color: #666 !important;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.allowence-data-row:hover {
  background-color: rgba(102, 204, 255, 0.1);
}
</style>

<style scoped>
span.user-assignments {
  margin: 0;
  padding: 0;
}
span.user-assignments div h6 {
  font-size: 1.1em;
  vertical-align: middle;
}
span.user-assignments div h6 > .badge {
  vertical-align: middle;
  margin-top: 0.7em;
  text-shadow: none !important;
}
.stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #93c 10px,
      #93c 20px
    ),
    linear-gradient(to bottom, #c39, #93c);
}
.wfa-stripe-bg {
  background: repeating-linear-gradient(
      25deg,
      transparent,
      transparent 10px,
      #400d01 10px,
      #400d01 20px
    ),
    linear-gradient(to bottom, #592202, #400d01);
}
</style>
