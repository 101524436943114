<template>
  <b-container fluid>
    <div class="row" style="background-color: rgba(255, 255, 255, 0.5)">
      <div class="col-sm-6">&nbsp;</div>
      <div class="col-sm-6 text-right roles-holder">
        <h6>
          <ul>
            <li @click="toggleAllRoles">
              <b-badge
                :style="{
                  'background-color': roleColors['ALL'].color
                }"
                >All Roles</b-badge
              >
            </li>
            <li
              v-for="(role, index) in this.UserRoles"
              :key="role.value"
              @click="selectRole(role.value, index)"
            >
              <b-badge
                :style="{
                  'background-color': roleColors[role.value.toUpperCase()].color
                }"
                >{{ role.label }}</b-badge
              >
            </li>
          </ul>
        </h6>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'

export default {
  name: 'RolesSelector',
  computed: {
    Store() {
      return this.$store.getters
    },
    UserRoles() {
      return this.Store.staffingUserRoles
    },
    SelectedUserRoles() {
      return this.Store.staffingSelectedUserRoles
    },
    Users() {
      return this.Store.staffingUsers
    }
  },
  data() {
    return {
      allRolesSelected: false,
      roleColors: {
        ALL: { color: '', default: '#CC0033' },
        MANAGER: { color: '', default: '#1C3D59' },
        ASSOCIATE: { color: '', default: '#3AC0EE' },
        ANALYST: { color: '', default: '#BF871F' },
        FTINTERN: { color: '', default: '#170126' }
        // SUPPORT: { color: '', default: '#8C470B' }
      },
      rolesToHide: [
        'ADMIN',
        'PARTNER',
        'PTINTERN',
        'EXPERT',
        'CONTRACTOR',
        'SUPPORT'
      ]
    }
  },
  async mounted() {
    // this.$root.$on('parent_tab_staffing_selected', this.tabMounted)
    this.tabMounted()
    // await this.selectRole('MANAGER')
  },
  methods: {
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    async tabMounted() {
      await this.getUserRoles()
      await this.toggleAllRoles(true)
    },
    async getUserRoles() {
      try {
        if (this.UserRoles.length) return
        const { status = 500, data = {} } = await ServicesOld.GetUserRolesList()
        this.commitToStore(
          'setStaffingUserRoles',
          status == 200
            ? data.content
                .filter((itm) => {
                  return !this.rolesToHide.includes(itm.value.toUpperCase())
                })
                .map(({ label, value }, index) => {
                  return {
                    label,
                    value,
                    color: this.roleColors[value.toUpperCase()].color
                  }
                })
            : []
        )
      } catch (Exception) {
        console.log(Exception)
      }
    },
    async getUsers() {
      if (this.SelectedUserRoles.filter((r) => r !== null).length === 0) {
        this.commitToStore('setStaffingUsers', [])
      }
      if (this.Users.length) return
      const { status, data } = await Services.Users.getUsersByRole(
        this.SelectedUserRoles.join(',').trim()
      )
      this.commitToStore(
        'setStaffingUsers',
        status == 200
          ? data.content
              .filter((itm) => {
                return !this.rolesToHide.includes(itm.role.toUpperCase())
              })
              .map(({ uid, firstName, lastName, role }) => {
                return {
                  uid: uid,
                  name: `${firstName} ${lastName}`,
                  role,
                  color: this.roleColors[role.toUpperCase()]?.color ?? '#000'
                }
              })
              .sort((a, b) => {
                if (
                  this.SelectedUserRoles.indexOf(a.role.toUpperCase()) <
                  this.SelectedUserRoles.indexOf(b.role.toUpperCase())
                )
                  return -1
                if (
                  this.SelectedUserRoles.indexOf(a.role.toUpperCase()) >
                  this.SelectedUserRoles.indexOf(b.role.toUpperCase())
                )
                  return 1
                return 0
              })
          : []
      )
    },
    async selectRole(role, index = 0) {
      console.log({ role, index })
      const selectedRoles = [...this.SelectedUserRoles]
      selectedRoles[index] = !this.SelectedUserRoles.filter(
        (r) => r !== null
      ).includes(role)
        ? role
        : null
      this.$store.commit('setStaffingSelectedRoles', selectedRoles)
      this.roleColors[role.toUpperCase()].color = selectedRoles[index]
        ? this.roleColors[role.toUpperCase()].default
        : ''
      if (selectedRoles.includes(null)) {
        this.allRolesSelected = false
        this.roleColors.ALL.color = ''
      } else {
        this.allRolesSelected = true
        this.roleColors.ALL.color = this.roleColors.ALL.default
      }
      await this.getUsers()
    },
    async toggleAllRoles(force = null) {
      this.allRolesSelected = force != null ? force : !this.allRolesSelected
      this.roleColors.ALL.color = this.allRolesSelected
        ? this.roleColors.ALL.default
        : ''
      if (this.allRolesSelected) {
        this.$store.commit(
          'setStaffingSelectedRoles',
          this.UserRoles.map((r) => r.value)
        )
        for (const role in this.roleColors) {
          if (role !== 'ALL') {
            this.roleColors[role].color = this.roleColors[role].default
          }
        }
      } else {
        this.$store.commit(
          'setStaffingSelectedRoles',
          new Array(Object.keys(this.roleColors).length - 1).fill(null)
        )
        for (const role in this.roleColors) {
          if (role !== 'ALL') {
            this.roleColors[role].color = ''
          }
        }
      }
      await this.getUsers()
    }
  }
}
</script>

<style scoped>
.roles-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.roles-holder ul li {
  display: inline-block;
  margin: 0;
  padding: 0;
}
.roles-holder ul li .badge {
  border-radius: 0;
  padding: 10px;
  background-color: #ccc;
}
.roles-holder ul li .badge.selected {
  background-color: #333;
}
.roles-holder ul li .badge:hover {
  cursor: pointer;
  background-color: #333;
}
.roles-holder ul li .badge:active {
  background-color: #000;
  color: #ccc;
}
</style>
