<template>
  <b-container fluid>
    <div class="row" v-if="list.length">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :busy="loading"
          :items="list"
          :fields="fields"
        >
          <template #table-caption>Statistics</template>
          <template #cell(payableAmount)="data">
            <span v-if="showSensitiveData"
              >£{{ numberWithCommas(data.item.payableAmount) || 0 }}</span
            >
            <span v-else>
              <h6>
                <b-badge variant="light" style="color: #999">***</b-badge>
              </h6>
            </span>
          </template>
          <template #cell(exportExcel)="">
            <download-excel
              :before-generate="generateExcel"
              :before-finish="resetExportExcelOpts"
              :type="exportExcel.stats.main.type"
              :name="exportExcel.stats.main.name"
              :fields="exportExcel.stats.main.fields"
              :data="exportExcel.stats.main.data"
              :worksheet="exportExcel.stats.main.worksheet"
            >
              <b-button variant="outline-success" size="sm"
                ><b-icon
                  icon="file-earmark-excel-fill"
                  title="Export Excel"
                ></b-icon>
                Export Excel</b-button
              >
            </download-excel>
          </template>
          <template #cell(exportExcel2)="">
            <download-excel
              v-if="exportExcel2.stats.main.data.length"
              :before-generate="generateExcel2"
              :before-finish="resetExportExcelOpts2"
              :type="exportExcel2.stats.main.type"
              :name="exportExcel2.stats.main.name"
              :fields="exportExcel2.stats.main.fields"
              :data="exportExcel2.stats.main.data"
              :worksheet="exportExcel2.stats.main.worksheet"
            >
              <b-button variant="outline-success" size="sm"
                ><b-icon
                  icon="file-earmark-excel-fill"
                  title="Export Excel"
                ></b-icon>
                Timesheet Report</b-button
              >
            </download-excel>
          </template>
        </b-table>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'TimelogStatsComponent',
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    list: {
      type: Array,
      required: true
    },
    ctreport: {
      type: Array,
      required: false
    },
    showSensitiveData: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'totalHours',
          label: 'Total Hours',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'calendarDays',
          label: 'Calender Days',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'actualDays',
          label: 'Actual Days',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'billableDays',
          label: 'Billable Days',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'payableAmount',
          label: 'Total Cost',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'exportExcel',
          label: '',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'exportExcel2',
          label: '',
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ],
      exportExcel: {
        stats: {
          main: {
            type: 'xls',
            name: 'reports-stats.xls',
            worksheet: "Candesic's Report Stats",
            fields: {
              'Total Hours': 'totalHours',
              'Calendar Days': 'calendarDays',
              'Actual Days': 'actualDays',
              'Billable Days': 'billableDays'
            },
            data: []
          }
        }
      },
      exportExcel2: {
        stats: {
          main: {
            type: 'xls',
            name: 'ct-reports-stats.xls',
            worksheet: 'Timesheet Report',
            fields: {
              Title: 'title',
              'Project code': 'projectCode',
              'Total hours': 'totalHours',
              'Calendar days': 'calendarDays',
              'Actual days': 'actualDays',
              'Billable days': 'billableDays',
              'Blake Edwards': 'Blake Edwards',
              'Campbell Foubister': 'Campbell Foubister',
              'Chris Nasseh': 'Chris Nasseh',
              'Clemmie Shires': 'Clemmie Shires',
              'Denis Tomanov': 'Denis Tomanov',
              'Dimitar Funa': 'Dimitar Funa',
              'Fabio Ruffinoni': 'Fabio Ruffinoni',
              'Floris Wentholt': 'Floris Wentholt',
              'Hassan Traboulsi': 'Hassan Traboulsi',
              'Iham Kasem': 'Iham Kasem',
              'Jack Zeng': 'Jack Zeng',
              'Javier Garcia-Perez': 'Javier Garcia-Perez',
              'Jawad Barbar': 'Jawad Barbar',
              'Kristoffer Kentta': 'Kristoffer Kentta',
              'Magdalena Langosch': 'Magdalena Langosch',
              'Marco Mazevet': 'Marco Mazevet',
              'Mario El Asmar': 'Mario El Asmar',
              'Mathis Busse': 'Mathis Busse',
              'Niam Arora': 'Niam Arora',
              'Nicolas Correa': 'Nicolas Correa',
              'Phaidon Theologites': 'Phaidon Theologites',
              'Siddhant Tandon': 'Siddhant Tandon',
              'Swarag Tadepally': 'Swarag Tadepally',
              'Teresa Pla Prats': 'Teresa Pla Prats',
              'Zachary Hui': 'Zachary Hui',
              'Zeynep Kubac': 'Zeynep Kubac'
            },
            data: []
          }
        }
      }
    }
  },
  methods: {
    numberWithCommas(x) {
      const parsed = parseFloat(x || 0).toFixed(2)
      return parseFloat(parsed).toLocaleString()
    },
    generateExcel() {
      this.exportExcel.stats.main.data = this.list
    },
    resetExportExcelOpts() {
      this.exportExcel.stats.main.data = []
    },
    generateExcel2() {
      this.exportExcel2.stats.main.data = this.ctreport
    },
    resetExportExcelOpts2() {
      this.exportExcel2.stats.main.data = []
    }
  }
}
</script>
