<template>
  <div class="row">
    <div class="col-lg">
      <div class="row">
        <div class="col-sm-3 text-right">
          <label for="" class="h6"
            >Amount <b-icon icon="info-circle-fill" title="exclude VAT"></b-icon
          ></label>
        </div>
        <div
          class="col-sm-3"
          style="margin: 0 !important; padding-right: 2px !important">
          <input
            type="number"
            class="form-control lizard-form-duration txt"
            style="text-align: center"
            :placeholder="amount"
            :value="amount"
            id="amountVal"
            title="Popover title"
            max="9999.99"
            @change="amountChanged" />
          <b-popover
            target="amountVal"
            triggers="hover focus"
            placement="bottom"
            content="Amount (excl. VAT)"></b-popover>
        </div>
        <div
          class="col-sm-5 gbp-rate"
          style="margin: 0 !important; padding-left: 2px !important">
          <CurrencySelector />
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencySelector from './CurrencySelector.vue'

export default {
  name: 'AmountSelector',
  components: { CurrencySelector },
  data() {
    return {
      approxGBPRate: '',
      gbpRates: { eur: 0, usd: 0 }
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    amount() {
      return this.FormData.amount
    },
    vat() {
      return this.FormData.vat
    },
    currency() {
      return this.FormData.currency
    }
  },
  // watch: {
  //   amount: {
  //     handler: 'amountChanged',
  //     immediate: true
  //   },
  //   currency: {
  //     handler: 'calcRates',
  //     immediate: true
  //   }
  // },
  // mounted() {
  //   // this.gbpRates = this.$cookies.get('__gbp-rates') || this.gbpRates
  //   // this.calcRates()
  // },
  methods: {
    amountChanged(ev) {
      const amount = ev?.target?.value ?? this.amount
      if (amount != this.amount) {
        this.$store.commit('setFormDataAmount', amount)
      }
    }
  }
}
</script>

<style scoped>
.amount-cell {
  padding-right: 5px;
}
.gbp-rate {
  padding: 0;
  margin: 0 !important;
}
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: left;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
