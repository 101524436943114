<script>
exports.UsersService = (api) => {
  const basePath = '/users'
  const adminPath = `/admin${basePath}`

  const handleError = (error) => {
    if (typeof error === 'string') {
      throw new Error(error)
    } else {
      throw error
    }
  }

  const authenticateUser = async (data) => {
    try {
      return await api.post(`${basePath}/auth`, data)
    } catch (error) {
      handleError('Failed to authenticate user')
    }
  }

  const fetchCurrentUser = async () => {
    try {
      return await api.get(`${basePath}/me`)
    } catch (error) {
      handleError('Failed to fetch current user')
    }
  }

  const getUsersByRole = async (role = null) => {
    try {
      return await api.get(`${basePath}/roles/${role}`)
    } catch (error) {
      handleError('Failed to fetch users by role')
    }
  }

  const signOut = async () => {
    try {
      return await api.post(`${basePath}/signout`)
    } catch (error) {
      handleError('Failed to sign out')
    }
  }

  const createUser = async ({ firstName, lastName, email, role }) => {
    try {
      return await api.post(adminPath, {
        firstName,
        lastName,
        email,
        role
      })
    } catch (error) {
      handleError(error)
    }
  }

  const getUsersForAdmin = async (role = null) => {
    try {
      return await api.get(`${adminPath}/roles/${role}`)
    } catch (error) {
      handleError('Failed to fetch users for admin')
    }
  }

  const getList = async () => {
    try {
      return await api.get(`${adminPath}/list`)
    } catch (error) {
      handleError('Failed to fetch list')
    }
  }

  const getRolesList = async () => {
    try {
      return await api.get(`${adminPath}/roles-list`)
    } catch (error) {
      handleError('Failed to fetch roles list')
    }
  }

  const getReports = async ({
    projectUid,
    userUid,
    from = '2019-08-03',
    to = Date.now()
  }) => {
    try {
      const queryParams = []
      if (projectUid) {
        queryParams.push(`project=${projectUid}`)
      }
      if (userUid) {
        queryParams.push(`user=${userUid}`)
      }
      const queryString = queryParams.length ? '?' + queryParams.join('&') : ''
      return await api.get(`${adminPath}/reports/${from}/${to}${queryString}`)
    } catch (error) {
      handleError('Failed to fetch reports')
    }
  }

  const updateUser = async ({ uid, data }) => {
    try {
      const toUpdate = {}
      for (let key in data) {
        switch (key) {
          case 'role':
          case 'birthday':
            toUpdate[key] = data[key]
            break
          case 'active':
          case 'staffing':
          case 'leave_allowances':
            toUpdate.settings[key] = data.settings[key]
            break
        }
      }
      return await api.patch(`${adminPath}/uid/${uid}`, toUpdate)
    } catch (error) {
      console.log(error)
      handleError('Failed to update user')
    }
  }

  return {
    authenticateUser,
    fetchCurrentUser,
    getUsersByRole,
    signOut,
    Admin: {
      createUser,
      getUsersForAdmin,
      getList,
      getRolesList,
      getReports,
      updateUser
    }
  }
}
</script>
