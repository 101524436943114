<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Days</label></div>
    <div class="col-sm-7">
      <b-form-input
        type="number"
        class="form-control txt"
        placeholder="No of days requested"
        min="0.5"
        max="30"
        step="1"
        readonly
        disabled
        :value="daysRequested"
        v-model="totalDaysRequested"
        @change="daysRequestedChanged"></b-form-input>
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
const { differenceInBusinessDays } = require('date-fns')

export default {
  name: 'DaysRequestedSelector',
  data() {
    return {
      totalDaysRequested: 0
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    dates() {
      return this.FormData.dates
    },
    daysRequested() {
      return this.FormData.totalDays
    },
    totalBankHolidaysInDates() {
      return this.FormData.totalBankHolidaysInDates
    },
    selectedHolidayType() {
      return this.FormData.holidayType
    }
  },
  mounted() {
    this.totalDaysRequested = this.daysRequested || 1
  },
  methods: {
    daysRequestedChanged(val) {
      this.$store.commit('modifyFormData', {
        key: 'totalDays',
        value: parseFloat(val)
      })
    },
    datesChanged() {
      this.$store.commit('modifyFormData', {
        key: 'totalDays',
        value: this.calcDaysRequested()
      })
      this.totalDaysRequested = this.daysRequested
    },
    calcDaysRequested() {
      if (!this.dates) {
        return 0
      }
      let diff = ['HALFDAY', 'HALFPERSONAL'].includes(this.selectedHolidayType)
        ? 0.5
        : differenceInBusinessDays(
            new Date(this.dates[1] || this.dates[0]),
            new Date(this.dates[0])
          ) + 1
      return diff - this.totalBankHolidaysInDates < 0
        ? 0
        : diff - this.totalBankHolidaysInDates
    }
  },
  watch: {
    dates: {
      handler: 'datesChanged',
      immediate: true
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.file-txt {
  color: #666;
  font-weight: bold !important;
  font-size: 8pt !important;
}
</style>
