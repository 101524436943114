<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-10"></div>
      <div class="col-lg">
        <b-button
          :id="btn.id"
          :variant="btn.variant"
          size="md"
          @click="toggleModal">
          {{ btn.text }}
        </b-button>
      </div>
    </div>
    <b-modal
      centered
      no-close-on-esc
      button-size="sm"
      :ref="modal.id"
      :header-bg-variant="modal.styles.headerBgVariant"
      :header-text-variant="modal.styles.headerTextVariant"
      :body-bg-variant="modal.styles.bodyBgVariant"
      :body-text-variant="modal.styles.bodyTextVariant"
      :footer-bg-variant="modal.styles.footerBgVariant"
      :footer-text-variant="modal.styles.footerTextVariant">
      <template #modal-title>{{ modal.title }}</template>
      <div class="d-block text-center">
        <b-form>
          <b-form-row class="mb-1">
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <b-form-group id="input-group-1" label="Email:" label-for="email">
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-input
                id="email"
                size="sm"
                v-model="modal.data.email"
                type="email"
                placeholder="Enter candesic email address"
                required></b-form-input
            ></b-col>
            <b-col cols="1"></b-col>
          </b-form-row>
          <b-form-row class="mb-1">
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <b-form-group
                id="input-group-2"
                label="Name:"
                label-for="firstName">
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-input
                id="firstName"
                size="sm"
                v-model="modal.data.firstName"
                type="text"
                placeholder="Enter first name"
                required></b-form-input
            ></b-col>
            <b-col cols="4">
              <b-form-input
                id="lastName"
                size="sm"
                v-model="modal.data.lastName"
                type="text"
                placeholder="Enter last name"
                required></b-form-input
            ></b-col>
            <b-col cols="1"></b-col>
          </b-form-row>
          <b-form-row class="mb-1">
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <b-form-group id="input-group-3" label="Role:" label-for="role">
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-select
                id="role"
                size="sm"
                v-model="modal.data.role"
                :options="roles"
                required>
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select a role</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="1"></b-col>
          </b-form-row>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button
          :disabled="modal.loading"
          size="sm"
          variant="info"
          @click="addNewUser()">
          {{ modal.submitBtnText }}
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Services from '../../../services/main.service.vue'

export default {
  name: 'CreateUser',
  props: ['roles'],
  data() {
    return {
      btn: {
        id: 'add-user-btn',
        text: 'Add New User',
        variant: 'info'
      },
      modal: {
        id: 'add-user-modal',
        title: 'Add New User',
        submitBtnText: 'Submit',
        loading: false,
        styles: {
          headerBgVariant: 'info',
          headerTextVariant: 'light',
          bodyBgVariant: 'light',
          bodyTextVariant: 'dark',
          footerBgVariant: 'light',
          footerTextVariant: 'dark'
        },
        data: {
          email: '',
          firstName: '',
          lastName: '',
          role: null
        }
      }
    }
  },
  methods: {
    toggleModal() {
      this.$refs[this.modal.id].toggle('#' + this.btn.id)
    },
    resetData() {
      this.modal.data.email = ''
      this.modal.data.firstName = ''
      this.modal.data.lastName = ''
      this.modal.data.role = null
    },
    addNewUser() {
      if (this.modal.loading) {
        return
      }
      let formIsValid = true
      formIsValid = formIsValid && this.modal.data.email.trim() !== ''
      formIsValid = formIsValid && this.modal.data.firstName.trim() !== ''
      formIsValid = formIsValid && this.modal.data.lastName.trim() !== ''
      formIsValid = formIsValid && this.modal.data.role !== null
      if (!formIsValid) {
        return
      }
      this.modal.loading = true
      this.modal.submitBtnText = 'Please wait...'
      Services.Users.Admin.createUser(this.modal.data)
        .then(() => {
          this.modal.loading = false
          this.modal.submitBtnText = 'Submit'
          this.resetData()
          this.$root.$emit('refresh-user-list')
          this.$refs[this.modal.id].hide('#' + this.btn.id)
        })
        .catch((error) => {
          const { code, details, status } = error?.response?.data?.error ?? {
            code: 'UNKNOWN',
            details: {},
            status: 500
          }
          if (code === 'VALIDATION_ERROR' && status === 400) {
            this.$toasted
              .error(details.validationErrors[0].message)
              .goAway(5000)
          }
          this.modal.loading = false
          this.modal.submitBtnText = 'Submit'
        })
    }
  }
}
</script>
