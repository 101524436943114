<script>
import VueCookies from 'vue-cookies'
import axios from 'axios'

import { UsersService } from './users.service.vue'
import { ProjectsService } from './projects.service.vue'
import { ActivitiesService } from './activities.service.vue'

const createApiInstance = (baseURL) => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const setAuthToken = (api, token = VueCookies.get('__api-token')) => {
  if (!token) {
    return
  }
  api.defaults.headers['Authorization'] = `Bearer ${token}`
}

const setCustomHeader = (api, key, value) => {
  api.defaults.headers[key] = value
}

const deleteCache = () => {
  caches.delete(location.pathname)
  localStorage.clear()
  sessionStorage.clear()
  // location.reload()
}

const deleteCookies = () => {
  VueCookies.remove('__api-token')
  VueCookies.remove('__auth-data')
}

const checkServiceHealth = async (api) => {
  try {
    let url = '/health'
    if (location.hash) {
      url = `${url}?code=${location.hash.split('=')[1]}`
    }
    return await api.get(url)
  } catch (error) {
    console.log({ error })
    throw new Error('Failed to check service health')
  }
}

const MainService = () => {
  const api = createApiInstance(process.env.VUE_APP_API_BASE_URL_V2)

  api.interceptors.request.use(
    (config) => {
      setAuthToken({ ...api })
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use((response) => {
    if (response.status === 200) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  })

  return {
    ResponseInterceptors: api.interceptors.response,
    deleteCache,
    deleteCookies,
    checkServiceHealth: () => checkServiceHealth({ ...api }),
    Users: UsersService(api),
    Projects: ProjectsService(api),
    Activities: ActivitiesService(api)
  }
}

export default MainService()
</script>
