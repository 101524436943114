<template>
  <b-container
    fluid
    class="welcome-user"
    :class="hasError ? 'error-banner' : 'ok-banner'"
  >
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!mobileView">
      <div
        class="col-sm-12 text-left header-msg"
        v-if="IsAuthenticated && !hasError"
      >
        <div class="row">
          <div class="col-lg-10 text-left">
            <h4>
              <strong v-if="UserData.details.role != 'not_specified'">{{
                UserData.details.firstName + ' ' + UserData.details.lastName
              }}</strong>
              <strong v-else>Welcome!</strong>
            </h4>
          </div>
          <div class="col-lg-2 text-right">
            <b-badge
              v-if="UserData.details.role != 'not_specified'"
              variant="primary"
              style="text-transform: uppercase"
              >{{ UserData.details.role }}</b-badge
            >
          </div>
        </div>
      </div>
      <div class="col-sm-12 header-msg text-left" v-else>
        <h4>
          <strong
            >Your current session has expired. Please refresh the page and sign
            in again.</strong
          >
        </h4>
      </div>
    </div>
    <div class="row" v-if="mobileView">
      <div
        class="col-sm-12 text-left header-msg"
        v-if="IsAuthenticated && !hasError"
      >
        <div class="row">
          <div class="col-lg-12 text-left">
            <strong v-if="UserData.details.role != 'not_specified'">{{
              UserData.details.firstName + ' ' + UserData.details.lastName
            }}</strong>
            <strong v-else>Welcome!</strong>
          </div>
        </div>
      </div>
      <div class="col-sm-12 header-msg text-left" v-else>
        <strong
          >Your current session has expired. Please refresh the page and sign in
          again.</strong
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'WelcomeUser',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hasError: false
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    UserData() {
      return this.Store.userAuth
    },
    IsAuthenticated() {
      return this.UserData.authenticated
    }
  },
  watch: {
    IsAuthenticated() {
      this.hasError = !this.IsAuthenticated
    }
  }
}
</script>

<style scoped>
.welcome-user {
  border: 1px solid #f0f0f0;
  border-left-width: 0px;
  border-right-width: 0px;
}
.welcome-user.error-banner {
  background-color: #bc0707 !important;
}
.welcome-user.ok-banner {
  background-color: #23b1f3 !important;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  color: #f3f3f3;
  /* color: #0773BC; */
  font-weight: bold !important;
}
.header-msg h4 {
  margin: 0;
}
</style>
