<template>
  <b-container>
    <div class="row">
      <div class="col-lg-12 text-left">
        <b-form-radio-group
          class="allowance-year-group"
          v-model="selectedYear"
          :options="years"
          button-variant="outline-secondary"
          name="allowance-years"
          buttons
        ></b-form-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <b-table-lite
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-stats-box"
          thead-class="label"
          tbody-class="value"
          :items="statItems[selectedYear]"
          :fields="statFields"
        >
          <template #cell(type)="data">
            {{ data.item.value.toUpperCase() }}
          </template>
          <template #cell(allowance)="data">
            <b-form-input
              type="number"
              size="sm"
              v-if="data.item.key == 'ANNUAL' || data.item.key == 'PERSONAL'"
              class="text-center allowance-input"
              :data-group="data.item.key"
              :value="
                data.item.groups[data.item.key] || data.item.allowance || ''
              "
              @blur="updateAllowance"
            ></b-form-input>
          </template>
          <template #cell(carryForward)="data">
            <span v-if="data.item.key == 'ANNUAL'">
              <b-form-input
                type="number"
                size="sm"
                class="text-center allowance-input"
                data-group="CARRYFORWARD"
                :value="data.item.groups.CARRYFORWARD || ''"
                @blur="updateAllowance"
              ></b-form-input>
            </span>
          </template>
          <template #cell(inLieu)="data">
            <span v-if="data.item.key == 'ANNUAL'">
              <b-form-input
                type="number"
                size="sm"
                class="text-center allowance-input"
                data-group="INLIEU"
                :value="data.item.groups.INLIEU || ''"
                @blur="updateAllowance"
              ></b-form-input>
            </span>
          </template>
          <template #cell(balance)="data">
            {{ data.item.balance }}
          </template>
        </b-table-lite>
      </div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../../services/main.service.vue'

export default {
  name: 'HolidayStats',
  props: ['statsData'],
  data: () => {
    return {
      statFields: [
        {
          key: 'type',
          label: 'Leave Type',
          thClass: 'w40 aleft',
          tdClass: 'w40 aleft'
        },
        {
          key: 'allowance',
          label: 'Allowance',
          thClass: 'w10',
          tdClass: 'w10'
        },
        {
          key: 'carryForward',
          label: 'Carry-forward',
          thClass: 'w10',
          tdClass: 'w10'
        },
        {
          key: 'inLieu',
          label: 'In-lieu',
          thClass: 'w10',
          tdClass: 'w10'
        },
        {
          key: 'pending',
          label: 'Pending',
          thClass: 'w10',
          tdClass: 'w10'
        },
        {
          key: 'approved',
          label: 'Approved',
          thClass: 'w10',
          tdClass: 'w10'
        },
        {
          key: 'balance',
          label: 'Balance',
          thClass: 'w10',
          tdClass: 'w10'
        }
      ],
      statItems: {},
      selectedYear: null,
      years: [],
      leaveAllowances: {}
    }
  },
  mounted() {
    const now = new Date()
    this.selectedYear = now.getFullYear()
    this.sortStats()
    this.setLeaveAllowance()
  },
  methods: {
    sortStats() {
      const TypesToHide = ['ANNUALSALE']
      const { years, allowance } = this.statsData
      this.years = years
      years.forEach((year) => {
        this.statItems[year] = allowance[year]
          .map((itm) => {
            itm.balance = ''
            if (itm.value.toLowerCase() == 'personal') {
              itm.value = 'Personal/Sick'
              itm.groups[itm.key] = ''
              // itm.balance = itm.remains
            }
            if (
              ['annual holiday', 'personal/sick'].includes(
                itm.value.toLowerCase()
              )
            ) {
              itm.balance = itm.remains
            }
            itm.pending = itm.pending || ''
            itm.approved = itm.approved || ''
            return itm
          })
          .filter((itm) => {
            return !TypesToHide.includes(itm.key)
          })
      })
    },
    setLeaveAllowance() {
      this.leaveAllowances = {}
      for (const year in this.statItems) {
        this.leaveAllowances[year] = {
          annual: Number(this.statItems[year][0].groups.ANNUAL),
          carryforward: Number(this.statItems[year][0].groups.CARRYFORWARD),
          inlieu: Number(this.statItems[year][0].groups.INLIEU),
          personal: this.statItems[year][1].allowance
        }
      }
    },
    updateAllowance(ev) {
      const {
        value,
        dataset: { group }
      } = ev.target
      const user = this.statsData.user
      this.leaveAllowances[this.selectedYear][group.toLowerCase()] = Number(
        value || 0
      )
      Services.Users.Admin.updateUser({
        uid: user,
        data: {
          leaveAllowances: this.leaveAllowances
        }
      })
        .then(this.setLeaveAllowance)
        .catch((err) => {
          console.log({ err })
        })
    }
  }
}
</script>

<style>
.lizard-stats-box {
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid #eee;
  border-radius: 3px;
}
.lizard-stats-box .label {
  color: #fff;
  text-align: center;
  padding: 4px;
  border-bottom: 1px solid #eee;
  font-size: 10pt;
  font-weight: 600;
  height: 40px;
  line-height: 30px;
  background-color: #66ccff;
}
.lizard-stats-box .value {
  color: #666;
  text-align: center;
  padding: 4px;
  font-size: 10pt;
  height: 40px;
  line-height: 30px;
}
.lizard-stats-box .w10 {
  width: auto !important;
}
.lizard-stats-box .w40 {
  width: 25% !important;
}
.lizard-stats-box .aleft {
  text-align: left;
}
.allowance-year-group {
  width: 100% !important;
  line-height: 1 !important;
}
.allowance-year-group label {
  background-color: #eee !important;
  color: #ccc !important;
  height: 32px !important;
  min-height: 32px !important;
  line-height: 2 !important;
  vertical-align: middle !important;
  box-sizing: border-box;
  min-width: 25% !important;
  font-size: 75% !important;
  padding: auto 0 !important;
  border: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.allowance-year-group label:hover {
  background-color: #ccc !important;
  color: #eee !important;
}
.allowance-year-group label.focus {
  box-shadow: 0 0 0 transparent !important;
}
.allowance-year-group label.active {
  /* background-color: #17a2b8 !important; */
  background-color: #66ccff !important;
  color: #fff !important;
}
</style>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.allowance-input {
  padding: 0;
  margin: 0 auto;
  background: transparent;
  border: 0;
  font-weight: bold;
  font-size: 10pt;
  max-width: 40px;
}
</style>
