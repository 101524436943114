<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-10 text-left header-msg">&nbsp;</div>
      <div class="col-sm-1"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-left">
        <b-card no-body>
          <b-tabs pills card class="tabs-space-ver">
            <b-tab
              v-for="tab in tabs.filter((tab) => tab.show)"
              :key="tab.id"
              :title="tab.title"
              :active="tab.active"
              lazy
              @click="tabChanged(tab.id)"
            >
              <component :is="tab.component" />
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../services/main.service.vue'
import ReportsComponent from './Main.vue'
import CTReportsComponent from './CT.vue'

export default {
  name: 'ReportsComponenet',
  components: {
    ReportsComponent,
    CTReportsComponent
  },
  data() {
    return {
      tabs: [
        {
          id: 'tab_reports_timesheet',
          component: 'reports-component',
          title: 'Timesheet',
          active: true,
          show: true
        },
        {
          id: 'tab_reports_ct',
          component: 'CTReportsComponent',
          title: 'CT Report',
          active: false,
          show: true
        }
      ]
    }
  },
  mounted() {
    this.$root.$on('parent_tab_reports_selected', this.tabMounted)
  },
  methods: {
    tabMounted(tabId) {
      this.tabChanged(tabId)
    },
    tabChanged(tabId) {
      Services.Users.fetchCurrentUser()
        .then(({ status, data }) => {
          if (status === 200) {
            this.$root.$emit(`${tabId}_selected`)
          } else {
            this.$root.$emit('user-signed-out')
          }
        })
        .catch(() => {
          this.$root.$emit('user-signed-out')
        })
    }
  }
}
</script>
