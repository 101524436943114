<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Type</label></div>
    <div class="col-sm-7">
      <b-form-select
        v-model="selectedType"
        :options="holidayTypeOptions"
        @change="typeChanged"
        size="sm"
      >
      </b-form-select>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'

export default {
  name: 'holidayTypeSelector',
  data() {
    return {
      selectedType: null,
      holidayTypeOptions: [
        { value: null, text: 'Select your request type', disabled: true }
      ]
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    selectedHolidayType() {
      return this.FormData.selectedHolidayType
    }
  },
  async mounted() {
    await this.getHolidayTypeOptions()
  },
  methods: {
    async getHolidayTypeOptions() {
      const holidayTypeOptions = await ServicesOld.GetHolidayTypeOptions({})
      if (holidayTypeOptions.status == 200) {
        this.holidayTypeOptions = this.holidayTypeOptions.splice(0, 1)
        this.holidayTypeOptions = this.holidayTypeOptions.concat(
          holidayTypeOptions.data.content.filter((itm) => {
            return itm.sort > -1
          })
        )
        const halfDayIndex = this.holidayTypeOptions.findIndex(
          (itm) => itm.value == 'HALFDAY'
        )
        this.holidayTypeOptions[halfDayIndex].value = 'HALFDAYAM'
        this.holidayTypeOptions[halfDayIndex].text = 'Half-Day Holiday (AM)'
        this.holidayTypeOptions.push({
          sort: this.holidayTypeOptions[halfDayIndex].sort + 0.1,
          value: 'HALFDAYPM',
          text: 'Half-Day Holiday (PM)'
        })
        this.holidayTypeOptions = this.holidayTypeOptions.sort((a, b) => {
          if (a.sort < b.sort) return -1
          if (a.sort > b.sort) return 1
          return 0
        })
        this.$root.$emit('holidayTypeOptions', this.holidayTypeOptions)
      }
    },
    typeChanged() {
      const halfDayRegEx = new RegExp(/^HALFDAY[A-Z]M$/i)
      const personalHalfDayRegEx = new RegExp(/^HALFPERSONAL$/i)
      const isHalfDay = halfDayRegEx.test(this.selectedType)
      const isPersonalHalfDay = personalHalfDayRegEx.test(this.selectedType)
      this.$store.commit('modifyFormData', {
        key: 'holidayType',
        value: isHalfDay ? 'HALFDAY' : this.selectedType
      })
      if (isHalfDay) {
        this.$store.commit('modifyFormData', {
          key: 'holidayHalfDayMeridiem',
          value: this.selectedType.substr(this.selectedType.length - 2)
        })
      }
    }
  }
}
</script>

<style>
label.btn-outline-info {
  padding: 0 !important;
  line-height: 30px !important;
}
</style>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
