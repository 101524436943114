<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Category</label></div>
    <div class="col-sm-7">
      <select
        class="form-control txt"
        v-model="selectedCategory"
        @change="categoryChanged"
        :disabled="loadingType">
        <option value="def" selected v-if="loadingType">Please wait...</option>
        <option value="def" selected v-if="!loadingType">Select one...</option>
        <option v-for="cat in categories" :key="cat.value" :value="cat.value">
          {{ cat.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'

export default {
  name: 'CategoriesSelector',
  data() {
    return {
      loadingType: false,
      selectedCategory: 'def',
      categories: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    category() {
      return this.FormData.category
    }
  },
  async mounted() {
    this.loadingType = true
    const Categories = await ServicesOld.GetExpenseCategories()
    this.categories = Categories.data.content
    this.loadingType = false
  },
  methods: {
    categoryChanged() {
      if (this.category !== this.selectedCategory) {
        this.$store.commit('modifyFormData', {
          key: 'category',
          value: this.selectedCategory
        })
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
