<template>
  <b-container id="app" class="lizard-wrapper" fluid>
    <div class="lizard-content">
      <router-view />
    </div>
    <footer class="lizard-footer">
      <div style="float: left">
        <strong>{{ appName }} v{{ appVersion }}</strong>
      </div>
      <div style="float: right">
        <strong>&copy; Candesic 2024</strong>
      </div>
    </footer>
  </b-container>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    appName() {
      return this?.$store?.getters?.appName ?? 'default'
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? '0.0.0'
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'CANDESIC-FONT';
  src: url('assets/fonts/myriad-pro/MYRIADPRO-REGULAR.OTF') format('opentype');
}
html,
body {
  height: 100% !important;
  margin: 0;
  padding: 0;
  font-family: 'CANDESIC-FONT', Arial, sans-serif !important;
}
body {
  background: rgba(100, 100, 100, 0.55) url('assets/bg-1.jpg') no-repeat center
    center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-blend-mode: darken;
  display: flex;
  flex-direction: column;
}
.lizard-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 !important;
  padding: 0 !important;
}
.lizard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.lizard-footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgb(102, 204, 255, 0.5) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background-blend-mode: darken;
  color: #ccc;
  padding: 0 3vw;
  font-weight: bold;
  font-size: 10pt;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: auto !important;
  border-top: 2px solid #fff;
}
.lizard-footer div {
  line-height: 10vh;
  display: inline-block;
  position: relative;
}
.tab-content {
  min-height: calc(100vh - 35vh) !important;
}
</style>
