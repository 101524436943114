<template>
  <div class="lizard-form-bg">
    <div class="row">
      <div class="col-lg-5">
        <ActivityForm :activityType="activityType" />
      </div>
      <div class="col-lg-7">
        <ActivityList />
      </div>
    </div>
  </div>
</template>

<style scoped>
.lizard-form-bg {
  background-color: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid #ccc;
}
</style>

<script>
import ActivityForm from './ActivityForm'
import ActivityList from './ActivityList'

export default {
  name: 'ActivityEntry',
  props: ['activityType'],
  components: {
    ActivityForm,
    ActivityList
  },
  mounted() {
    this.$store.commit('resetFormData')
    this.$store.commit('clearActivityEnteries')
  }
}
</script>
