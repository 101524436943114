<template>
  <b-container fluid>
    <div class="row" v-if="list.length">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="list"
          :fields="fields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption>TimeLog Reports</template>
          <template #cell(date)="data">
            {{ formatDate(data.item.date) }}
          </template>
          <template #cell(owner)="data">
            <span
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to include in filters"
              @click="fnAddToFilters(data.item.owner, 'user')"
              style="cursor: pointer"
            >
              {{ data.item.owner.fullName }}
            </span>
          </template>
          <template #cell(project)="data">
            <div
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to include in filters"
              @click="fnAddToFilters(data.item.project, 'project')"
              class="selected-project"
              style="margin-bottom: 5px"
              :style="
                'background-color: ' + data.item.project.color + ' !important'
              "
            >
              {{ data.item.project.title }}
            </div>
          </template>
          <template #cell(hourlyRate)="data">
            <span
              v-if="showSensitiveData"
              v-b-tooltip.hover.bottom="{ variant: 'primary' }"
              :title="getRatePeriodText(data.item.ratePeriod)"
            >
              £{{ numberWithCommas(data.item.hourlyRate) || 0 }}
            </span>
            <span v-else>
              <h6>
                <b-badge variant="light" style="color: #999">***</b-badge>
              </h6>
            </span>
          </template>
          <template #cell(cost)="data">
            <span v-if="showSensitiveData"
              >£{{ numberWithCommas(data.item.cost) || 0 }}</span
            >
            <span v-else>
              <h6>
                <b-badge variant="light" style="color: #999">***</b-badge>
              </h6>
            </span>
          </template>
        </b-table>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row" v-if="list.length > pager.perPage">
      <div class="col-lg-12 text-center">
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="list.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, parseISO } from 'date-fns'

export default {
  name: 'TimelogReportsComponent',
  props: {
    fnAddToFilters: {
      type: Function,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    showSensitiveData: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'date',
          label: 'Date',
          thClass: 'aleft',
          tdClass: 'aleft',
          sortable: true
        },
        {
          key: 'owner',
          label: 'Team member',
          thClass: 'aleft',
          tdClass: 'aleft',
          sortable: true
        },
        // {
        //   key: 'owner.role',
        //   label: 'Role',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        //   sortable: true
        // },
        {
          key: 'project',
          label: 'Project',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        },
        // {
        //   key: 'hourlyRate',
        //   label: 'Hourly Rate',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        //   sortable: true
        // },
        {
          key: 'hours',
          label: 'Hours submitted',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true
        }
        // {
        //   key: 'cost',
        //   label: 'Pay to Hours',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        //   sortable: true
        // }
      ],
      loading: false,
      pager: {
        currentPage: 1,
        perPage: 25
      }
    }
  },
  methods: {
    getRatePeriodText(ratePeriod = []) {
      ratePeriod = ratePeriod.filter((rate) => rate != null)
      if (ratePeriod.length === 0)
        return 'There is no rate time period associated with this timelog.'
      if (ratePeriod.length === 1)
        return `Based on rates assigned from ${dateFormat(
          parseISO(ratePeriod[0]),
          'dd/MM/yyyy'
        )} to the present day`
      return `Based on rates assigned from ${dateFormat(
        parseISO(ratePeriod[0]),
        'dd/MM/yyyy'
      )} to ${dateFormat(parseISO(ratePeriod[1]), 'dd/MM/yyyy')}`
    },
    numberWithCommas(x) {
      const parsed = parseFloat(x || 0).toFixed(2)
      return parseFloat(parsed).toLocaleString()
    },
    formatDate(dt) {
      return dateFormat(dt, 'dd/MM/yyyy')
    }
  }
}
</script>
