<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2" style="text-align: right !important">
      <label for="" class="h6">VAT</label>
    </div>
    <div
      class="col-sm-3"
      style="margin: 0 !important; padding-right: 2px !important">
      <b-form-input
        :value="vat.amount"
        :disabled="showPercentageButtons && selectedVat !== 'other'"
        @change="vatChanged"
        type="number"
        class="form-control lizard-form-duration txt"></b-form-input>
    </div>
    <!-- <div
      class="col-sm-4"
      v-if="showPercentageButtons"
      style="margin: 0 !important; padding-left: 2px !important">
      <b-form-radio-group
        :options="vatOptions"
        name="radio-inline"
        buttons
        button-variant="outline-info"
        size="sm"
        class="lizard-form-date"
        v-model="selectedVat"
        @change="vatChanged"></b-form-radio-group>
    </div> -->
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
export default {
  name: 'VATSelector',
  data() {
    return {
      selectedVat: 'other',
      vatOptions: [
        { text: '0%', value: '0' },
        { text: '20%', value: '20' },
        { text: 'Other', value: 'other' }
      ],
      showPercentageButtons: false
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    amount() {
      return this.FormData.amount
    },
    vat() {
      return this.FormData.vat
    }
  },
  methods: {
    vatChanged(value) {
      this.$store.commit('setFormDataVat', {
        amount: value,
        value: this.selectedVat
      })
      // const newAmount =
      //   this.selectedVat === 'other'
      //     ? this.vat.amount
      //     : (this.amount * parseInt(value)) / 100
      // this.$store.commit('modifyFormData', {
      //   key: 'amount',
      //   value: newAmount
      // })
      // this.vat = {
      //   value:
      //     this.vatOptions.findIndex((itm) => itm.value == value) == -1
      //       ? 'other'
      //       : value,
      //   amount
      // }
      // this.$root.$emit('vat-changed', this.vat)
    }
  },
  watch: {
    // 'formData.vat': {
    //   handler(vat) {
    //     this.vat = vat
    //     this.selectedVat = vat.value
    //   },
    //   deep: true
    // }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.file-txt {
  color: #666;
  font-weight: bold !important;
  font-size: 8pt !important;
}
</style>
