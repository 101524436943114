<template>
  <b-container fluid>
    <div class="row">
      <div class="col-lg-12 text-right">
        <b-button
          :id="btn.id"
          :variant="btn.variant"
          size="sm"
          @click="toggleModal"
        >
          {{ btn.text }}
        </b-button>
      </div>
    </div>
    <b-modal
      centered
      no-close-on-esc
      button-size="sm"
      :ref="modal.id"
      :header-bg-variant="modal.styles.headerBgVariant"
      :header-text-variant="modal.styles.headerTextVariant"
      :body-bg-variant="modal.styles.bodyBgVariant"
      :body-text-variant="modal.styles.bodyTextVariant"
      :footer-bg-variant="modal.styles.footerBgVariant"
      :footer-text-variant="modal.styles.footerTextVariant"
    >
      <template #modal-title>{{ modal.title }}</template>
      <div class="d-block text-left">
        <b-form>
          <b-form-row class="mb-1">
            <b-col cols="3">
              <b-form-group
                id="input-group-1"
                label="Team member:"
                label-for="user"
              >
              </b-form-group>
            </b-col>
            <b-col cols="8" class="text-left">
              <b-form-group
                id="filter-group-1"
                label=""
                label-for="teamMembersSearchInput"
                description=""
              >
                <b-form-input
                  id="teamMembersSearchInput"
                  list="teamMembersList"
                  size="sm"
                  placeholder="Select a team member..."
                  :value="modal.data.userFullname"
                  @keydown="checkUsersList"
                  @change="userChanged"
                ></b-form-input>
                <b-form-datalist
                  id="teamMembersList"
                  :options="data.teamMembers"
                  text-field="fullName"
                ></b-form-datalist>
              </b-form-group>
            </b-col>
            <b-col cols="1">&nbsp;</b-col>
          </b-form-row>
          <b-form-row class="mb-1">
            <b-col cols="3">
              <b-form-group
                id="input-group-3"
                label="Holiday Type:"
                label-for="type"
              >
              </b-form-group>
            </b-col>
            <b-col cols="8" class="text-left">
              <b-form-select
                id="type"
                size="sm"
                v-model="modal.data.type"
                :disabled="!modal.data.user"
                :options="data.types"
                required
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select a Holiday type</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="1">&nbsp;</b-col>
          </b-form-row>
          <b-form-row class="mb-1">
            <b-col cols="3">
              <b-form-group id="input-group-3" label="Date:" label-for="date">
              </b-form-group>
            </b-col>
            <b-col cols="8" class="text-left">
              <el-date-picker
                v-model="modal.data.dates"
                type="date"
                placeholder="Pick a day"
                style="min-width: fit-content; width: 100%"
                size="small"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                :disabled="!modal.data.user"
                :picker-options="holidayPickerOptions"
                v-if="!data.showDateRangeInput"
              ></el-date-picker>
              <el-date-picker
                v-model="modal.data.dates"
                type="daterange"
                range-separator=""
                start-placeholder="From"
                end-placeholder="To"
                style="min-width: fit-content; width: 100%"
                size="small"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                :disabled="!modal.data.user"
                :picker-options="holidayPickerOptions"
                v-else
              >
              </el-date-picker>
            </b-col>
            <b-col cols="1">&nbsp;</b-col>
          </b-form-row>
          <b-form-row class="mb-1" v-if="modal.data.allowance.length">
            <b-col cols="3">
              <b-form-group
                id="input-group-3"
                label="Balance:"
                label-for="allowance"
              >
              </b-form-group>
            </b-col>
            <b-col cols="8" class="text-left">
              <b-button
                v-for="yearData in modal.data.allowance"
                :key="yearData.year"
                :variant="yearData.balance >= 0 ? 'success' : 'danger'"
                style="margin: 0 1px"
                disabled
                size="sm"
              >
                {{ yearData.year }}:
                <b-badge variant="light">{{ yearData.balance }}</b-badge>
              </b-button>
            </b-col>
            <b-col cols="1">&nbsp;</b-col>
          </b-form-row>
        </b-form>
      </div>
      <template #modal-footer>
        <b-button
          :disabled="modal.loading"
          size="sm"
          variant="info"
          @click="addNewHoliday()"
        >
          {{ modal.submitBtnText }}
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'
import {
  isWeekend,
  format as dateFormat,
  differenceInCalendarDays,
  differenceInQuarters
} from 'date-fns'

export default {
  name: 'CreateHoliday',
  data() {
    return {
      data: {
        types: [],
        bankHolidays: [],
        teamMembers: [],
        showDateRangeInput: false
      },
      btn: {
        id: 'add-holiday-btn',
        text: 'Add Holiday',
        variant: 'info'
      },
      modal: {
        id: 'add-holiday-modal',
        title: 'New Holiday',
        submitBtnText: 'Submit',
        loading: false,
        styles: {
          headerBgVariant: 'info',
          headerTextVariant: 'light',
          bodyBgVariant: 'light',
          bodyTextVariant: 'dark',
          footerBgVariant: 'light',
          footerTextVariant: 'dark'
        },
        data: {
          user: null,
          userFullname: null,
          dates: null,
          type: null,
          holidayType: null,
          holidayHalfDayMeridiem: null,
          totalDays: 0,
          totalBankHolidaysInDates: 0,
          allowanceData: [],
          allowance: [],
          allowanceKey: null
        }
      }
    }
  },
  computed: {
    holidayPickerOptions() {
      return {
        firstDayOfWeek: 1,
        disabledDate: (dt) => {
          const isBankHoliday = this.data.bankHolidays.includes(
            dateFormat(new Date(dt), 'yyyy-MM-dd')
          )
          const dtYear = new Date(dt).getFullYear()
          const now = new Date()
          const currentYear = now.getFullYear()
          const sideYear =
            currentYear +
            (!Math.abs(
              differenceInQuarters(
                now,
                new Date(currentYear + 1, 1, 1, 0, 0, 0, 0)
              )
            )
              ? 1
              : -1)
          const sameYear = [currentYear, sideYear].includes(dtYear)
          return isWeekend(new Date(dt)) || isBankHoliday || !sameYear
        }
      }
    }
  },
  methods: {
    async toggleModal() {
      this.$refs[this.modal.id].toggle('#' + this.btn.id)
      if (this.data.bankHolidays.length === 0) {
        const { status, data } = await this.getBankHolidays()
        if (status === 200) {
          this.data.bankHolidays = data.content.map((bh) => bh.date)
        }
      }
      if (this.data.teamMembers.length === 0) {
        await this.SetUsersList()
      }
    },
    async holidayTypes(user) {
      this.data.types = []
      const holidayTypeOptions = await ServicesOld.GetHolidayTypeOptions({
        userUid: user.uid
      })
      if (holidayTypeOptions.status == 200) {
        this.data.types = this.data.types.splice(0, 1)
        this.data.types = this.data.types.concat(
          holidayTypeOptions.data.content.filter((itm) => {
            return itm.sort > -1
          })
        )
        const halfDayIndex = this.data.types.findIndex(
          (itm) => itm.value == 'HALFDAY'
        )
        this.data.types[halfDayIndex].value = 'HALFDAYAM'
        this.data.types[halfDayIndex].text = 'Half-Day Holiday (AM)'
        this.data.types.push({
          sort: this.data.types[halfDayIndex].sort + 0.1,
          value: 'HALFDAYPM',
          text: 'Half-Day Holiday (PM)'
        })
        this.data.types = this.data.types.sort((a, b) => {
          if (a.sort < b.sort) return -1
          if (a.sort > b.sort) return 1
          return 0
        })
      }
    },
    async getBankHolidays() {
      return await ServicesOld.GetBankHolidays()
    },
    async SetUsersList() {
      const Users = await Services.Users.Admin.getList()
      const List =
        Users?.data?.content
          ?.filter((user) => user.settings.active)
          ?.map(({ uid, firstName, lastName, email }) => {
            return {
              uid,
              fullName: `${firstName} ${lastName}`.trim(),
              email
            }
          }) ?? []
      this.data.teamMembers = List.sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1
        }
        if (a.fullName > b.fullName) {
          return 1
        }
        return 0
      })
    },
    async userChanged(fullName) {
      const user = this.data.teamMembers.find(
        (member) => member.fullName === fullName
      )
      this.modal.data.user = user?.uid ?? null
      if (this.modal.data.user) {
        this.modal.data.userFullname = user.fullName
        await this.holidayTypes(user)
      }
      // GET ALLOWANCE
      this.getAllowance()
        .then((allowance) => {
          this.modal.data.allowanceData = allowance
        })
        .catch((err) => {
          console.log({ err })
        })
    },
    typeChanged(val) {
      this.modal.data.dates = null
      this.modal.data.totalDays = 0
      this.modal.data.totalBankHolidaysInDates = 0
      this.modal.data.allowance = []
      const halfdayReg = new RegExp('^HALF.+', 'i')
      this.data.showDateRangeInput = !halfdayReg.test(val)
      this.modal.data.allowanceKey = null
      switch (val) {
        case 'ANNUAL':
          this.modal.title = 'New Annual Holiday'
          this.modal.data.holidayType = val
          this.modal.data.allowanceKey = val
          break
        case 'SICK':
        case 'PERSONAL':
          this.modal.title = 'New Personal/Sick Holiday'
          this.modal.data.holidayType = val
          this.modal.data.allowanceKey = 'PERSONAL'
          break
        case 'HALFDAYAM':
          this.modal.title = 'New Half-Day Holiday (AM)'
          this.modal.data.holidayType = 'HALFDAY'
          this.modal.data.holidayHalfDayMeridiem = 'AM'
          this.modal.data.allowanceKey = 'ANNUAL'
          break
        case 'HALFDAYPM':
          this.modal.title = 'New Half-Day Holiday (PM)'
          this.modal.data.holidayType = 'HALFDAY'
          this.modal.data.holidayHalfDayMeridiem = 'PM'
          this.modal.data.allowanceKey = 'ANNUAL'
          break
        case 'HALFPERSONAL':
          this.modal.title = 'New Half-Day Personal'
          this.modal.data.holidayType = 'HALFPERSONAL'
          this.modal.data.holidayHalfDayMeridiem = 'AM'
          this.modal.data.allowanceKey = 'PERSONAL'
          break
        default:
          this.modal.title = 'New Holiday'
          this.modal.data.holidayType = val
          break
      }
      if (this.modal.data.user) {
        this.modal.title += ' for ' + this.modal.data.userFullname
      }
    },
    datesChanged(dt) {
      this.modal.data.allowance = []
      if (!dt) {
        return
      }
      dt = typeof dt === 'string' ? [dt] : dt
      const start = new Date(dt[0])
      const end = new Date(dt[1] || dt[0])
      const daysInEachYear = {}
      this.modal.data.totalDays =
        this.modal.data.holidayType === 'HALFDAY'
          ? 0.5
          : differenceInCalendarDays(new Date(end), new Date(start)) + 1
      const loop = new Date(start)
      while (loop <= end) {
        const year = loop.getFullYear()
        const dt = dateFormat(loop, 'yyyy-MM-dd')
        const isBankHoliday = this.data.bankHolidays.includes(dt)
        if (isBankHoliday || isWeekend(loop)) {
          this.modal.data.totalDays -= ['HALFDAY', 'HALFPERSONAL'].includes(
            this.modal.data.holidayType
          )
            ? 0.5
            : 1
        } else {
          daysInEachYear[year] = daysInEachYear[year] || 0
          daysInEachYear[year]++
        }
        loop.setDate(loop.getDate() + 1)
      }
      let hasBalance = true
      if (this.modal.data.allowanceKey) {
        for (const year in this.modal.data.allowanceData) {
          const requestedDays = daysInEachYear[year] ?? 0
          const allowance =
            this.modal.data.allowanceData[year].find(
              (data) => data.key === this.modal.data.allowanceKey
            )?.remains ?? 0
          daysInEachYear[year] = allowance - requestedDays
          hasBalance = hasBalance && daysInEachYear[year] >= 0
          this.modal.data.allowance.push({
            year,
            requestedDays,
            allowance,
            balance: allowance - requestedDays
          })
        }
      }
    },
    async getAllowance() {
      try {
        const response = await ServicesOld.GetHolidayAllowencesByUser(
          this.modal.data.user
        )
        return response?.data?.content ?? {}
      } catch (Exception) {
        throw new Error(Exception)
      }
    },
    async checkUsersList() {
      if (!this.data.teamMembers.length) {
        await this.SetUsersList()
      }
    },
    resetData() {
      this.data.types = []
      this.data.teamMembers = []
      this.data.bankHolidays = []
      this.modal.data = {
        user: null,
        userFullname: null,
        dates: null,
        type: null,
        holidayType: null,
        holidayHalfDayMeridiem: null,
        totalDays: 0,
        totalBankHolidaysInDates: 0,
        allowanceData: [],
        allowance: null,
        allowanceBgColour: 'transparent'
      }
    },
    addNewHoliday() {
      const { dates, holidayType, holidayHalfDayMeridiem, totalDays, user } =
        this.modal.data
      ServicesOld.AddHolidayByAdmin({
        dates: typeof dates === 'string' ? [dates] : dates,
        type: holidayType,
        holidayHalfDayMeridiem,
        totalDays,
        user,
        notes: 'added by admin.'
      })
        .then((res) => {
          console.log({ res })
          if (res.status === 200) {
            this.resetData()
            this.$refs[this.modal.id].hide()
            setTimeout(() => {
              this.$root.$emit('tab_holidays_selected')
            }, 500)
          }
        })
        .catch((err) => {
          console.log({ err })
        })
    }
  },
  watch: {
    'modal.data.type': {
      handler: 'typeChanged',
      immediate: true
    },
    'modal.data.dates': {
      handler: 'datesChanged',
      immediate: true
    }
  }
}
</script>
