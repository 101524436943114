const { name, version } = require('../../package.json')
import { startOfWeek, addDays, format } from 'date-fns'

export default function (Vuex) {
  return new Vuex.Store({
    state: {
      packageName: name,
      packageVersion: version,
      appEnv: process.env.APP_ENV,
      appStatus: process.env.APP_STATUS,
      appMessage: process.env.VUE_APP_MESSAGE,
      showAdminTabs: process.env.VUE_APP_SHOW_ADMIN_TABS,
      showCostExplTab: process.env.VUE_APP_SHOW_COSTEXPL_TAB,
      showAd: process.env.VUE_APP_SHOW_AD,
      user: {
        authenticated: false,
        hasAdminPrivileges: false,
        hasManagerPrivileges: false,
        details: {}
      },
      components: {
        staffing: {
          userRoles: [],
          selectedRoles: [],
          users: [],
          activeProjects: [],
          calendarWeeksStartDate: null,
          projectToDrag: null
        },
        staffinglogs: {
          userRoles: [],
          selectedRoles: [],
          users: [],
          calendarWeeksStartDate: null
        },
        timelogs: {
          calendarWeeksStartDate: null,
          logsInQueue: [],
          logsToRemoveQueue: []
        },
        reports: {
          filtersInfo: {
            projectTitles: [],
            userFullName: '',
          },
          filters: {
            user: null,
            projects: [],
            dates: []
          }
        },
        expenses: {
          statusOptions: [],
          selectedStatus: [],
          selectedDateRange: {},
          searchQuery: ''
        },
        leaves: {
          approvedList: [], // the half days are not included!
          filters: {
            user: {
              fullName: null,
              uid: null
            },
            labels: [{
              value: 'submittedDate',
              text: 'Filter by Submitted Date'
            }, {
              value: 'leaveDates',
              text: 'Filter by Leave Dates'
            }],
            dateLabel: 'submittedDate',
            dates: []
          }
        },
        formData: {
          type: 'def',
          project: 'def',
          dates: null,
          expenseProjType: 'prj',
          holidayType: null,
          holidayHalfDayMeridiem: 'AM',
          category: 'def',
          currency: 'gbp',
          amount: 0,
          holiday: 'full',
          duration: '1',
          approvalmanager: 'def',
          vat: {
            value: '0',
            amount: 0
          },
          totalDays: 1,
          notes: '',
          file: null,
          totalBankHolidaysInDates: 0
        },
        formLayouts: {
          FrmHolidayType: false,
          FrmProject: false,
          FrmDate: false,
          FrmDateRange: false,
          FrmCategories: false,
          FrmAmount: false,
          FrmReceipt: false,
          FrmManager: false,
          FrmVAT: false,
          FrmTotalDays: false,
          FrmHours: false,
          FrmNotes: false
        },
        assetBoard: {
          page: 1,
          perPage: 15,
          filters: {},
          sort: []
        },
        activityItems: {
          holidayItems: [],
          expenseItems: []
        },
        projectsTracker: {
          page: 1,
          perPage: 15,
          total: 0
        }
      },
      caches: {
        bankHolidays: {
          ts: null,
          data: []
        },
      }
    },
    getters: {
      appName: (state) => {
        return state.packageName
      },
      appVersion: (state) => {
        return state.packageVersion
      },
      appEnv: (state) => {
        return state.appEnv
      },
      appStatus: (state) => {
        return state.appStatus
      },
      appMessage: (state) => {
        return state.appMessage
      },
      showAdminTabs: (state) => {
        return state.showAdminTabs === 'true'
      },
      showCostExplTab: (state) => {
        return state.showCostExplTab === 'true'
      },
      showAd: (state) => {
        return state.showAd === 'true'
      },
      userAuth: (state) => {
        return state.user
      },
      staffingActiveProjects: (state) => {
        return state.components.staffing.activeProjects
      },
      staffingUsers: (state) => {
        return state.components.staffing.users
      },
      staffingUserRoles: (state) => {
        return state.components.staffing.userRoles
      },
      staffingSelectedUserRoles: (state) => {
        return state.components.staffing.selectedRoles
      },
      staffingCalendarWeeksStartDate: (state) => {
        return state.components.staffinglogs.calendarWeeksStartDate
      },
      calendarWeeksStartDate: (state) => {
        return state.components.timelogs.calendarWeeksStartDate
      },
      timelogsInQueue: (state) => {
        return state.components.timelogs.logsInQueue
      },
      timelogsToRemoveQueue: (state) => {
        return state.components.timelogs.logsToRemoveQueue
      },
      reportsFilters(state) {
        return {
          info: state.components.reports.filtersInfo,
          filters: state.components.reports.filters
        }
      },
      expenseStatusOptions(state) {
        return state.components.expenses.statusOptions
      },
      selectedExpenseStatus(state) {
        return state.components.expenses.selectedStatus
      },
      selectedExpenseDateRange(state) {
        return state.components.expenses.selectedDateRange
      },
      expenseSearchQuery(state) {
        return state.components.expenses.searchQuery
      },
      formData(state) {
        return state.components.formData
      },
      formLayouts(state) {
        return state.components.formLayouts
      },
      activityItems(state) {
        return state.components.activityItems
      },
      bankHolidays(state) {
        return state.caches.bankHolidays
      },
      approvedHolidays(state) {
        return state.components.leaves.approvedList
      },
      leavesFilters(state) {
        return state.components.leaves.filters
      },
      assetBoardPager(state) {
        const { page, perPage } = state.components.assetBoard
        return { page, perPage }
      },
      assetBoardFilters(state) {
        return state.components.assetBoard.filters
      },
      assetBoardSort(state) {
        return state.components.assetBoard.sort
      },
      projectsTrackerPager(state) {
        const { page, perPage, total } = state.components.projectsTracker
        return { page, perPage, total }
      },
    },
    mutations: {
      setUser(state, user = {}) {
        state.user = {
          authenticated: true,
          hasAdminPrivileges: ['superadmin', 'admin'].includes(user?.role) ?? false,
          hasManagerPrivileges: user?.role === 'manager' ?? false,
          details: user
        }
      },
      rmUser(state) {
        state.user = {
          authenticated: false,
          hasAdminPrivileges: false,
          hasManagerPrivileges: false,
          details: {}
        }
      },
      setUserAuthenticationStatus(state, status) {
        state.user.authenticated = status
      },
      setStaffingUserRoles(state, userRoles) {
        state.components.staffing.userRoles = userRoles
      },
      setStaffingSelectedRoles(state, selectedRoles) {
        state.components.staffing.selectedRoles = selectedRoles
      },
      setStaffingUsers(state, users) {
        state.components.staffing.users = users
      },
      setStaffingLogsUsers(state, users) {
        state.components.staffinglogs.users = users
      },
      setStaffingActiveProjects(state, projects) {
        state.components.staffing.activeProjects = projects
      },
      setTimelogsCalendarWeeksStartDate(
        state,
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 })
      ) {
        state.components.timelogs.calendarWeeksStartDate = startDate
      },
      setStaffingCalendarWeeksStartDate(
        state,
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 })
      ) {
        state.components.staffing.calendarWeeksStartDate = startDate
      },
      setStaffingLogsCalendarWeeksStartDate(
        state,
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 })
      ) {
        state.components.staffinglogs.calendarWeeksStartDate = startDate
      },
      setStaffingProjectToDrag(state, project) {
        state.components.staffing.projectToDrag = project
      },
      setTimelogCalendarWeeksStartDate(
        state,
        startDate = startOfWeek(new Date(), { weekStartsOn: 1 })
      ) {
        state.components.timelogs.calendarWeeksStartDate = startDate
      },
      timelogToQueue(state, { project, date, hours }) {
        const currentQueue = this.getters.timelogsInQueue
        const timelogIndex = currentQueue.findIndex(
          (tl) => tl.project === project && tl.date === date
        )
        if (timelogIndex != -1) {
          state.components.timelogs.logsInQueue[timelogIndex].hours = hours
        } else {
          state.components.timelogs.logsInQueue.push({ project, date, hours })
        }
      },
      removeTimelogFromQueue(state, { project, date }) {
        const currentQueue = this.getters.timelogsInQueue
        const timelogIndex = currentQueue.findIndex(
          (tl) => tl.project === project && tl.date === date
        )
        if (timelogIndex != -1) {
          state.components.timelogs.logsInQueue.splice(timelogIndex, 1)
        }
      },
      clearTimelogQueue(state) {
        state.components.timelogs.logsInQueue = []
      },
      clearDeleteTimelogQueue(state) {
        state.components.timelogs.logsToRemoveQueue = []
      },
      timelogToRemoveQueue(state, { project, date }) {
        const currentQueue = this.getters.timelogsToRemoveQueue
        const timelogIndex = currentQueue.findIndex(
          (tl) => tl.project === project && tl.date === date
        )
        if (timelogIndex == -1) {
          state.components.timelogs.logsToRemoveQueue.push({
            project,
            date
          })
        }
      },
      deleteTimelogFromRemoveQueue(state, { project }) {
        const currentQueue = this.getters.timelogsToRemoveQueue
        const timelogIndex = currentQueue.findIndex(
          (tl) => tl.project === project
        )
        if (timelogIndex != -1) {
          state.components.timelogs.logsToRemoveQueue = currentQueue.filter(
            (tl) => tl.project !== project
          )
        }
      },
      setReportsFilters(state, { filter, data }) {
        switch (filter) {
          case 'project':
            // state.components.reports.filters.project = data.uid
            // state.components.reports.filtersInfo.projectTitle = data.title
            if (data.uid) {
              state.components.reports.filters.projects.push(data.uid)
              state.components.reports.filtersInfo.projectTitles.push(data.title)
            }
            break;
          case 'user':
            state.components.reports.filters.user = data.uid
            state.components.reports.filtersInfo.userFullName = data.fullName
            break;
          case 'dates':
            state.components.reports.filters.dates = data
            break;
        }
      },
      deleteReportsFilters(state, { filter, data }) {
        switch (filter) {
          case 'project':
            state.components.reports.filters.projects = state.components.reports.filters.projects.filter(p => p !== data.uid)
            state.components.reports.filtersInfo.projectTitles = state.components.reports.filtersInfo.projectTitles.filter(p => p !== data.title)
            break;
          case 'user':
            state.components.reports.filters.user = null
            state.components.reports.filtersInfo.userFullName = ''
            break;
          case 'dates':
            state.components.reports.filters.dates = []
            break;
        }
      },
      setExpenseStatusOptions(state, options) {
        state.components.expenses.statusOptions = options
        options.forEach(({ value }) => {
          state.components.expenses.selectedStatus.push(value)
        })
      },
      setSelectedExpenseStatus(state, statuses) {
        state.components.expenses.selectedStatus = statuses
      },
      setSelectedExpenseDateRange(state, dateRange) {
        state.components.expenses.selectedDateRange = dateRange
      },
      setExpenseSearchQuery(state, q) {
        q = q.trim()
        state.components.expenses.searchQuery = q.length > 1 ? q : ''
      },
      cacheBankHolidays(state, bankHolidays) {
        state.caches.bankHolidays.data = bankHolidays
        state.caches.bankHolidays.ts = new Date()
      },
      modifyFormData(state, { key, value }) {
        state.components.formData[key] = value
      },
      resetFormData(state) {
        state.components.formData = {
          type: 'def',
          project: 'def',
          dates: null,
          expenseProjType: 'prj',
          holidayType: null,
          holidayHalfDayMeridiem: 'AM',
          category: 'def',
          currency: 'gbp',
          amount: 0,
          holiday: 'full',
          duration: '1',
          approvalmanager: 'def',
          vat: {
            value: '0',
            amount: 0
          },
          totalDays: 1,
          notes: '',
          file: null,
          totalBankHolidaysInDates: 0
        }
      },
      setFormDataAmount(state, value) {
        state.components.formData.amount = parseFloat(value).toFixed(2)
        this.commit('setFormDataVat', state.components.formData.vat)
      },
      setFormDataVat(state, { amount, value }) {
        state.components.formData.vat.value = value === 'other' ? value : parseInt(value)
        if (state.components.formData.vat.value === 'other') {
          state.components.formData.vat.amount = parseFloat(amount).toFixed(2)
        } else {
          state.components.formData.vat.amount = parseFloat((parseFloat(state.components.formData.amount) * state.components.formData.vat.value) / 100).toFixed(2)
        }
      },
      setFormLayouts(state, { layout, show }) {
        state.components.formLayouts[layout] = show
      },
      resetFormLayouts(state) {
        for (let key in state.formLayouts) {
          state.components.formLayouts[key] = false
        }
      },
      addActivityEntry(state, { list, item }) {
        state.components.activityItems[list].push(item)
      },
      modifyActivityEnteries(state, { list, value }) {
        state.components.activityItems[list] = value
      },
      clearActivityEnteries(state) {
        for (let key in state.components.activityItems) {
          state.components.activityItems[key] = []
        }
      },
      setApprovedHolidays(state, holidays) {
        state.components.leaves.approvedList = []
        holidays = holidays.filter(h => {
          return h.totalDays > 0.5 && h.status === 'approved'
        }).forEach(h => {
          if (state.components.leaves.approvedList.includes(h.startDate)) {
            return
          }
          for (let i = 0; i < h.totalDays; i++) {
            state.components.leaves.approvedList.push(format(addDays(new Date(h.startDate), i), 'yyyy-MM-dd'))
          }
        })
      },
      setLeavesFilters(state, { filter, data }) {
        state.components.leaves.filters[filter] = data
      },
      setAssetBoardPager(state, { page, perPage }) {
        if (page) {
          state.components.assetBoard.page = page
        }
        if (perPage) {
          state.components.assetBoard.perPage = perPage
        }
      },
      setAssetBoardFilters(state, { filter }) {
        state.components.assetBoard.filters[filter.key] = filter.val
      },
      setAssetBoardSort(state, { sortBy, sortDesc }) {
        state.components.assetBoard.sort = [sortBy, sortDesc ? 'desc' : 'asc']
      },
      resetAssetBoardFilters(state, filters = {}) {
        const keysToKeep = []
        for (const key in filters) {
          if (filters[key].length) {
            keysToKeep.push(key)
          }
        }
        state.components.assetBoard.filters = {}
        keysToKeep.forEach(key => {
          state.components.assetBoard.filters[key] = filters[key]
        })
      },
      updProjectsTrackerPager(state, { page, perPage, total }) {
        if (page) {
          state.components.projectsTracker.page = page
        }
        if (perPage) {
          state.components.projectsTracker.perPage = perPage
        }
        if (total) {
          state.components.projectsTracker.total = total
        }
      },
    }
  })
}
