<template>
  <b-container fluid class="glass-bg">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-center header-msg">
        <h1 v-if="!mobileView">
          <img src="../assets/candesic-2.png" alt="" v-if="isUp()" />
          <span class="strokeme" v-if="isUp()">&nbsp;{{ appName }}</span>
          <strong class="strokeme2" v-if="!isUp()"
            >service is not available right now!</strong
          >
        </h1>
        <h2 v-else>
          <img src="../assets/logo.png" alt="" v-if="isUp()" /><br />
          <strong class="strokeme" v-if="isUp()">{{ appName }}</strong>
          <strong class="strokeme2" v-if="!isUp()"
            >service is not available right now!</strong
          >
        </h2>
        <span class="strokeme2" v-if="!isUp()">{{ appMessage }}</span>
        <div v-if="errorMessage" class="lizard-error">
          {{ errorMessage.toLowerCase() }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'About',
  props: ['errorMessage', 'mobileView'],
  data() {
    return {
      status:
        this?.$store?.getters?.appStatus ?? process?.env?.VUE_APP_STATUS ?? 'up'
    }
  },
  methods: {
    isUp() {
      return this.status === 'up'
    }
  },
  computed: {
    appName() {
      return this?.$store?.getters?.appName ?? 'default'
    },
    appVersion() {
      return this?.$store?.getters?.appVersion ?? '0.0.0'
    },
    appEnv() {
      return (
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
      )
    },
    appMessage() {
      return this?.$store?.getters?.appMessage ?? ''
    }
  }
}
</script>

<style>
@font-face {
  font-family: Calibri;
  src: url('../assets/calibri-regular.ttf');
}
</style>

<style scoped>
.glass-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5970763305322129) 0%,
    rgba(102, 102, 102, 0) 100%
  );
  min-height: calc(100vh - 200px) !important;
}
.lizard-form-empty-col {
  min-height: 10px !important;
}
.header-msg {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  color: #f3f3f3;
  top: 3vw !important;
}
.header-msg img {
  height: 100px !important;
  margin: 0 -10px 18px 0;
}
.header-msg h1 {
  text-transform: uppercase;
  font-size: 100px !important;
  line-height: 4.5rem !important;
  letter-spacing: -0.1rem !important;
  margin: 0;
}
.header-msg h2 {
  text-transform: capitalize;
  font-size: 60px !important;
  line-height: 3rem !important;
  letter-spacing: -0.1rem !important;
  margin: 0;
}
.header-msg h1 .strokeme2 {
  /* text-transform: uppercase; */
  font-size: 50px !important;
  line-height: 4.5rem !important;
  letter-spacing: 0 !important;
  margin: 0;
}
.strokeme {
  text-transform: capitalize;
  /* color: #62a7d4; */
  color: #3070b6;
  font-size: 70pt !important;
  /* text-shadow: -1px -1px 0 #4dcbf4, 1px -1px 0 #4dcbf4, -1px 1px 0 #4dcbf4,
    1px 1px 0 #4dcbf4; */
}
.strokeme2 {
  color: #ff0055;
  /* text-shadow: -1px -1px 0 #f4584d, 1px -1px 0 #f4584d, -1px 1px 0 #f4584d,
    1px 1px 0 #f4584d; */
  text-shadow: -1px -1px 0 #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc,
    1px 1px 0 #ccc;
}
span.strokeme2 {
  font-size: 14pt !important;
  text-wrap: pretty !important;
  background: rgba(255, 255, 255, 0.5) !important;
  padding: 12px !important;
  border-radius: 5px !important;
  border: 2px solid #f05 !important;
}
.lizard-error {
  color: #fff;
  font-size: 12pt;
  font-variant: small-caps;
  border: 3px solid #f05;
  max-width: 40vw;
  margin: 0 auto;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 10px;
}
</style>
