<template>
  <div>
    <b-navbar variant="dark" class="lizard-navbar">
      <b-navbar-brand v-if="IsAuthenticated">
        <span @click="goToCandesic" title="Candesic.com">
          <!-- <img src="../assets/logo.png" width="28" alt="" v-if="isUp()" />
          <strong class="strokeme" v-if="isUp()">&nbsp;{{ appName }}</strong>
          <strong class="strokeme2" v-if="!isUp()">{{ appName }}</strong></span
        > -->
          <img
            src="../assets/candesic-2.png"
            @click="goToCandesic"
            title="Candesic.com"
            width="140"
          />
          <span class="strokeme" v-if="isUp()">{{ appName }}</span>
        </span>
      </b-navbar-brand>
      <AzureAuth
        class="lizard-navbar-azure"
        :mobileView="mobileView"
        v-if="isUp()"
      />
    </b-navbar>
  </div>
</template>

<script>
import AzureAuth from './Azure.vue'

export default {
  name: 'NavbarComponent',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AzureAuth
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    appName() {
      return this.Store.appName ?? 'default'
    },
    appVersion() {
      return this.Store.appVersion ?? '0.0.0'
    },
    appEnv() {
      return this.Store.appEnv ?? process?.env?.VUE_APP_ENV ?? 'production'
    },
    appMessage() {
      return this.Store.appMessage ?? ''
    },
    UserData() {
      return this.Store.userAuth
    },
    IsAuthenticated() {
      return this.UserData.authenticated
    }
  },
  data() {
    return {
      status:
        this?.$store?.getters?.appStatus ??
        process?.env?.VUE_APP_STATUS ??
        'up',
      env:
        this?.$store?.getters?.appEnv ??
        process?.env?.VUE_APP_ENV ??
        'production'
    }
  },
  methods: {
    isUp() {
      return this.status === 'up'
    },
    isPrd() {
      return ['staging', 'development'].indexOf(this.env) === -1
    },
    goToCandesic() {
      window.open('https://www.candesic.com')
    }
  }
}
</script>

<style scoped>
img {
  cursor: pointer;
}
pre {
  display: inline-flex;
  margin: 0 0 0 10px;
}
.strokeme {
  text-transform: capitalize;
  /* text-decoration: underline; */
  margin-left: 4px !important;
  /* color: #aae0fa; */
  color: #88d8f5;
  /* color: #3070b6; */
  /* color: #0272bc; */
  /* text-shadow: -1px -1px #fff; */
  /* letter-spacing: -0.03rem !important; */
  font-size: 14pt !important;
  font-weight: 600;
}
.strokeme2 {
  text-transform: capitalize;
  color: #ff0055;
  letter-spacing: -0.01rem !important;
  font-size: 20px !important;
  /* text-shadow: -1px -1px 0 #f4584d, 1px -1px 0 #f4584d, -1px 1px 0 #f4584d,
    1px 1px 0 #f4584d; */
  /* text-shadow: -1px -1px 0 #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc,
    1px 1px 0 #ccc; */
}
.lizard-navbar {
  min-height: 60px !important;
  background: rgba(255, 255, 255, 0.8);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(238, 238, 238, 1) 100%
  );
}
.lizard-navbar-azure {
  position: absolute;
  right: 2% !important;
}
</style>
