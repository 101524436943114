<template>
  <b-container fluid class="bg-white">
    <div class="row" v-if="clonedItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="clonedItems.length && showReportsPanel">
      <reports-box :expenseStatusOpts="expenseStatusOpts" />
    </div>
    <div class="row" v-if="clonedItems.length && showReportsPanel">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="clonedItems.length">
      <filters-bar />
    </div>
    <div class="row" v-if="!clonedItems.length">
      <div class="col-sm-5"></div>
      <div class="col-sm-2">
        <h6>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h6>
      </div>
      <div class="col-sm-5"></div>
    </div>
    <div class="row" v-if="clonedItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-12">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          selectable
          select-mode="range"
          selected-variant="info"
          @row-selected="rowsSelected"
          ref="expensesTable"
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          variant="secondary"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #table-caption
            ><b-badge
              variant="light"
              style="
                background-color: transparent;
                color: #999;
                text-transform: uppercase;
                font-size: 8pt;
              "
            >
              DISPLAYING {{ listItems.length }} RECORDS MATCHING SELECTED
              FILTERS
            </b-badge>
          </template>
          <template #cell(refCode)="data">
            <b-badge
              variant="info"
              v-if="data.item.refCode"
              style="cursor: default"
            >
              {{ data.item.refCode }}
            </b-badge>
          </template>
          <template #cell(projectCode)="data">
            <div
              v-if="data.item.project"
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to filter list by project code"
              class="selected-project"
              style="margin-bottom: 5px"
              :style="
                'background-color: ' + data.item.project.color + ' !important'
              "
              @click="UpdateSearchQuery(data.item.project.projectCode)"
            >
              {{ data.item.project.title }}
            </div>
            <div
              v-else
              v-b-tooltip.hover.bottom="{ variant: 'info' }"
              title="Click to filter list by project code"
              class="selected-project"
              style="margin-bottom: 5px; background-color: #333"
              @click="UpdateSearchQuery('CANDESIC')"
            >
              CANDESIC
            </div>
          </template>
          <template #cell(owner)="data">
            <b-link
              @click="UpdateSearchQuery(data.item.owner.fullName || '')"
              style="color: #666 !important"
            >
              {{ data.item.owner.fullName || '-' }}
            </b-link>
          </template>
          <template #cell(category)="data">
            <b-link
              @click="UpdateSearchQuery(data.item.fee.type || '')"
              style="color: #666 !important"
            >
              {{ data.item.fee.type || '-' }}
            </b-link>
          </template>
          <template #cell(amount)="data"
            >{{ CurrencySymbol[data.item.fee.currency]
            }}{{ parseFloat(data.item.fee.amount).toFixed(2) }}</template
          >
          <template #cell(vat)="data"
            >{{ CurrencySymbol[data.item.fee.currency]
            }}{{ parseFloat(data.item.fee.vat).toFixed(2) }}</template
          >
          <template #cell(notes)="data"
            ><span v-b-popover.hover.top="data.item.notes">{{
              summarize(data.item.notes)
            }}</span></template
          >
          <template #cell(updated)="data">
            {{ formatDate(data.item.updated) }}</template
          >
          <template #cell(created)="data">
            {{ formatDate(data.item.created) }}</template
          >
          <template #cell(expenseDate)="data">
            {{ formatDate(data.item.expenseDate) }}</template
          >
          <template #cell(attachments)="data">
            <ul class="lizard-attachment-list">
              <li v-if="data.item.attachments">
                <b-link @click="downloadReceipt(data.item.attachments)">
                  Download</b-link
                >
              </li>
            </ul>
          </template>
          <template #cell(status)="data">
            <b-form-select
              class="lizard-update-select"
              v-model="data.item.status"
              :options="expenseStatusOpts"
              @change="UpdateExpenseStatus(data.item)"
              size="sm"
            ></b-form-select> </template
        ></b-table>
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import ReportsBox from './expense-components/reports-box.vue'
import FiltersBar from './expense-components/filters-bar.vue'
import { format as formatDate } from 'date-fns'

export default {
  name: 'Expenses',
  components: {
    ReportsBox,
    FiltersBar
  },
  data() {
    return {
      showReportsPanel: true,
      q: null,
      pager: {
        perPage: 20,
        currentPage: 1
      },
      listFields: [
        { key: 'created', label: 'Submitted Date', sortable: true },
        // { key: 'refCode', label: 'Reference Code' },
        { key: 'expenseDate', label: 'Expense Date', sortable: true },
        { key: 'projectCode', label: 'Project' },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'owner', label: 'Person name', sortable: true },
        { key: 'manager', label: 'Manager', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'vat', label: 'VAT', sortable: true },
        { key: 'notes', label: 'Notes' },
        { key: 'attachments', label: 'Attachments' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'updated', label: 'Last Action Date', sortable: true }
      ],
      listItems: [],
      clonedItems: [],
      selectedItems: [],
      CurrencySymbol: {
        GBP: '£',
        USD: '$',
        EUR: '€'
      },
      expenseStatusOpts: [],
      exportExcel: true,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Expenses Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        Amount: 'amount',
        VAT: 'vat',
        Currency: 'currency',
        'GBP Amount (£)': 'gbpamount',
        Manager: 'approvalManager',
        Category: 'category',
        Project: 'projectCode',
        Description: 'description',
        'Expense Date': 'date',
        'Submitted Date': 'submitted',
        Status: 'status',
        'Paid Date': 'paidDate'
      },
      exportData: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    SearchQuery() {
      return this.Store.expenseSearchQuery
    },
    StatusOptions() {
      return this.Store.expensesStatusOptions
    },
    SelectedExpenseStatus() {
      return this.Store.selectedExpenseStatus
    },
    SelectedExpenseDateRange() {
      return this.Store.selectedExpenseDateRange
    }
  },
  mounted() {
    this.tabMounted()
  },
  methods: {
    async tabMounted() {
      this.q = null
      this.$store.commit('setExpenseStatusOptions', [])
      this.$store.commit('setSelectedExpenseStatus', [])
      await this.fetchMetas()
      this.listItems = await this.getExpenses()
      this.clonedItems = [...this.listItems]
    },
    async fetchMetas() {
      const { status, data } = await Services.Projects.Admin.getMetas()
      if (status === 200) {
        const {
          expenses: { status: expensesList }
        } = data.content.metas
        this.expenseStatusOpts = expensesList
      }
    },
    statusOptionsUpdated(list) {
      this.$store.commit('setExpenseStatusOptions', list)
    },
    async getExpenses() {
      const { data, status } = await ServicesOld.GetExpenses(true)
      if (status === 200) {
        data.content = data.content.map((itm) => {
          itm.status = itm.status.toUpperCase()
          itm.owner.fullName = `${itm.owner?.firstName ?? '?'} ${
            itm.owner?.lastName ?? '?'
          }`.trim()
          itm.manager = `${itm?.manager?.firstName ?? ''} ${
            itm?.manager?.lastName ?? ''
          }`.trim()
          return itm
        })
        return data.content.sort((a, b) => {
          return new Date(b.created) - new Date(a.created)
        })
      }
    },
    currencySymbol(currency) {
      let result = ''
      switch (currency.toLowerCase()) {
        case 'usd':
          result = '$'
          break
        case 'eur':
          result = '€'
          break
        default:
          result = '£'
      }
      return result
    },
    categoryLabel(cid) {
      return this.categories[cid] ?? 'n/a'
    },
    calcAmount({ currency, amount }) {
      let toGBP = ''
      return (
        this.currencySymbol(currency) + parseFloat(amount).toFixed(2) + toGBP
      )
    },
    GetExpenseStatusVariant(status = 'pending') {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'info'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'paid':
          variant = 'success'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    UpdateExpenseStatus({ uid, status }) {
      const bulkEditIsActive = this.selectedItems.length
      if (bulkEditIsActive) {
        this.selectedItems.forEach((uid, idx) => {
          ServicesOld.UpdateExpenseByAdmin(uid, {
            status
          }).then(() => {
            const itmIndex = this.listItems.findIndex((itm) => itm.uid === uid)
            this.listItems[itmIndex].status = status
            if (idx === bulkEditIsActive - 1) {
              this.selectedItems = []
              this.$refs.expensesTable.clearSelected()
            }
          })
        })
      } else {
        ServicesOld.UpdateExpenseByAdmin(uid, {
          status
        })
      }
    },
    async downloadReceipt(fileName) {
      if (fileName === null) {
        return
      }
      console.log({ fileName })
      return await ServicesOld.DownloadExpenseBill(fileName)
    },
    async removeBill(itm) {
      itm.attachments = null
    },
    async uploadBill(ev) {
      this.uploading = true
      const { data, status } = await ServicesOld.UploadExpenseBill(
        ev.target.files[0]
      )
      if (status === 200) {
        const itmId = ev.target.attributes['itmId'].value
        let itm = {}
        this.listItems.forEach((listItm) => {
          if (itmId === listItm.uid) {
            itm = listItm
          }
        })
        delete itm.action
        itm.attachments = data.content.fileName
        await ServicesOld.UpdateExpense(itmId, {
          attachmentKeys: itm.attachments
        })
        this.uploading = false
      } else {
        this.uploading = false
      }
    },
    rowsSelected(rows) {
      this.selectedItems = []
      rows.forEach((itm) => {
        this.selectedItems.push(itm.uid)
      })
    },
    FilterExpenses(q = this.SearchQuery) {
      if (q && q.trim().length > 1) {
        const keywords = q
          .trim()
          .split(' ')
          .map((keyword) => keyword.trim())
        this.listItems = this.clonedItems.filter((itm) => {
          const fullName = `${itm?.owner?.firstName?.trim() ?? ''} ${
            itm?.owner?.lastName?.trim() ?? ''
          }`
          const ownerEmail = itm?.owner?.email?.trim()?.split('@')?.[0] ?? ''
          const projectCode = itm?.project?.projectCode?.trim() ?? 'CANDESIC'
          const projectTitle = itm?.project?.title?.trim() ?? ''
          const category = itm?.fee?.type?.trim() ?? ''
          const refCode = itm?.refCode?.trim() ?? ''
          return keywords.every((keyword) => {
            const qRegEx = new RegExp(keyword, 'i')
            return (
              qRegEx.test(fullName) ||
              qRegEx.test(ownerEmail) ||
              qRegEx.test(projectCode) ||
              qRegEx.test(projectTitle) ||
              qRegEx.test(category) ||
              qRegEx.test(refCode)
            )
          })
        })
      } else {
        this.listItems = this.clonedItems
      }
      this.listItems = this.filterByStatus(this.listItems)
      this.listItems = this.filterByDate(this.SelectedExpenseDateRange)
    },
    filterByStatus(list) {
      return this.SelectedExpenseStatus.length
        ? list.filter((itm) => {
            return this.SelectedExpenseStatus.includes(itm.status)
          })
        : []
    },
    filterByDate({ year, months, dateLabel }) {
      return this.listItems.filter((itm) => {
        const dt = formatDate(
          new Date(
            dateLabel === 'Filter by Expense Date'
              ? itm.expenseDate
              : itm.created
          ),
          'yyyy,MMMM'
        ).split(',')
        return dt[0] == year && months.includes(dt[1])
      })
    },
    UpdateSearchQuery(q) {
      this.$root.$emit('expenseSearchQueryChanged', q)
    },
    summarize(note) {
      if (!note) {
        return ''
      }
      note = note.trim()
      if (note.length > 20) {
        return note.substring(0, 20) + '...'
      } else {
        return note
      }
    },
    generateExcel() {
      try {
        this.exportName = `expenses-report.${this.exportType}`
        this.exportData = []
        this.listItems.forEach((itm, idx) => {
          this.exportData.push({
            no: idx + 1,
            name: (itm.owner.firstName + ' ' + itm.owner.lastName).trim(),
            email: itm.owner.email,
            approvalManager: itm.manager.trim(),
            amount: parseFloat(itm.fee.amount).toFixed(2),
            vat: parseFloat(itm.fee.vat).toFixed(2),
            currency: itm.fee.currency,
            category: itm.fee.type,
            // gbpamount: String(this.calcAmount(itm.fee, true, true)).substr(1),
            projectCode: itm.project?.projectCode ?? 'CANDESIC EXPENSES',
            description: itm.notes,
            date: this.formatDate(itm.expenseDate),
            submitted: this.formatDate(itm.created),
            status: itm.status,
            paidDate:
              itm.status === 'PAID' ? this.formatDate(itm.updated) : 'NOT-PAID'
          })
        })
      } catch (Exception) {
        console.log('ERRROR"')
        console.log(Exception)
      }
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    },
    formatDate(dt) {
      return formatDate(new Date(dt), 'dd/MM/yyyy')
    }
  },
  watch: {
    SearchQuery: {
      handler() {
        this.FilterExpenses()
      },
      immediate: true
    },
    listItems: {
      handler() {
        this.list = this.listItems
      },
      deep: true
    },
    SelectedExpenseStatus: {
      handler() {
        this.FilterExpenses()
      },
      immediate: true
    },
    SelectedExpenseDateRange: {
      handler() {
        this.FilterExpenses()
      },
      immediate: true
    },
    expenseStatusOpts: {
      handler: 'statusOptionsUpdated',
      immediate: true
    }
  }
}
</script>

<style>
.cost-details-btn {
  font-size: 9pt !important;
  font-weight: bold;
  color: #6cf;
}
.lizard-column-fees {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 12px !important;
  font-weight: 100 !important;
}
.lizard-column-fees-disabled {
  color: #999;
}
.custom-link {
  color: '#999' !important;
}
.custom-link:hover {
  color: '#666' !important;
  text-decoration: underline !important;
}
</style>

<style scoped>
.lizard-expense-status {
  font-variant: small-caps;
  font-weight: bolder;
  font-size: 12pt;
  letter-spacing: 0.03em;
  padding: 0 8px 4px 8px;
  margin: 0 auto;
}
.lizard-expense-status:hover {
  cursor: pointer;
}
.export-excel-button {
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(40, 128, 5);
  border-radius: 2px;
  background-color: transparent;
  color: rgb(40, 128, 5);
  margin: 10px 0;
  padding: 2px;
  width: 10%;
  max-width: 10%;
  float: right;
}
.export-excel-button:hover {
  background-color: rgb(40, 128, 5, 0.6);
  color: #fff;
  cursor: pointer;
}
.export-excel-button:active {
  background-color: rgb(40, 128, 5, 0.8);
  color: #ccc;
}
.lizard-activity-list {
  font-size: 12px !important;
  color: #666;
  font-weight: bold !important;
}
.lizard-column-index {
  text-align: center !important;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-reports-stats li {
  display: inline-block;
}
.lizard-hourly-rate-input {
  text-align: center;
  width: 60px;
  max-width: 60px;
}
.lizard-date-input {
  text-align: center;
  width: 90px;
  max-width: 90px;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
