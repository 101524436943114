<template>
  <b-container fluid class="bg-white">
    <div class="row" v-if="showTable">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <b-table
          caption-top
          responsive
          fixed
          striped
          small
          borderless
          table-class="lizard-activity-list lizard-expenses-box"
          thead-class="label"
          tbody-class="value"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
          :busy="loading"
        >
          <template #table-caption>Submitted Requests</template>
          <template #cell(submitted)="data">
            {{ formatDate(data.item.submitted) }}
          </template>
          <template #cell(startDate)="data">
            {{ formatDate(data.item.startDate) }}
          </template>
          <template #cell(endDate)="data">
            {{ formatDate(data.item.endDate) }}
          </template>
          <template #cell(type)="data">
            {{ data.item.type.toUpperCase() }}
          </template>
          <template #cell(notes)="data">
            <span v-b-popover.hover.top="data.item.comments.user">
              {{ summarize(data.item.comments.user || '') }}
            </span>
          </template>
          <template #cell(comment)="data">
            <span v-b-popover.hover.top="data.item.comments.manager">
              {{ summarize(data.item.comments.manager || '') }}
            </span>
          </template>
          <template #cell(status)="data">
            <h5>
              <b-badge
                class="lizard-holiday-status"
                :variant="GetHolidayStatusVariant(data.item.status)"
              >
                {{ data.item.status.toUpperCase() }}
              </b-badge>
            </h5>
          </template>
          <template #cell(refCode)="data">
            <b-badge
              variant="info"
              v-if="data.item.refCode"
              style="cursor: pointer"
              title="Click to copy"
              @click="copyRefCode(data.item)"
            >
              {{ data.item.refCode }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            <b-link @click="doRemove(data.value.id)" v-if="data.value.id"
              ><b-icon
                icon="x-square-fill"
                variant="danger"
                title="Remove"
              ></b-icon
            ></b-link>
          </template>
        </b-table>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div
      class="row"
      v-if="listItems.length > pager.perPage && !loading && showTable"
    >
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row" v-if="loading">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <h6>
          <b-badge variant="secondary">Please wait...</b-badge>
        </h6>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row" v-if="!loading && !showTable">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <h6>
          <b-badge variant="secondary"
            >There is not any Holiday requests to show!</b-badge
          >
        </h6>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </b-container>
</template>

<script>
import ServicesOld from '../../../services/index.vue'
import Services from '../../../services/main.service.vue'
import { format as dateFormat, differenceInBusinessDays } from 'date-fns'

export default {
  name: 'HolidayList',
  props: {
    selectedYear: {
      type: Number,
      required: true
    },
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    listFields() {
      const flds = [
        {
          key: 'submitted',
          label: 'Submitted Date',
          sortable: true,
          thClass: 'w10 aleft',
          tdClass: 'w10 aleft',
          _showInMobileView: false
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
          thClass: 'w40 aleft',
          tdClass: 'w40 aleft',
          _showInMobileView: true
        },
        {
          key: 'startDate',
          label: 'From',
          sortable: true,
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'endDate',
          label: 'To',
          sortable: true,
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        },
        {
          key: 'totalDays',
          label: 'Days requested',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'notes',
          label: 'Notes',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        {
          key: 'comment',
          label: 'Manager comment',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: false
        },
        // {
        //   key: 'refCode',
        //   label: 'Reference Code',
        //   thClass: 'w10',
        //   tdClass: 'w10',
        //   _showInMobileView: false
        // },
        {
          key: 'status',
          label: 'Status',
          thClass: 'w10',
          tdClass: 'w10',
          _showInMobileView: true
        }
      ]
      return flds.filter((fld) => {
        return this.mobileView ? fld._showInMobileView : true
      })
    }
  },
  data: () => {
    return {
      loading: true,
      showTable: true,
      listItems: [],
      pager: {
        currentPage: 1,
        perPage: 10
      }
    }
  },
  async mounted() {
    this.$root.$on('parent_tab_holidays_selected', this.tabMounted)
    this.$root.$on('saved', this.tabMounted)
  },
  methods: {
    async tabMounted() {
      this.loading = true
      this.listItems = await this.GetList()
      this.loading = false
      this.showTable = this.listItems.length
      this.$store.commit('setApprovedHolidays', this.listItems)
    },
    summarize(note) {
      note = note.trim()
      if (note.length > 20) {
        return note.substring(0, 20) + '...'
      } else {
        return note
      }
    },
    async GetList() {
      let { status, data } = await ServicesOld.GetHolidays(null, {
        year: this.selectedYear
      })
      if (status === 200) {
        return data.content
          .map((itm) => {
            if (itm.type.toLowerCase() === 'half-day' && itm.meridiem) {
              itm.type = itm.type + ' (' + itm.meridiem.toUpperCase() + ')'
            }
            switch (true) {
              case itm.type.toLowerCase() === 'annual holiday':
                itm.type = 'Holiday'
                break
              case /^half\-day.+/.test(itm.type.toLowerCase()):
                itm.type = itm.type + ' Holiday'
                break
            }
            return itm
          })
          .sort((a, b) => {
            if (a.submitted > b.submitted) return -1
            if (a.submitted < b.submitted) return 1
            return 0
          })
      } else {
        return []
      }
    },
    async doRemove(id) {
      this.deleteInProgress = true
      this.deleteItemId = id
    },
    GetHolidayStatusVariant(status) {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'approved':
          variant = 'success'
          break
        case 'rejected':
          variant = 'danger'
          break
        case 'pending':
          variant = 'warning'
          break
        case 'failed':
          variant = 'dark'
          break
      }
      return variant
    },
    copyRefCode({ refCode = null }) {
      if (refCode === null) {
        return
      }
      navigator.clipboard.writeText(refCode).then(
        () => {
          this.$toasted.show('Reference code copied to clipboard', {
            type: 'info',
            duration: 3000
          })
        },
        function (err) {
          console.error('Could not copy text: ', err)
        }
      )
    },
    formatDate(dt) {
      return dateFormat(new Date(dt), 'dd/MM/yyyy')
    }
  },
  watch: {
    selectedYear: {
      handler: 'tabMounted',
      immediate: true
    }
  }
}
</script>

<style>
.lizard-holiday-status {
  font-variant: small-caps;
  font-weight: bolder;
  font-size: 10pt !important;
  letter-spacing: 0.03em;
  padding: 4px 8px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.lizard-holidays-box {
  background-color: rgba(200, 200, 200, 0.1);
  border: 1px solid #eee;
  border-radius: 3px;
}
.lizard-holidays-box .label {
  color: #fff;
  text-align: center;
  padding: 4px;
  border-bottom: 1px solid #eee;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 30px;
  background-color: #66ccff;
}
.lizard-holidays-box .value {
  color: #666;
  text-align: center;
  padding: 4px;
  font-size: 10pt;
  height: 40px;
  line-height: 30px;
}
.lizard-holidays-box .w10 {
  width: auto !important;
}
.lizard-holidays-box .w40 {
  width: 20% !important;
}
.lizard-holidays-box .aleft {
  text-align: left;
}
</style>
