import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=445de717&scoped=true"
import script from "./Projects.vue?vue&type=script&lang=js"
export * from "./Projects.vue?vue&type=script&lang=js"
import style0 from "./Projects.vue?vue&type=style&index=0&id=445de717&prod&lang=css"
import style1 from "./Projects.vue?vue&type=style&index=1&id=445de717&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445de717",
  null
  
)

export default component.exports