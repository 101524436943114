<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row">
      <div class="col-lg"></div>
      <div class="col-lg-11">
        <div class="row">
          <div class="col-lg-10 text-right">
            <b-form-input
              size="sm"
              v-model="q"
              placeholder="Search..."
            ></b-form-input>
          </div>
          <div class="col-lg-2 text-left">
            <b-button
              size="sm"
              variant="info"
              v-if="filters.projectType.selected == 'PROJECT'"
              @click="addNew"
              >{{ addNewBtnTxt }}</b-button
            >
          </div>
        </div>
      </div>
      <div class="col-lg"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="!listItems.length">
      <div class="col-lg-12 text-center">
        <h5>
          <b-badge variant="secondary"
            >There isn't any records to show here!</b-badge
          >
        </h5>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-12">
        <b-table
          fixed
          striped
          small
          borderless
          responsive
          caption-top
          sort-icon-left
          table-class="lizard-activity-list lizard-candesic-table"
          thead-class="label"
          tbody-class="value"
          :busy.sync="loading"
          :items="listItems"
          :fields="listFields"
          :per-page="pager.perPage"
          :current-page="pager.currentPage"
        >
          <template #cell(color)="data">
            <div
              v-if="!data.item._edit"
              @click="toggleEdit(data.item)"
              class="lizard-color-btn"
              :style="
                'background-color: ' +
                (data.item.meta.color || '#000') +
                ' !important'
              "
            ></div>
            <span v-if="data.item._edit">
              <b-form-input
                type="color"
                class="lizard-color-btn"
                @change="updateProject(data.item, 'color')"
                v-model="data.item.meta.color"
                :style="
                  'background-color: ' +
                  (data.item.meta.color || '#000') +
                  ' !important'
                "
              ></b-form-input>
            </span>
          </template>
          <template #cell(code)="data">
            <code
              style="font-size: 12px; color: #666"
              v-if="!data.item._edit && data.item.type == 'project'"
              >{{ data.item.code }}</code
            >
            <span v-if="data.item._edit">
              <b-form-input
                type="text"
                v-model="data.item.code"
                size="sm"
              ></b-form-input>
            </span>
          </template>
          <template #cell(title)="data">
            <span v-if="!data.item._edit">{{ data.item.title }}</span>
            <span v-if="data.item._edit">
              <b-form-input
                type="text"
                size="sm"
                v-model="data.item.title"
              ></b-form-input>
            </span>
          </template>
          <template #cell(client)="data">
            <span v-if="!data.item._edit">{{ data.item.client }}</span>
            <span v-if="data.item._edit">
              <b-form-input
                type="text"
                size="sm"
                v-model="data.item.client"
              ></b-form-input>
            </span>
          </template>
          <template #cell(partners)="data">
            <span
              v-if="!data.item._edit"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="getPartnerLabel(data.item.meta.partner, false)"
              >{{ getPartnerLabel(data.item.meta.partner) }}</span
            >
            <span v-if="data.item._edit">
              <b-form-select
                v-model="data.item.meta.partner"
                :options="metas.partners"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >select one</b-form-select-option
                  >
                </template>
              </b-form-select></span
            >
          </template>
          <template #cell(ems)="data">
            <span
              v-if="!data.item._edit"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="getEMSLabel(data.item.meta.ems, false)"
              >{{ getEMSLabel(data.item.meta.ems) }}</span
            >
            <span v-else>
              <b-form-select
                v-model="data.item.meta.ems"
                :options="ems"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >select one</b-form-select-option
                  >
                </template>
              </b-form-select></span
            >
          </template>
          <template #cell(status)="data">
            <h5
              v-if="!data.item._new && !data.item._edit"
              @click="updateProject(data.item, 'status')"
              style="cursor: pointer"
            >
              <b-badge :variant="GetProjectStatusVariant(data.item.status)">{{
                data.item.status
              }}</b-badge>
            </h5>
            <b-badge
              v-else
              variant="dark"
              title="System projects cannot be deactivated"
              >NOT AVAILABLE ON EDIT MODE</b-badge
            >
          </template>
          <template #cell(active)="data">
            <ul class="actions">
              <!-- <li v-if="data.item.type == 'project'">REMOVE</li> -->
              <li v-if="data.item.meta.system">
                <h5 style="cursor: default">
                  <b-badge
                    variant="dark"
                    title="System projects cannot be deactivated"
                    >SYSTEM</b-badge
                  >
                </h5>
              </li>
              <li v-if="!data.item.meta.system">
                <b-form-checkbox
                  switch
                  v-if="!data.item._new && !data.item._edit"
                  v-model="data.item.active"
                  name="activeProject"
                  size="lg"
                  :disabled="data.item.meta.system"
                  @change="
                    updateProject(
                      data.item.meta.system ? null : data.item,
                      'active'
                    )
                  "
                >
                </b-form-checkbox>
                <b-badge
                  v-else
                  variant="dark"
                  title="System projects cannot be deactivated"
                  >NOT AVAILABLE ON EDIT MODE</b-badge
                >
              </li>
            </ul>
          </template>
          <template #cell(actions)="data">
            <ul
              class="actions"
              v-if="data.item.type === 'project' && !data.item._new"
            >
              <li v-if="!data.item._edit">
                <b-link
                  ><el-button
                    icon="el-icon-edit"
                    size="mini"
                    @click="toggleEdit(data.item)"
                    style="
                      background-color: transparent;
                      border: 0;
                      font-size: 12pt;
                    "
                    v-b-tooltip.hover.bottom="{ variant: 'dark' }"
                    :title="'Click to modify ' + data.item.title"
                  ></el-button
                ></b-link>
              </li>
              <li v-else>
                <b-link
                  ><el-button
                    icon="el-icon-check"
                    style="
                      background-color: transparent;
                      border: 0;
                      font-size: 12pt;
                    "
                    size="mini"
                    v-b-tooltip.hover.bottom="{ variant: 'dark' }"
                    title="Save changes"
                    @click="updateProject(data.item)"
                  ></el-button
                ></b-link>
              </li>
            </ul>
          </template>
          <template #cell(visibility)="data">
            <b-form-checkbox
              v-model="data.item.active"
              button-variant="outline-warning"
              button
              :disabled="data.item.meta.system"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="
                'Click to ' +
                (data.item.active ? 'hide' : 'show') +
                ' in staffing calendar'
              "
              @change="
                updateProject(
                  data.item.meta.system ? null : data.item,
                  'active'
                )
              "
              >staffing</b-form-checkbox
            >
            <b-form-checkbox
              v-model="data.item.status"
              button-variant="outline-success"
              button
              :disabled="data.item.meta.system"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="
                'Click to ' +
                (data.item.status ? 'disable' : 'enable') +
                ' time tracking'
              "
              @change="
                updateProject(
                  data.item.meta.system ? null : data.item,
                  'status'
                )
              "
              >timesheet</b-form-checkbox
            >
          </template>
          <template #cell(onTrack)="data">
            <b-form-checkbox
              v-model="data.item.onTrack"
              button-variant="outline-danger"
              button
              :disabled="data.item.meta.system"
              v-b-tooltip.hover.bottom="{ variant: 'dark' }"
              :title="
                'Click to ' +
                (data.item.onTrack ? 'disable' : 'enable') +
                ' tracking mode'
              "
              @change="
                updateProject(
                  data.item.meta.system ? null : data.item,
                  'onTrack'
                )
              "
              >Tracking</b-form-checkbox
            >
          </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg">
        <b-pagination
          v-model="pager.currentPage"
          :total-rows="listItems.length"
          :per-page="pager.perPage"
          aria-controls="activityTable"
          size="sm"
          pills
          align="center"
        ></b-pagination>
      </div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div class="row" v-if="listItems.length">
      <div class="col-lg-7"></div>
      <div class="col-lg-3 text-right">
        <download-excel
          :before-generate="generateExcel"
          :before-finish="resetExportData"
          :type="exportType"
          :name="exportName"
          :fields="exportFields"
          :data="exportData"
          :worksheet="exportWorksheet"
          v-if="exportExcel"
        >
          <b-button variant="outline-success" size="sm"
            ><b-icon
              icon="file-earmark-excel-fill"
              title="Export Excel"
            ></b-icon>
            Export Excel</b-button
          >
        </download-excel>
      </div>
      <!-- <div class="col-lg-2 text-left">
        <b-button variant="info" size="sm" @click="saveChanges"
          >Save Changes</b-button
        >
      </div> -->
    </div>
    <b-modal
      id="modal-color-pallet"
      hide-backdrop
      hide-footer
      no-fade
      :header-bg-variant="colorPalletModal.headerBgVariant"
      :header-text-variant="colorPalletModal.headerTextVariant"
      :body-bg-variant="colorPalletModal.bodyBgVariant"
      :body-text-variant="colorPalletModal.bodyTextVariant"
      :footer-bg-variant="colorPalletModal.footerBgVariant"
      :footer-text-variant="colorPalletModal.footerTextVariant"
      title="Colour Pallet"
    >
      <p class="my-4">
        <color-picker @input="setColor" />
      </p>
    </b-modal>
  </b-container>
</template>

<script>
import Services from '../../../services/main.service.vue'

export default {
  name: 'ProjectList',
  props: ['metas', 'listItems', 'ems', 'showFeeCheckbox', 'showProjectFilter'],
  data() {
    return {
      q: null,
      loading: false,
      pager: {
        currentPage: 1,
        perPage: 12
      },
      filters: {
        projectType: {
          selected: 'PROJECT',
          opts: []
        }
      },
      metaData: {},
      listFields: [
        {
          key: 'actions',
          label: 'Modify',
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-actions',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-actions'
        },
        {
          key: 'color',
          label: 'Colour',
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-color',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-color'
        },
        {
          key: 'code',
          label: 'Project Code',
          sortable: true,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-code',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-code'
        },
        {
          key: 'title',
          label: 'Asset Name',
          sortable: true,
          thClass:
            'lizard-projects-tbl-cols lizard-projects-tbl-cols-title text-center',
          tdClass:
            'lizard-projects-tbl-cols lizard-projects-tbl-cols-title text-left'
        },
        {
          key: 'client',
          label: 'Client Name',
          sortable: true,
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-client',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-client'
        },
        {
          key: 'partners',
          label: 'Partner',
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-partners',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-partners'
        },
        {
          key: 'ems',
          label: 'Manager',
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-ems',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-ems'
        },
        // {
        //   key: 'status',
        //   label: 'Status (Active)',
        //   sortable: true,
        //   thClass: 'lizard-projects-tbl-cols',
        //   tdClass: 'lizard-projects-tbl-cols'
        // },
        // {
        //   key: 'active',
        //   label: 'Show in Staffing',
        //   sortable: true,
        //   thClass: 'lizard-projects-tbl-cols',
        //   tdClass: 'lizard-projects-tbl-cols'
        // },
        {
          key: 'visibility',
          label: 'Show in calendars',
          thClass:
            'lizard-projects-tbl-cols lizard-projects-tbl-cols-visibility',
          tdClass:
            'lizard-projects-tbl-cols lizard-projects-tbl-cols-visibility'
        },
        {
          key: 'onTrack',
          label: 'Costs Tracking',
          thClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-code',
          tdClass: 'lizard-projects-tbl-cols lizard-projects-tbl-cols-code'
        }
      ],
      list: [],
      toEdit: null,
      edit: {},
      visibilityOpts: [
        { text: 'Staffing', value: 'staffing' },
        { text: 'Timesheet', value: 'timesheet' }
      ],
      exportExcel: false,
      exportType: 'xls',
      exportName: 'data.xls',
      exportWorksheet: "Candesic's Projects Report",
      exportFields: {
        No: 'no',
        Name: 'name',
        Email: 'email',
        'Hourly Rate': 'hourlyRate'
      },
      exportData: [],
      colorPalletModal: {
        headerBgVariant: 'dark',
        headerTextVariant: 'light',
        bodyBgVariant: 'dark',
        bodyTextVariant: 'light',
        footerBgVariant: 'dark',
        footerTextVariant: 'light',
        selectedColor: '#000'
      },
      addNewBtnTxt: 'Add New Project',
      SaveBtnTxt: 'Save',
      onEdit: {
        uid: null,
        field: null,
        value: {},
        editting: false
      },
      beforeUpdateClonedProject: null,
      totalActiveProjectsAllowence: 20
    }
  },
  methods: {
    toggleEdit({ uid, type, _edit = true }) {
      if (type !== 'project') {
        return
      }
      const listIndex = this.listItems.findIndex((itm) => itm.uid === uid)
      this.listItems[listIndex]._edit = !_edit
    },
    GetProjectStatusVariant(status) {
      let variant = 'light'
      switch (status.toLowerCase()) {
        case 'live':
        case 'bd':
        case 'pitching':
        case 'lop sent':
        case 'lop writing':
          variant = 'success'
          break
        case 'on hold':
          variant = 'info'
          break
        case 'completed':
          variant = 'primary'
          break
        case 'old':
          variant = 'secondary'
          break
        default:
          variant = 'dark'
          break
      }
      return variant
    },
    stripHtml(html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText
    },
    async updateProject(project, field = null) {
      if (!project || !project.uid) return
      const toUpdate = {}
      const itemIndex = this.listItems.findIndex(
        (itm) => itm.uid === project.uid
      )
      let itemToUpdate = this.listItems[itemIndex]
      // console.log({ itemToUpdate })
      if (itemToUpdate._new) {
        return
      }
      let fieldsToUpdate = [
        'code',
        'title',
        'client',
        'status',
        'color',
        'partner',
        'ems',
        'active',
        'onTrack'
      ]
      if (field) {
        fieldsToUpdate = fieldsToUpdate.filter((fld) => fld === field)
      }
      fieldsToUpdate.forEach((field) => {
        switch (field) {
          case 'code':
            toUpdate['projectCode'] = itemToUpdate[field]
            break
          case 'title':
          case 'client':
            toUpdate[field] = String(this.stripHtml(itemToUpdate[field])).trim()
            break
          case 'status':
            toUpdate[field] = itemToUpdate[field] ? 'CLOSED' : 'LIVE'
            break
          case 'onTrack':
            toUpdate[field] = Boolean(itemToUpdate[field])
            break
          case 'color':
          case 'partner':
          case 'ems':
            toUpdate.meta = toUpdate.meta || {}
            toUpdate.meta[field] = itemToUpdate.meta[field]
            break
          case 'active':
            const totalActiveProjects = this.listItems.filter(
              (itm) => itm.active === true
            ).length
            if (
              itemToUpdate[field] &&
              totalActiveProjects > this.totalActiveProjectsAllowence
            ) {
              itemToUpdate[field] = !itemToUpdate[field]
              this.$bvToast.toast(
                `You can only have ${this.totalActiveProjectsAllowence} active projects at a time`,
                {
                  title: 'Error',
                  variant: 'danger',
                  solid: true
                }
              )
              return
            }
            toUpdate[field] = itemToUpdate[field]
            break
          default:
            toUpdate[field] = parseFloat(
              parseFloat(itemToUpdate[field]).toFixed(2)
            )
        }
      })
      const upd = await this.saveChanges(itemToUpdate, toUpdate)
      if (upd) {
        const updObj = {
          _edit: false
        }
        if (Object.keys(toUpdate).includes('status')) {
          const nextMetaIndex = toUpdate.status === 'LIVE' ? 0 : 1
          this.listItems[itemIndex].status =
            this.metas.status[nextMetaIndex].key === 'LIVE'
        }
        itemToUpdate = { ...itemToUpdate, ...updObj }
        // this.$bvToast.toast('Project updated successfully', {
        //   title: 'Success',
        //   variant: 'success',
        //   solid: true
        // })
      } else {
        consoe.log(upd)
      }
      if (!field) {
        this.toggleEdit(project)
      }
    },
    async saveChanges(itemToUpdate, data = {}) {
      try {
        if (typeof itemToUpdate.uid === 'undefined' || !itemToUpdate.uid) {
          return
        }
        const { status, error } = await Services.Projects.Admin.update(
          itemToUpdate.uid,
          data
        )
        return status == 200 ? true : error
      } catch (Exception) {
        throw Exception
      }
    },
    generateExcel() {
      const dt = this.$moment().format('YYYY-MM-DD')
      this.exportName = `projects-list-report-${dt}.${this.exportType}`
      this.exportData = this.listItems.map((itm, idx) => {
        itm.no = idx + 1
        return itm
      })
    },
    resetExportData() {
      this.exportName = `data.${this.exportType}`
      this.exportData = []
    },
    addNew() {
      if (this.addNewBtnTxt === 'Add New Project') {
        this.$root.$emit('add-new', 'project')
        this.addNewBtnTxt = 'Save'
      } else {
        this.$root.$emit('save-new', 'project')
        setTimeout(() => {
          if (!this.hasNewItemToAdd()) {
            this.addNewBtnTxt = 'Add New Project'
            this.$root.$emit('tab_projects_refresh')
          }
        }, 500)
      }
    },
    updateField(field, item) {
      let value = item[field]
      if (field === 'created') {
        value = this.$moment(value).format('YYYY-MM-DD')
      }
      if (field === 'color') {
        value = item.meta[field]
      }
      this.onEdit = {
        uid: item.uid,
        field,
        value,
        editting: true
      }
    },
    updateItem({ uid: itemUid }) {
      const listIndex = this.listItems.findIndex(({ uid }) => uid === itemUid)
      this.listItems[listIndex]._edit = !this.listItems[listIndex]._edit
      if (this.listItems[listIndex]._edit) {
        // EDIT
        this.beforeUpdateClonedProject = JSON.parse(
          JSON.stringify(this.listItems[listIndex])
        )
      } else {
        // SAVE
        this.$root.$emit('update-project', this.listItems[listIndex])
        this.beforeUpdateClonedProject = null
      }
    },
    hasNewItemToAdd() {
      return this.listItems.find((itm) => itm._new) ? true : false
    },
    setColor(color) {
      this.onEdit.value = color
      this.onEdit.editting = false
    },
    getPartnerLabel(partner, short = true) {
      const partnerObj = this.metas.partners.find(
        (itm) => itm.value === partner
      )
      return short ? partnerObj?.value : partnerObj?.text
    },
    getEMSLabel(ems, short = true) {
      const emsName = this.ems.find((itm) => itm.value === ems)
      return short
        ? emsName?.text
            .split(' ')
            .map((itm) => itm[0])
            .join('')
        : emsName?.text
    },
    getSectorLabel(sector) {
      return this.metas.sectors.find((itm) => itm.value === sector)?.text
    },
    refreshList() {
      this.filters.projectType.selected = 'PROJECT'
      this.addNewBtnTxt = 'Add New Project'
      this.$root.$emit('tab_projects_refresh')
    }
  },
  watch: {
    q: {
      handler(q) {
        this.$root.$emit('search-project', q)
      },
      deep: true
    },
    metas: {
      handler() {
        this.metaData = this.metas
        this.filters.projectType.opts = this.metaData.types
          .map((itm) => {
            return {
              value: itm.key,
              text: itm.label
            }
          })
          .splice(0, 2)
      },
      deep: true
    },
    'filters.projectType.selected': {
      handler() {
        this.$root.$emit(
          'filter-project-type',
          this.filters.projectType.selected
        )
      },
      deep: true
    },
    'onEdit.editting': {
      handler() {
        const listIndex = this.listItems.findIndex(
          ({ uid }) => uid === this.onEdit.uid
        )
        const beforeUpdateClone = JSON.parse(
          JSON.stringify(this.listItems[listIndex])
        )
        this.listItems[listIndex]._edit = this.onEdit.editting
      },
      deep: true
    }
  }
}
</script>

<style>
caption {
  padding: 0 !important;
  margin: 50px 0 0 0 !important;
  max-height: 40px;
}
caption div {
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block;
}
caption div .search {
  width: 200px !important;
}
caption .custom-control-label {
  line-height: 32px !important;
  font-size: 14px !important;
  color: #ccc;
}
.lizard-column {
  overflow: auto !important;
}

.btn-outline-danger,
.btn-outline-warning,
.btn-outline-success {
  min-width: 80px !important;
  max-height: 30px !important;
  padding: 0 7px !important;
  margin: 0 1px !important;
  line-height: 30px !important;
  border: 0 !important;
  border-radius: 2px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
}

.lizard-projects-tbl-cols {
  font-size: 12px !important;
  font-weight: bold !important;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
}
.lizard-projects-tbl-cols-actions {
  /* 5 */
  min-width: calc(100vw / 20) !important;
  max-width: calc(100vw / 20) !important;
}
.lizard-projects-tbl-cols-color {
  /* 5 */
  min-width: calc(100vw / 20) !important;
  max-width: calc(100vw / 20) !important;
}
.lizard-projects-tbl-cols-code {
  /* 10 * 2 = 20 */
  min-width: calc(100vw / 10) !important;
  max-width: calc(100vw / 10) !important;
}
.lizard-projects-tbl-cols-title {
  /* 12.5 */
  min-width: calc(100vw / 8) !important;
  max-width: calc(100vw / 8) !important;
}
.lizard-projects-tbl-cols-client {
  /* 10 */
  min-width: calc(100vw / 10) !important;
  max-width: calc(100vw / 10) !important;
}
.lizard-projects-tbl-cols-partners {
  /* 8.33 */
  min-width: calc(100vw / 12) !important;
  max-width: calc(100vw / 12) !important;
}
.lizard-projects-tbl-cols-ems {
  /* 8.33 */
  min-width: calc(100vw / 12) !important;
  max-width: calc(100vw / 12) !important;
}
.lizard-projects-tbl-cols-visibility {
  /* 14.28 */
  min-width: calc(100vw / 7) !important;
  max-width: calc(100vw / 7) !important;
}
</style>

<style scoped>
.actions {
  list-style: none;
  padding: 0;
  margin: 0;
}
.actions li {
  display: inline-block;
  margin: 0 5px;
}
.lizard-column-actions ul {
  padding: 0 !important;
  margin: 0 !important;
  list-style: none;
}
.lizard-column-actions ul li {
  display: inline-block;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}
.lizard-color-btn {
  width: 38px;
  height: 34px;
  border: 2px solid #fff;
  border-radius: 3px;
  margin: 0 auto !important;
  padding: 0 !important;
  cursor: pointer;
  border: 0 none;
}
.lizard-color-btn:hover {
  border: 2px solid #666;
}
</style>
