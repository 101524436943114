<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Hours</label></div>
    <div class="col-sm-7">
      <input
        type="number"
        name=""
        id=""
        max="18"
        class="form-control lizard-form-duration txt"
        placeholder="1"
        v-model="duration"
        @keyup="pickDuration" />
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
export default {
  name: 'HoursSelector',
  computed: {
    FormData() {
      return this.$store.getters.formData
    },
    duration() {
      return this.FormData.duration
    }
  },
  methods: {
    pickDuration(ev) {
      let numReg = new RegExp('^[0-9]+$|^[0-9]+?\\.[1-9]$', 'g')
      let val = parseFloat(ev.target.value)
      let isValid = numReg.test(String(val))
      if (!isValid) {
        ev.target.value = ''
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
