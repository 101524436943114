<template>
  <b-container
    fluid
    class="bg-white custom-font-size"
    :class="{ 'lizard-no-padding': mobileView }"
    style="padding: 0 !important; margin: 0 !important"
  >
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <div class="col-lg-12">
        <ul class="week-switcher-toolbar" v-if="users.length">
          <li
            class="wst-prv"
            :class="{ mobile: mobileView }"
            @click="prevWeek()"
          >
            <span v-if="!mobileView">previous week</span>
            <span v-else>previous</span>
          </li>
          <li
            class="wst-label"
            :class="{ mobile: mobileView }"
            @click="currentWeek()"
          >
            {{ monthInfo }}
          </li>
          <li
            class="wst-nxt"
            :class="{ mobile: mobileView }"
            @click="nextWeek()"
          >
            <span v-if="!mobileView">next week</span> <span v-else>next</span>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row lizard-calendar"
      :class="{ 'lizard-no-margin': mobileView }"
    >
      <div class="col-lg-12" style="padding-right: 0 !important">
        <StaffingCalendar
          :loading="loading"
          :users="users"
          :showWeekends="showWeekends"
          :mobileView="mobileView"
        />
      </div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
    <div
      class="row"
      :class="{ 'lizard-no-margin': mobileView }"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <div class="col-lg lizard-form-empty-col"></div>
    </div>
  </b-container>
</template>

<script>
import { format as dateFormat, sub, add, differenceInDays } from 'date-fns'
import ServicesOld from '../../services/index.vue'
import Services from '../../services/main.service.vue'
import StaffingCalendar from './Staffing/Calendar.vue'

export default {
  name: 'StaffingLogs',
  props: {
    mobileView: {
      type: Boolean,
      required: true
    }
  },
  components: {
    StaffingCalendar
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    users() {
      return this.Store.staffingUsers
    },
    calendarWeeksStartDate() {
      return this.Store.staffingCalendarWeeksStartDate
    },
    showWeekends() {
      return this.mobileView ? false : true
    }
  },
  data() {
    return {
      loading: false,
      dataFetched: false,
      selectedRoles: ['MANAGER', 'ASSOCIATE', 'ANALYST', 'FTINTERN'],
      roleColors: {
        MANAGER: { color: '#1C3D59' },
        ASSOCIATE: { color: '#3AC0EE' },
        ANALYST: { color: '#BF871F' },
        FTINTERN: { color: '#170126' }
      },
      monthInfo: ''
    }
  },
  async mounted() {
    // this.$root.$on('parent_tab_staffing_logs_selected', this.tabMounted)
    this.tabMounted()
    this.$root.$on('get-staffing-logs-assignments', async () => {
      await this.getStaffingAssignments()
    })
  },
  methods: {
    async tabMounted() {
      await this.getUsers()
      this.setDefaultCalendarWeeksStartDate(true)
      this.currentWeek()
    },
    prevWeek() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      this.$store.commit(
        'setStaffingLogsCalendarWeeksStartDate',
        sub(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    nextWeek() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      this.$store.commit(
        'setStaffingLogsCalendarWeeksStartDate',
        add(new Date(this.calendarWeeksStartDate), {
          weeks: 1
        })
      )
      this.setCurrentMonth()
    },
    async currentWeek() {
      // this.setDefaultCalendarWeeksStartDate()
      this.setCurrentMonth()
    },
    setDefaultCalendarWeeksStartDate(setNull = false) {
      if (setNull) {
        this.$store.commit('setStaffingLogsCalendarWeeksStartDate', null)
      } else {
        this.$store.commit('setStaffingLogsCalendarWeeksStartDate')
      }
    },
    setCurrentMonth() {
      if (!this.calendarWeeksStartDate) {
        this.setDefaultCalendarWeeksStartDate()
      }
      const now = new Date()
      const startDate = this.calendarWeeksStartDate
      const isCurrentWeek = this.isWithinTwoWeeks(now, startDate)

      const monthsDays = this.countDaysByMonth(startDate)
      const selectedMonth = this.getFirstMonthWithMinDays(monthsDays, 7)
      const selectedYear = isCurrentWeek
        ? this.getYear(now)
        : this.getYear(startDate)
      this.monthInfo = selectedMonth + ' ' + selectedYear
    },
    commitToStore(commitName, value) {
      this.$store.commit(commitName, value)
    },
    async getUsers() {
      if (this.users.length) return
      const { status, data } = await Services.Users.getUsersByRole(
        this.selectedRoles.join(',')
      )
      this.commitToStore(
        'setStaffingUsers',
        status == 200
          ? data.content
              .map(({ uid, firstName, lastName, role }) => {
                return {
                  uid: uid,
                  name: `${firstName} ${lastName}`,
                  role,
                  color: this.roleColors[role.toUpperCase()].color
                }
              })
              .sort((a, b) => {
                if (
                  this.selectedRoles.indexOf(a.role.toUpperCase()) <
                  this.selectedRoles.indexOf(b.role.toUpperCase())
                )
                  return -1
                if (
                  this.selectedRoles.indexOf(a.role.toUpperCase()) >
                  this.selectedRoles.indexOf(b.role.toUpperCase())
                )
                  return 1
                return 0
              })
          : []
      )
    },
    async getStaffingAssignments() {
      try {
        if (this.dataFetched) return
        this.loading = true
        if (!this.calendarWeeksStartDate) {
          this.setDefaultCalendarWeeksStartDate()
        }
        const { status, data } = await ServicesOld.GetStaffingAssignments({
          fromDate: dateFormat(this.calendarWeeksStartDate, 'yyyy-MM-dd'),
          weeks: this.mobileView ? 1 : 2
        })
        if (status == 200) {
          this.$root.$emit('staffinglogs-assignments-fetched', data.content)
        }
        this.dataFetched = true
        this.loading = false
      } catch (Exception) {
        console.log({ Exception })
      }
    },
    isWithinTwoWeeks(date1, date2) {
      const diffInDays = differenceInDays(add(date2, { days: 13 }), date1)
      return diffInDays > 0 && diffInDays < 14
    },
    countDaysByMonth(startDate) {
      const monthsDays = {}
      const start = new Date(startDate)
      const end = add(new Date(startDate), { days: 13 })

      const loopDate = new Date(start)
      while (loopDate <= end) {
        const month = this.getMonthName(loopDate)
        monthsDays[month] = (monthsDays[month] || 0) + 1
        loopDate.setDate(loopDate.getDate() + 1)
      }

      return monthsDays
    },
    getFirstMonthWithMinDays(monthsDays, minDays) {
      for (const month in monthsDays) {
        if (monthsDays[month] >= minDays) {
          return month
        }
      }
      return null
    },
    getMonthName(date) {
      try {
        return dateFormat(date, this.mobileView ? 'MMM.' : 'MMMM')
      } catch (error) {
        console.error({ error })
        return null
      }
    },
    getYear(date) {
      try {
        return dateFormat(date, 'yyyy')
      } catch (error) {
        console.error({ error })
        return null
      }
    }
  },
  watch: {
    calendarWeeksStartDate() {
      setTimeout(() => {
        this.dataFetched = false
        this.getStaffingAssignments()
        this.$root.$emit('week-changed-on-staffing-logs')
      }, 500)
    }
  }
}
</script>

<style scoped>
.week-switcher-toolbar {
  list-style: none;
  margin: 0;
  padding: 0 1%;
}
.week-switcher-toolbar li {
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 30px;
  font-size: 8pt;
  font-weight: bold;
  cursor: pointer;
}
.week-switcher-toolbar li.wst-prv {
  width: 10% !important;
  color: #fff;
  background-color: #036;
  text-transform: uppercase;
  float: left !important;
}
.week-switcher-toolbar li.wst-label {
  width: 80% !important;
  color: #036;
  background-color: #fff;
  text-align: center !important;
  text-transform: capitalize;
  font-size: 16pt;
}
.week-switcher-toolbar li.wst-nxt {
  width: 10% !important;
  color: #fff;
  background-color: #036;
  text-transform: uppercase;
  float: right !important;
}
.week-switcher-toolbar li.wst-prv.mobile {
  width: 25% !important;
}
.week-switcher-toolbar li.wst-nxt.mobile {
  width: 25% !important;
}
.week-switcher-toolbar li.wst-label.mobile {
  width: 50% !important;
}
</style>

<style>
.custom-font-size {
  font-size: 8px !important;
}
</style>
