<template>
  <b-container fluid class="bg-white">
    <div class="row">
      <div class="col-lg-12">
        <SearchActivities :loading="loading" :keyword="q" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <activities-list
          :listItems="listItems"
          :pager="pager"
          :loading="loading"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import Services from '../../services/main.service.vue'
import ActivitiesList from './activities-components/List.vue'
import SearchActivities from './activities-components/Search.vue'

export default {
  name: 'ActivitiesComponenet',
  components: {
    ActivitiesList,
    SearchActivities
  },
  data() {
    return {
      loading: false,
      pager: {
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        totalPages: 1
      },
      listItems: [],
      q: ''
    }
  },
  mounted() {
    this.$root.$on('tab_activities_selected', this.tabMounted)
    this.$root.$on('refresh-activities-list', this.fetchData)
    this.$root.$on('setActivitiesSearchQuery', this.searchActivities)
  },
  methods: {
    async tabMounted() {
      await this.fetchData()
    },
    async fetchData(
      page = this.pager.currentPage,
      perPage = this.pager.perPage
    ) {
      try {
        this.loading = true
        const Activities = await Services.Activities.Admin.getList(
          this.q.trim(),
          page,
          perPage
        )
        const { info, list } = Activities.data.content
        if (info.total === 0) {
          this.listItems = []
          this.loading = false
          return
        }
        let listLength = info.page * info.limit
        if (info.total < listLength) {
          listLength = info.total
        }
        this.listItems = new Array(listLength).fill({})
        this.pager.currentPage = info.page
        this.pager.totalRows = info.total
        this.pager.totalPages = Math.ceil(
          this.pager.totalRows / this.pager.perPage
        )
        const firstIndex = (info.page - 1) * info.limit
        let dataItr = 0
        for (let i = firstIndex; i < listLength; i++) {
          this.listItems[i] = list[dataItr]
          dataItr++
        }
        setTimeout(() => {
          this.loading = false
        }, 500)
      } catch (Exception) {
        console.error(Exception)
        this.pager = {
          perPage: 25,
          currentPage: 1,
          totalRows: 1,
          totalPages: 1
        }
        this.listItems = []
        this.loading = false
      }
    },
    searchActivities(q) {
      if (this.loading) {
        return
      }
      this.q = q.trim()
      if (this.q.length === 0 || this.q.length >= 3) {
        this.fetchData()
      }
    }
  }
}
</script>
