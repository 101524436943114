<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2"><label for="" class="h6">Manager</label></div>
    <div class="col-sm-7">
      <b-select
        class="form-control txt"
        v-model="selectedManager"
        @change="managerChanged"
        :options="managers">
      </b-select>
    </div>
    <div class="col-sm-2"></div>
  </div>
</template>

<script>
import Services from '../../services/main.service.vue'

export default {
  name: 'ManagerSelector',
  data() {
    return {
      selectedManager: 'def',
      managers: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    manager() {
      return this.FormData.approvalmanager
    }
  },
  mounted() {
    this.getManagers()
  },
  methods: {
    async getManagers() {
      const Managers = await Services.Users.getUsersByRole('manager')
      this.managers = Managers.data.content.map((manager) => {
        return {
          value: manager.uid,
          text: `${manager.firstName} ${manager.lastName}`
        }
      })
      this.managers.unshift({
        value: 'def',
        text: 'Select one...'
      })
    },
    managerChanged() {
      if (this.manager !== this.selectedManager) {
        this.$store.commit('modifyFormData', {
          key: 'approvalmanager',
          value: this.selectedManager
        })
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
.file-txt {
  color: #666;
  font-weight: bold !important;
  font-size: 8pt !important;
}
</style>
