<template>
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-2" style="text-align: right !important">
      <label for="" class="h6">Project</label>
    </div>
    <div class="col-sm-7">
      <select
        class="form-control txt"
        v-model="selectedProject"
        @change="projectChanged"
        :disabled="loadingType">
        <option value="def" selected v-if="loadingType">Please wait...</option>
        <option value="def" selected v-if="!loadingType">Select one...</option>
        <option
          v-for="prj in projects"
          :key="prj.uid"
          :value="prj.code + ':' + prj.uid">
          <span v-if="prj.type === 'project'"
            >{{ prj.code }}: {{ prj.title }}</span
          >
          <span v-else>{{ prj.title }}</span>
        </option>
      </select>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-1"></div>
  </div>
</template>

<script>
import Services from '../../services/main.service.vue'

export default {
  name: 'ProjectSelector',
  props: ['activityType'],
  data() {
    return {
      loadingType: false,
      selectedProject: 'def',
      projects: []
    }
  },
  computed: {
    Store() {
      return this.$store.getters
    },
    FormData() {
      return this.Store.formData
    },
    project() {
      return this.FormData.project
    }
  },
  async mounted() {
    this.projects = []
    if (!this.projects.length) {
      this.loadingType = true
      const Projects = await Services.Projects.getMondayProjects()
      this.projects = Projects.data.content
        .map((prj) => {
          const prjType = prj.type === 'business_development' ? 'BD' : 'Project'
          if (prjType === 'BD') {
            const hasBDPerfix = /^BD/.test(prj.title)
            if (!hasBDPerfix) {
              prj.title = `BD ${prj.title}`
              if (prj.client) {
                prj.title = `${prj.title} (${prj.client})`
              }
            }
          }
          return prj
        })
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
      if (this.activityType == 'exp') {
        this.projects.unshift({
          uid: 'candesic',
          code: 'CANEXP',
          title: 'Candesic Expense'
        })
      }
      this.loadingType = false
    }
  },
  methods: {
    projectChanged() {
      if (this.project !== this.selectedProject) {
        this.$store.commit('modifyFormData', {
          key: 'project',
          value: this.selectedProject
        })
      }
    }
  }
}
</script>

<style scoped>
.lizard-help-list {
  padding: 0 2px 2px 2px;
  margin: 1px;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  border-radius: 2px;
  line-height: 16px;
  font-variant: small-caps;
}
.lizard-help-icon {
  position: relative;
  float: right;
  display: block;
  top: -27px;
  right: 2px;
}
.lizard-form-empty-col {
  min-height: 20px !important;
}
.lizard-form-date {
  min-width: 100% !important;
}
.lizard-form-datepicker {
  max-width: 100% !important;
}
.lizard-form-duration {
  text-align: center;
}
.header-msg {
  color: #666;
  font-weight: bold !important;
}
label {
  color: #666;
  line-height: 40px !important;
  font-weight: bold !important;
  font-size: 12px !important;
}
.txt {
  color: #666;
  font-weight: bold !important;
  font-size: 12px !important;
}
</style>
